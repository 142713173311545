import React, { Component } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';
import classes from './styles.module.scss';
import { connect } from 'react-redux';
import {
    ICheckboxItem,
    ILaboratorioDitta,
    ILaboratorioDittaForm,
    ILaboratorioSede,
    ILaboratorioSedeForm,
    ISelectOption,
} from '../../shared/interfaces';
import CitySelect from '../CitySelect';
import Field from '../Field';
import FlexWrapper from '../UI/FlexWrapper';
import Label from '../UI/Label';
import Button from '../UI/Button';
import CheckBox from '../UI/Checkbox';
import * as actions from '../../store/actions';
import constants from '../../shared/constants';
import Loader from '../UI/Loader';
import ErrorFeedback from '../UI/ErrorFeedback';
import Toast from '../UI/Toast';
import Textarea from '../Textarea';

interface IProps {
    currentData?: ILaboratorioDitta;
    item: ILaboratorioDittaForm;
    waitingForSaving: boolean;
    error: any;
    sedi: Array<ILaboratorioSede>;
    onUpdate(id: string, data: ILaboratorioDittaForm, isCorrezione: boolean): ILaboratorioDitta;
    onCloseModal?(): void;
    onCreate(data: ILaboratorioDittaForm): ILaboratorioDitta;
    lastUpdatedLaboratorio: string;
    lastCreatedLaboratorio: string;
    lastCreatedSede: string;
    onCreateSede(
        idLaboratorio: string,
        data: ILaboratorioSedeForm,
        assegnaNumero: boolean
    ): ILaboratorioDitta;
}

interface IState {
    initialValues: ILaboratorioDittaForm;
    companyCity: ISelectOption;
    sedeCity: ISelectOption;
    assegnaNumero: ICheckboxItem;
    isCorrezione: ICheckboxItem;
    currentId: string | null;
    currentValues: any;
}

class LaboratorioForm extends Component<IProps, IState> {
    state = {
        initialValues: {
            Cap: '',
            Citta: '',
            ContattiTelefonici: '',
            Email: '',
            IdCitta: '',
            IdentificativoFiscale: '',
            Indirizzo: '',
            IndirizzoDettagli: '',
            LegaleRappresentanteCodiceFiscale: '',
            LegaleRappresentanteCognome: '',
            LegaleRappresentanteNome: '',
            Pec: '',
            RagioneSociale: '',
            Id: constants.EMPTY_UUID,
            // Sede
            SedeId: constants.EMPTY_UUID,
            SedeIdLabAnalisiDitta: constants.EMPTY_UUID,
            SedeIndirizzo: '',
            SedeIndirizzoDettagli: '',
            SedeCap: '',
            SedeIdCitta: constants.EMPTY_UUID,
            SedeCitta: '',
            SedeContattiTelefonici: '',
            SedeEmail: '',
            SedeResponsabileLaboratorio: '',
            SedeNumeroIscrizione: '',
            SedeIsAccreditato: false,
            SedeAccreditamentoRiferimento: '',
            SedeAccreditamentoNote: '',
        },
        companyCity: null,
        sedeCity: null,
        isCorrezione: {
            label: 'È una correzione, NON una variazione amministrativa.',
            value: 0,
            checked: true,
        },
        assegnaNumero: {
            label: 'Assegna numero iscrizione al salvataggio',
            value: 0,
            checked: true,
        },
        currentId: null,
        currentValues: null,
    };

    componentDidMount() {
        const { currentData } = this.props;
        if (currentData) {
            const initialValues: ILaboratorioDittaForm = {
                Cap: currentData.Cap || '',
                Citta: currentData.Citta || '',
                ContattiTelefonici: currentData.ContattiTelefonici || '',
                Email: currentData.Email || '',
                IdCitta: currentData.IdCitta || '',
                IdentificativoFiscale: currentData.IdentificativoFiscale || '',
                Indirizzo: currentData.Indirizzo || '',
                IndirizzoDettagli: currentData.IndirizzoDettagli || '',
                LegaleRappresentanteCodiceFiscale:
                    currentData.LegaleRappresentante.CodiceFiscale || '',
                LegaleRappresentanteCognome: currentData.LegaleRappresentante.Cognome || '',
                LegaleRappresentanteNome: currentData.LegaleRappresentante.Nome || '',
                Pec: currentData.Pec || '',
                RagioneSociale: currentData.RagioneSociale || '',
                Id: currentData.Id,
            };

            let companyCity: ISelectOption = null;

            if (currentData.IdCitta) {
                companyCity = {
                    value: currentData.IdCitta,
                    label: currentData.Citta,
                };
            }

            this.setState({
                initialValues,
                companyCity,
                currentId: currentData.Id,
            });
        }
    }

    citiesAreValid = (update: boolean = false): boolean => {
        if (update && this.state.companyCity && this.state.companyCity.value) {
            return true;
        }

        return (
            !update &&
            this.state.companyCity &&
            this.state.companyCity.value &&
            this.state.sedeCity &&
            this.state.sedeCity.value
        );
    };

    onSubmitClicked = (values, { setSubmitting }) => {
        setSubmitting(false);

        if (
            this.citiesAreValid(
                this.props.currentData !== null && this.props.currentData !== undefined
            )
        ) {
            const { companyCity } = this.state;

            const legaleRappresentante = {
                CodiceFiscale: values.LegaleRappresentanteCodiceFiscale,
                Cognome: values.LegaleRappresentanteCognome,
                Nome: values.LegaleRappresentanteNome,
            };

            const data = {
                ...values,
                IdCitta: companyCity.value,
                Citta: companyCity.label,
                legaleRappresentante,
            };

            this.setState(
                {
                    currentValues: data,
                },
                () => {
                    if (this.state.currentId) {
                        this.props.onUpdate(
                            this.state.currentId,
                            data,
                            this.state.isCorrezione.checked
                        );
                    } else {
                        this.props.onCreate(data);
                    }
                }
            );
        } else {
            Toast({
                message: 'Verifica di avere inserito la città ed il comune della sede.',
                type: 'error',
            });
        }
    };

    onValidate = (values: FormikValues) => {
        const errors: FormikErrors<any> = {};

        if (!values.IdentificativoFiscale) {
            errors.IdentificativoFiscale = 'Campo obbligatorio';
        }

        if (!values.RagioneSociale) {
            errors.RagioneSociale = 'Campo obbligatorio';
        }

        if (!values.Indirizzo) {
            errors.Indirizzo = 'Campo obbligatorio';
        }

        if (!values.LegaleRappresentanteCodiceFiscale) {
            errors.LegaleRappresentanteCodiceFiscale = 'Campo obbligatorio';
        }

        if (!values.LegaleRappresentanteCognome) {
            errors.LegaleRappresentanteCognome = 'Campo obbligatorio';
        }

        if (!values.LegaleRappresentanteNome) {
            errors.LegaleRappresentanteNome = 'Campo obbligatorio';
        }

        if (values.Email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
            errors.email = 'Indirizzo Email non valido';
        }

        if (!values.Pec) {
            errors.Pec = 'Campo obbligatorio';
        }

        if (values.Pec && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Pec)) {
            errors.pec = 'Indirizzo PEC non valido';
        }

        if (!this.props.currentData) {
            if (!values.SedeIndirizzo) {
                errors.SedeIndirizzo = 'Campo obbligatorio';
            }

            if (!values.SedeResponsabileLaboratorio) {
                errors.SedeResponsabileLaboratorio = 'Campo obbligatorio';
            }
        }

        return errors;
    };

    onCompanyCitySelected = (selected: ISelectOption, setFieldValue) => {
        setFieldValue('Cap', selected.optValue); // Formik trick
        this.setState({
            companyCity: selected,
        });
    };

    onSedeCitySelected = (selected: ISelectOption, setFieldValue) => {
        setFieldValue('SedeCap', selected.optValue); // Formik trick
        this.setState({
            sedeCity: selected,
        });
    };

    onCheckedAssegnaNumero = (checked: boolean) => {
        let assegnaNumero = {
            ...this.state.assegnaNumero,
            checked,
        };

        this.setState({
            assegnaNumero,
        });
    };

    onCheckedIsCorrezione = (checked: boolean) => {
        let isCorrezione = {
            ...this.state.isCorrezione,
            checked,
        };

        this.setState({
            isCorrezione,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastUpdatedLaboratorio !== prevProps.lastUpdatedLaboratorio &&
            this.props.lastUpdatedLaboratorio &&
            this.props.onCloseModal
        ) {
            Toast({ message: 'Record aggiornato con successo.', type: 'success' });
            this.props.onCloseModal();
        }
        if (
            this.props.lastCreatedLaboratorio !== prevProps.lastCreatedLaboratorio &&
            this.props.lastCreatedLaboratorio
        ) {
            this.createSedeLaboratorio();
        }
        if (
            this.props.lastCreatedSede !== prevProps.lastCreatedSede &&
            this.props.lastCreatedSede &&
            this.props.onCloseModal
        ) {
            Toast({ message: 'Record creato con successo.', type: 'success' });
            this.props.onCloseModal();
        }
    }

    createSedeLaboratorio = () => {
        const { lastCreatedLaboratorio } = this.props;

        const {
            currentValues: {
                SedeId,
                SedeIndirizzo,
                SedeIndirizzoDettagli,
                SedeCap,
                SedeContattiTelefonici,
                SedeEmail,
                SedeResponsabileLaboratorio,
                SedeNumeroIscrizione,
                SedeAccreditamentoRiferimento,
                SedeAccreditamentoNote,
            },
            assegnaNumero,
            sedeCity,
        } = this.state;

        const data: ILaboratorioSedeForm = {
            IdLabAnalisiDitta: lastCreatedLaboratorio,
            Cap: SedeCap,
            Citta: sedeCity.label,
            ContattiTelefonici: SedeContattiTelefonici,
            Email: SedeEmail,
            Id: SedeId,
            IdCitta: sedeCity.value,
            Indirizzo: SedeIndirizzo,
            IndirizzoDettagli: SedeIndirizzoDettagli,
            ResponsabileLaboratorio: SedeResponsabileLaboratorio,
            NumeroIscrizione: SedeNumeroIscrizione,
            IsAccreditato: this.state.assegnaNumero.checked,
            AccreditamentoRiferimento: SedeAccreditamentoRiferimento,
            AccreditamentoNote: SedeAccreditamentoNote,
        };

        this.props.onCreateSede(lastCreatedLaboratorio, data, assegnaNumero.checked);
    };

    render() {
        const { initialValues, companyCity, assegnaNumero, isCorrezione, sedeCity } = this.state;
        const { waitingForSaving, error } = this.props;

        return (
            <section className={classes.LaboratorioForm}>
                {waitingForSaving && <Loader fullContent={true} />}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={this.onValidate}
                    onSubmit={this.onSubmitClicked}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        label={'Identificativo fiscale'}
                                        type={'text'}
                                        name={'IdentificativoFiscale'}
                                        placeholder={'Codice Fiscale o Partita IVA'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.IdentificativoFiscale}
                                        size={'sm'}
                                        hasMaxWidth={true}
                                        transform={'uppercase'}
                                        disabled={waitingForSaving}
                                        error={
                                            errors.IdentificativoFiscale &&
                                            touched.IdentificativoFiscale &&
                                            errors.IdentificativoFiscale
                                        }
                                    />
                                    <Field
                                        label={'Ragione sociale'}
                                        type={'text'}
                                        name={'RagioneSociale'}
                                        placeholder={'Denominazione o Ragione Sociale'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.RagioneSociale}
                                        size={'sm'}
                                        disabled={waitingForSaving}
                                        styles={{
                                            maxWidth: '600px',
                                        }}
                                        error={
                                            errors.RagioneSociale &&
                                            touched.RagioneSociale &&
                                            errors.RagioneSociale
                                        }
                                    />
                                </FlexWrapper>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        label={'Indirizzo'}
                                        type={'text'}
                                        name={'Indirizzo'}
                                        placeholder={'Indirizzo sede legale'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Indirizzo}
                                        size={'sm'}
                                        disabled={waitingForSaving}
                                        styles={{
                                            maxWidth: '500px',
                                        }}
                                        error={
                                            errors.Indirizzo &&
                                            touched.Indirizzo &&
                                            errors.Indirizzo
                                        }
                                    />
                                    <Field
                                        label={'Dettagli'}
                                        type={'text'}
                                        name={'IndirizzoDettagli'}
                                        placeholder={
                                            'Informazioni utili per identificazione indirizzo'
                                        }
                                        changed={handleChange}
                                        disabled={waitingForSaving}
                                        blurred={handleBlur}
                                        value={values.IndirizzoDettagli}
                                        styles={{
                                            maxWidth: '500px',
                                        }}
                                        size={'sm'}
                                        error={
                                            errors.IndirizzoDettagli &&
                                            touched.IndirizzoDettagli &&
                                            errors.IndirizzoDettagli
                                        }
                                    />
                                </FlexWrapper>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <CitySelect
                                        labelBlack={true}
                                        styles={{
                                            maxWidth: '400px',
                                            marginRight: '20px',
                                        }}
                                        label={'Città'}
                                        single={true}
                                        selected={companyCity}
                                        disabled={waitingForSaving}
                                        onSelectedChanged={(sel: ISelectOption) =>
                                            this.onCompanyCitySelected(sel, setFieldValue)
                                        }
                                    />
                                    <Field
                                        label={'CAP'}
                                        disabled={waitingForSaving}
                                        type={'text'}
                                        name={'Cap'}
                                        placeholder={'CAP'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Cap}
                                        size={'sm'}
                                        styles={{
                                            maxWidth: '70px',
                                        }}
                                        error={errors.Cap && touched.Cap && errors.Cap}
                                    />
                                </FlexWrapper>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Contatti telefonici'}
                                        type={'text'}
                                        name={'ContattiTelefonici'}
                                        placeholder={'Inserisci i recapiti telefonici'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.ContattiTelefonici}
                                        size={'sm'}
                                        styles={{
                                            maxWidth: '600px',
                                        }}
                                        error={
                                            errors.ContattiTelefonici &&
                                            touched.ContattiTelefonici &&
                                            errors.ContattiTelefonici
                                        }
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'PEC'}
                                        type={'text'}
                                        name={'Pec'}
                                        placeholder={'Inserisci indirizzo PEC'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Pec}
                                        styles={{
                                            maxWidth: '600px',
                                        }}
                                        size={'sm'}
                                        error={errors.Pec && touched.Pec && errors.Pec}
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Email'}
                                        type={'text'}
                                        name={'Email'}
                                        placeholder={'Inserisci indirizzo email'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Email}
                                        styles={{
                                            maxWidth: '400px',
                                        }}
                                        size={'sm'}
                                        error={errors.Email && touched.Email && errors.Email}
                                    />
                                </FlexWrapper>
                                <Label for={'legaleRappresentante'}>
                                    {'Legale rappresentante'}
                                </Label>
                                <FlexWrapper withFields={true}>
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Codice fiscale'}
                                        type={'text'}
                                        name={'LegaleRappresentanteCodiceFiscale'}
                                        placeholder={'Codice fiscale'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.LegaleRappresentanteCodiceFiscale}
                                        styles={{
                                            maxWidth: '220px',
                                        }}
                                        size={'sm'}
                                        transform={'uppercase'}
                                        error={
                                            errors.LegaleRappresentanteCodiceFiscale &&
                                            touched.LegaleRappresentanteCodiceFiscale &&
                                            errors.LegaleRappresentanteCodiceFiscale
                                        }
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Cognome'}
                                        type={'text'}
                                        name={'LegaleRappresentanteCognome'}
                                        placeholder={'Cognome'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.LegaleRappresentanteCognome}
                                        styles={{
                                            maxWidth: '320px',
                                        }}
                                        size={'sm'}
                                        error={
                                            errors.LegaleRappresentanteCognome &&
                                            touched.LegaleRappresentanteCognome &&
                                            errors.LegaleRappresentanteCognome
                                        }
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Nome'}
                                        type={'text'}
                                        name={'LegaleRappresentanteNome'}
                                        placeholder={'Nome'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.LegaleRappresentanteNome}
                                        styles={{
                                            maxWidth: '320px',
                                        }}
                                        size={'sm'}
                                        error={
                                            errors.LegaleRappresentanteNome &&
                                            touched.LegaleRappresentanteNome &&
                                            errors.LegaleRappresentanteNome
                                        }
                                    />
                                </FlexWrapper>
                                {!this.props.currentData && (
                                    <>
                                        <Label for={'Sede'}>{'Sede'}</Label>
                                        <FlexWrapper withFields={true} withMarginBottom={true}>
                                            <Field
                                                label={'Indirizzo Sede'}
                                                type={'text'}
                                                name={'SedeIndirizzo'}
                                                placeholder={'Indirizzo'}
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values.SedeIndirizzo}
                                                size={'sm'}
                                                disabled={waitingForSaving}
                                                styles={{
                                                    maxWidth: '500px',
                                                }}
                                                error={
                                                    errors.SedeIndirizzo &&
                                                    touched.SedeIndirizzo &&
                                                    errors.SedeIndirizzo
                                                }
                                            />
                                            <Field
                                                label={'Dettagli'}
                                                type={'text'}
                                                name={'SedeIndirizzoDettagli'}
                                                placeholder={
                                                    'Informazioni utili per identificazione indirizzo'
                                                }
                                                changed={handleChange}
                                                disabled={waitingForSaving}
                                                blurred={handleBlur}
                                                value={values.SedeIndirizzoDettagli}
                                                styles={{
                                                    maxWidth: '500px',
                                                }}
                                                size={'sm'}
                                                error={
                                                    errors.SedeIndirizzoDettagli &&
                                                    touched.SedeIndirizzoDettagli &&
                                                    errors.SedeIndirizzoDettagli
                                                }
                                            />
                                        </FlexWrapper>
                                        <FlexWrapper withFields={true} withMarginBottom={true}>
                                            <CitySelect
                                                styles={{
                                                    maxWidth: '400px',
                                                    marginRight: '20px',
                                                }}
                                                label={'Comune'}
                                                labelBlack={true}
                                                single={true}
                                                selected={sedeCity}
                                                disabled={waitingForSaving}
                                                onSelectedChanged={(sel: ISelectOption) =>
                                                    this.onSedeCitySelected(sel, setFieldValue)
                                                }
                                            />
                                            <Field
                                                label={'CAP'}
                                                disabled={waitingForSaving}
                                                type={'text'}
                                                name={'SedeCap'}
                                                placeholder={'CAP'}
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values.SedeCap}
                                                size={'sm'}
                                                styles={{
                                                    maxWidth: '70px',
                                                }}
                                                error={
                                                    errors.SedeCap &&
                                                    touched.SedeCap &&
                                                    errors.SedeCap
                                                }
                                            />
                                        </FlexWrapper>
                                        <FlexWrapper withFields={true} withMarginBottom={true}>
                                            <Field
                                                disabled={waitingForSaving}
                                                label={'Contatti telefonici'}
                                                type={'text'}
                                                name={'SedeContattiTelefonici'}
                                                placeholder={
                                                    'Inserisci i recapiti telefonici per la sede'
                                                }
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values.SedeContattiTelefonici}
                                                size={'sm'}
                                                styles={{
                                                    maxWidth: '600px',
                                                }}
                                                error={
                                                    errors.SedeContattiTelefonici &&
                                                    touched.SedeContattiTelefonici &&
                                                    errors.SedeContattiTelefonici
                                                }
                                            />
                                            <Field
                                                disabled={waitingForSaving}
                                                label={'Email'}
                                                type={'email'}
                                                name={'SedeEmail'}
                                                placeholder={
                                                    'Inserisci indirizzo email per la sede'
                                                }
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values.SedeEmail}
                                                styles={{
                                                    maxWidth: '400px',
                                                }}
                                                size={'sm'}
                                                error={
                                                    errors.SedeEmail &&
                                                    touched.SedeEmail &&
                                                    errors.SedeEmail
                                                }
                                            />
                                            <Field
                                                disabled={waitingForSaving}
                                                label={'Responsabile laboratorio'}
                                                type={'text'}
                                                name={'SedeResponsabileLaboratorio'}
                                                placeholder={'Nominativo'}
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values.SedeResponsabileLaboratorio}
                                                styles={{
                                                    maxWidth: '300px',
                                                }}
                                                size={'sm'}
                                                error={
                                                    errors.SedeResponsabileLaboratorio &&
                                                    touched.SedeResponsabileLaboratorio &&
                                                    errors.SedeResponsabileLaboratorio
                                                }
                                            />
                                        </FlexWrapper>
                                    </>
                                )}
                                {this.state.currentId ? (
                                    <FlexWrapper withMarginBottom={true}>
                                        <CheckBox
                                            checked={isCorrezione.checked}
                                            changed={(checked: boolean) =>
                                                this.onCheckedIsCorrezione(checked)
                                            }
                                        >
                                            {isCorrezione.label}
                                        </CheckBox>
                                    </FlexWrapper>
                                ) : (
                                    <FlexWrapper withMarginBottom={true}>
                                        <CheckBox
                                            checked={assegnaNumero.checked}
                                            changed={(checked: boolean) =>
                                                this.onCheckedAssegnaNumero(checked)
                                            }
                                        >
                                            {assegnaNumero.label}
                                        </CheckBox>
                                    </FlexWrapper>
                                )}
                                <>
                                    {!this.props.currentData && (
                                        <>
                                            <FlexWrapper withMarginBottom={true}>
                                                <Field
                                                    disabled={waitingForSaving}
                                                    label={'Rif. Accreditamento'}
                                                    type={'text'}
                                                    name={'SedeAccreditamentoRiferimento'}
                                                    placeholder={'Link al documento'}
                                                    changed={handleChange}
                                                    blurred={handleBlur}
                                                    value={values.SedeAccreditamentoRiferimento}
                                                    styles={{
                                                        maxWidth: '800px',
                                                    }}
                                                    size={'sm'}
                                                    error={
                                                        errors.SedeAccreditamentoRiferimento &&
                                                        touched.SedeAccreditamentoRiferimento &&
                                                        errors.SedeAccreditamentoRiferimento
                                                    }
                                                />
                                            </FlexWrapper>
                                            <FlexWrapper withMarginBottom={true}>
                                                <Textarea
                                                    disabled={waitingForSaving}
                                                    label={'Note accreditamento'}
                                                    name={'SedeAccreditamentoNote'}
                                                    placeholder={'Inserisci note'}
                                                    changed={handleChange}
                                                    blurred={handleBlur}
                                                    value={values.SedeAccreditamentoNote}
                                                    styles={{
                                                        maxWidth: '800px',
                                                    }}
                                                    error={
                                                        errors.SedeAccreditamentoNote &&
                                                        touched.SedeAccreditamentoNote &&
                                                        errors.SedeAccreditamentoNote
                                                    }
                                                />
                                            </FlexWrapper>
                                        </>
                                    )}
                                </>
                                {error && <ErrorFeedback>{error}</ErrorFeedback>}
                                <footer>
                                    <Button
                                        theme={'success'}
                                        type="submit"
                                        disabled={waitingForSaving || isSubmitting}
                                    >
                                        Invia
                                    </Button>
                                </footer>
                            </form>
                        );
                    }}
                </Formik>
            </section>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        item: state.laboratorioState.selected,
        waitingForSaving:
            state.laboratorioState.isUpdatingLaboratorio ||
            state.laboratorioState.isCreatingLaboratorio ||
            state.laboratorioState.isCreatingSede,
        error: state.laboratorioState.error,
        sedi: state.laboratorioState.sedi,
        lastUpdatedLaboratorio: state.laboratorioState.lastUpdatedLaboratorio,
        lastCreatedLaboratorio: state.laboratorioState.lastCreatedLaboratorio,
        lastCreatedSede: state.laboratorioState.lastCreatedSede,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdate: (id: string, data: ILaboratorioDittaForm, isCorrezione: boolean) =>
            dispatch(actions.updateLaboratorio(id, data, isCorrezione)),
        onCreate: (data: ILaboratorioDittaForm) => dispatch(actions.createLaboratorio(data)),
        onCreateSede: (idLaboratorio: string, data: ILaboratorioSedeForm, assegnaNumero: boolean) =>
            dispatch(actions.createSedeLaboratorio(idLaboratorio, data, assegnaNumero)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaboratorioForm);
