export enum SedeTipo {
    Laboratorio, //0
    EsercizioCommerciale, //1
    Deposito, //2
    Stabilimento, //3
    Primaria, //4
    StabilimentoPrimaria, //5
    Chiosco, //6
    BancoTemporaneo, //7
    Mezzo, //8
    NegozioMobile, //9
    Motobarca, //10
    Pesca24ore, //11
}

export enum SedeClasse {
    SedeStabile,
    MezzoTrasporto,
    Imbarcazione,
}

export enum SedeCarattere {
    Permanente,
    Stagionale,
    Temporanea,
}

export enum ControlloMotivo {
    NonSpecificato = 1 << 0,
    Allerta = 1 << 1,
    Documentazione = 1 << 2,
    Pianificazione = 1 << 3,
    Segnalazione = 1 << 4,
}

export enum ControlloTipo {
    Audit = 1 << 0,
    Campionamento = 1 << 1,
    Ispezione = 1 << 2,
    Monitoraggio = 1 << 3,
    Sorveglianza = 1 << 4,
    Verifica = 1 << 5,
}

export enum CampioneClasse {
    Alimento,
    AcquaPotabile,
    AcquaMarina,
    Fitosanitario,
}

export enum CampioneProvenienza {
    NonDefinito,
    Regionale,
    Nazionale,
    Comunitaria,
    Extracomunitaria,
}

export enum CampioneCircuito {
    NonDefinito,
    Convenzionale,
    Biologico,
}

export enum CampioneTipologiaOsa {
    T00_NonDefinito,
    T01_ProdIngrosso,
    T02_PrdoDettaglio,
    T03_RistTradizionale,
    T04_RistEtnica,
    T05_Bar,
    T06_Agriturismo,
    T07_SelfService,
    T08_MensaOspedale,
    T09_MensaScuola,
    T10_MensaCircondariale,
    T11_MensaUffPubblico,
    T12_CentroCottura,
    T13_Catering,
    T14_CasaRisposo,
    T15_DistrIngrosso,
    T16_GDO,
    T17_NegozioVicinato,
}

export enum FormatoreTipoDecretoEnum {
    NonDefinito = 0,
    Iscrizione = 1,
    Modifica = 2,
    Cancellazione = 3,
    Sospensione = 4,
    Riattivazione = 5,
}

export enum AzioneInfrazioniEnum {
    Nessuna = 1 << 1,
    A_IgieneGenerale = 1 << 2,
    B_IgieneHACCPPersonale = 1 << 4,
    C_Composizione = 1 << 8,
    D_Contaminazione = 1 << 16,
    E_Etichettatura = 1 << 32,
    F_Altro = 1 << 64,
}

export enum AzioneTipoEnum {
    Prescrizione,
    IllecitoAmministrativo,
    IllecitoPenale,
    Sequestro,
    Sigilli,
    DistruzioneAlimenti,
    Chiusura,
}

export enum StatoDitta {
    Attiva = 0,
    Sospesa = 1,
    Cancellata = 2,
}
