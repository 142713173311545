import React, { Component } from 'react';
import Select from 'react-select';
import classes from './styles.module.scss';
import constants from '../../shared/constants';
import Label from '../UI/Label';

const options = Object.keys(constants.TIPI_DECRETO).map((key: string) => {
    return {
        value: constants.TIPI_DECRETO[key].value,
        label: constants.TIPI_DECRETO[key].label,
    };
});

interface IProps {
    changed(selected: { value: string; label: string }): void;
    selected?: number;
    label?: string;
}

export default class TipoDecretoSelector extends Component<IProps> {
    render() {
        const { selected, label } = this.props;

        return (
            <section className={classes.TipoDecretoSelector}>
                {label && <Label for={'tipoDecreto'}>{label}</Label>}
                <Select
                    onChange={this.props.changed}
                    value={
                        selected >= 0
                            ? options.find((item) => +item.value === +selected)
                            : options[0]
                    }
                    isSearchable={false}
                    name="tipoDecreto"
                    options={options}
                />
            </section>
        );
    }
}
