export default {
    ANAGRAFICA: [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'ditta',
            headerName: 'Ditta',
            width: 500,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sede',
            headerName: 'Sede',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'attivita',
            headerName: 'Attività',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            width: 300,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'numeri',
            headerName: 'Numeri',
            width: 250,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
    ],
    CONTROLLI: [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'data',
            headerName: 'Data',
            width: 250,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'ditta',
            headerName: 'Ditta',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sede',
            headerName: 'Sede',
            width: 300,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'tipomotivo',
            headerName: 'Tipo Motivo',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'azionicampioni',
            headerName: 'N° Azioni / N° Campioni',
            width: 200,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
    ],
    FORMATORI: [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'numero',
            headerName: 'Numero',
            width: 250,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'denominazione',
            headerName: 'Denominazione',
            width: 500,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sede',
            headerName: 'Sede legale',
            width: 350,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'fadAsync',
            headerName: 'Asin',
            width: 63,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px', 'text-align': 'center' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'fadSync',
            headerName: 'Sin.',
            width: 63,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px', 'text-align': 'center' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'res',
            headerName: 'Res.',
            width: 63,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px', 'text-align': 'center' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sediOperative',
            headerName: 'Sedi operative',
            width: 500,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
    ],
    LABORATORI: [
        {
            field: 'idDitta',
            headerName: 'ID Ditta',
            hide: true,
        },
        {
            field: 'idSede',
            headerName: 'ID Sede',
            hide: true,
        },
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'numero',
            headerName: 'Numero',
            width: 300,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'denominazione',
            headerName: 'Denominazione',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sede',
            headerName: 'Laboratorio',
            width: 500,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'accreditato',
            headerName: 'Accreditato',
            width: 600,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
    ],
    CAMPIONI: [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'data',
            headerName: 'Data',
            width: 250,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'ditta',
            headerName: 'Ditta',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'sede',
            headerName: 'Sede',
            width: 300,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'classeprovenienza',
            headerName: 'Classe Provenienza',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
        {
            field: 'circuitotipologiaosa',
            headerName: 'Circuito Tipologia OSA',
            width: 400,
            cellStyle: { 'white-space': 'normal', 'line-height': '26px' },
            cellRenderer: function (params) {
                return '<span>' + params.value + '</span>';
            },
        },
    ],
};
