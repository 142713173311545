import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import NavigationLink from '../NavigationLink';
import * as routes from '../../shared/routes';
import { NavLink } from 'react-router-dom';
import navigations from '../../shared/data/navigations';
import constants from '../../shared/constants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ISessionData } from '../../shared/interfaces';
import Button from '../UI/Button';
import * as actions from '../../store/actions';

interface IProps {
    isAuthenticated?: boolean;
    fetchSession: boolean;
    session: ISessionData;
    history: History;
    children?: any;
    onLogout(): void;
}

interface IState {
    authenticated: boolean;
}

class Header extends Component<IProps, IState> {
    state = {
        authenticated: false,
    };

    componentDidMount(): void {
        const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

        if (!authToken) {
            this.props.history.push(routes.SIGNIN);
        } else {
            this.setState({
                authenticated: true,
            });
        }
    }

    onLogoutClicked = () => {
        this.props.onLogout();
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.session !== prevProps.session && this.props.session) {
            this.setState({
                authenticated: true,
            });
        }

        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            !this.props.isAuthenticated &&
            this.state.authenticated
        ) {
            window.location.href = '/';
        }
    }

    render() {
        const { authenticated } = this.state;

        return (
            <header className={classes.Header}>
                <div className={classes['Header-content']}>
                    <div className={classes['Header-logo']}>
                        <NavLink to={routes.HOMEPAGE}>{'DEMETRA.IO'}</NavLink>
                    </div>
                    <nav className={classes['Header-nav']}>
                        <ul>
                            {authenticated && (
                                <>
                                    {navigations.AUTH_NAV.map((item, ix) => (
                                        <li key={ix}>
                                            <NavigationLink to={item.route}>
                                                {item.label}
                                            </NavigationLink>
                                        </li>
                                    ))}
                                    <Button
                                        size={'sm'}
                                        theme={'danger'}
                                        weight={'bold'}
                                        clicked={this.onLogoutClicked}
                                    >
                                        {'Esci'}
                                    </Button>
                                </>
                            )}
                            {!authenticated &&
                                navigations.DEFAULT_NAV.map((item, ix) => (
                                    <li key={ix}>
                                        <NavigationLink to={item.route}>
                                            {item.label}
                                        </NavigationLink>
                                    </li>
                                ))}
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        session: state.sessionState.session,
        isAuthenticated: state.sessionState.authenticated,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogout: () => dispatch(actions.logout()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
