import { IAteco, ICitta, IMasterList, ISelectOption } from '../interfaces';

export const getSelectOptionsForCities = (list: Array<ICitta>): Array<ISelectOption> =>
    list && list.length > 0
        ? list.map((item: ICitta) => ({
              value: item.Id,
              label: `${item.Denominazione}${item.Provincia ? ' (' + item.Provincia + ')' : ''}`,
              optValue: item.Cap,
          }))
        : [];

export const getSelectOptionsForAteco = (list: Array<IAteco>): Array<ISelectOption> =>
    list && list.length > 0
        ? list.map((item: IAteco) => ({
              value: item.Id,
              label: `${item.Codice} - ${item.Descrizione}`,
          }))
        : [];

export const getSelectOptionsForMasterList = (list: Array<IMasterList>): Array<ISelectOption> =>
    list && list.length > 0
        ? list.map((item: IMasterList) => ({
              value: item.Id,
              label: `${item.Code} - ${item.Descrizione}`,
          }))
        : [];
