import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    styles?: object;
    size?: string;
    fullContent?: boolean;
    fullPage?: boolean;
    center?: boolean;
    label?: string;
}

const Loader = (props: IProps) => {
    return (
        <div
            style={props.styles}
            className={[
                classes.Loader,
                props.size && classes[`Loader--${props.size}`],
                props.fullContent && classes[`Loader--fullContent`],
                props.fullPage && classes[`Loader--fullPage`],
                props.center && classes[`Loader--center`],
            ].join(' ')}
        >
            <div className={classes['Loader-wrapper']}>
                <div className={classes['Loader-content']}>
                    <div className={classes['lds-ripple']}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                {props.label && <span>{props.label}</span>}
            </div>
        </div>
    );
};

export default Loader;
