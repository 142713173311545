import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from '../../shared/routes';
import { ISessionData } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';
import constants from '../../shared/constants';

interface IProps {
    isAuthenticated?: boolean;
    fetchSession: boolean;
    error: string;
    session: ISessionData;
    history: History;
    children?: any;
}

interface IState {
    authenticated: boolean;
}

class AuthWrapper extends Component<IProps, IState> {
    state = {
        authenticated: false,
    };

    componentDidMount(): void {
        const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

        if (!authToken) {
            this.props.history.push(routes.SIGNIN);
        } else {
            this.setState({
                authenticated: true,
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            !this.props.isAuthenticated
        ) {
            window.location.href = '';
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            window.location.href = '';
        }
    }

    render() {
        const { children } = this.props;
        const { authenticated } = this.state;

        return (
            <div className={classes.AuthWrapper}>
                {authenticated ? children : <Loader fullPage={true} size={'md'} />}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        session: state.sessionState.session,
        fetchingSession: state.sessionState.isFetching,
        error: state.sessionState.error,
        isAuthenticated: state.sessionState.authenticated,
    };
};

export default withRouter(connect(mapStateToProps)(AuthWrapper));
