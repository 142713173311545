import React from 'react';
import {
    IAteco,
    IControllo,
    IDittaSedeItem,
    ILegaleRappresentante,
    IMasterList,
    IRischio,
} from '../../shared/interfaces';
import DetailsWrapper from '../DetailsWrapper';
import Text from '../UI/Text';
import Label from '../UI/Label';
import FlexWrapper from '../UI/FlexWrapper';
import NoDataText from '../NoDataText';
import MapButton from '../MapButton';
import {
    ControlloMotivo,
    ControlloTipo,
    SedeCarattere,
    SedeClasse,
    SedeTipo,
} from '../../shared/enums';
import { getStringsValuesFromEnumValue } from '../../shared/helpers/utilities';
import dayjs from 'dayjs';

interface IProps {
    item: IDittaSedeItem;
}

const SedeDetails = (props: IProps) => {
    const { item } = props;

    return (
        <DetailsWrapper
            title={
                item.Ditta && (
                    <Text size={'md'} weight={'medium'}>
                        {item.Ditta.RagioneSociale}
                    </Text>
                )
            }
            subtitle={
                item.Ditta && (
                    <Text inline={true} label={'ID Fiscale'}>
                        {item.Ditta && item.Ditta.IdentificativoFiscale}
                    </Text>
                )
            }
            tabs={{
                titles: [
                    'Ditta',
                    'Titolarità',
                    'Sede Operativa',
                    'Attività',
                    'Categoria Rischio',
                    'Controlli',
                ],
                items: [
                    <div>
                        <Label size={'md'} for={'sede legale'}>
                            Sede legale
                        </Label>
                        {item.Ditta ? (
                            <>
                                <FlexWrapper withSeparator={true}>
                                    <Text weight={'medium'}>{item.Ditta.Citta}</Text>
                                    <Text>{item.Ditta.Cap}</Text>
                                </FlexWrapper>

                                <Text>{item.Ditta.Indirizzo}</Text>
                            </>
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                    <div>
                        <Label size={'md'} for={'legaleRappresentante'}>
                            Legali rappresentanti
                        </Label>
                        {item.Ditta &&
                        item.Ditta.LegaliRappresentanti &&
                        item.Ditta.LegaliRappresentanti.length > 0 ? (
                            item.Ditta.LegaliRappresentanti.map((data: ILegaleRappresentante) => (
                                <FlexWrapper
                                    key={data.Id}
                                    withSeparator={true}
                                    withMarginBottom={true}
                                >
                                    <Text>{data.Persona.Cognome}</Text>
                                    <Text>{data.Persona.Nome}</Text>
                                    <Text weight={'medium'}>{data.Persona.CodiceFiscale}</Text>
                                    <Text wrap={true}>
                                        {dayjs(data.Persona.DataNascita).format('DD/MM/YYYY')}
                                    </Text>
                                </FlexWrapper>
                            ))
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                    <FlexWrapper withSections={true}>
                        <section>
                            <Label size={'md'} for={'idFiscale'}>
                                Sede operativa
                            </Label>
                            {item.Sede ? (
                                <>
                                    <FlexWrapper withSeparator={true}>
                                        <Text weight={'medium'}>{item.Sede.Citta}</Text>
                                        <Text>{item.Sede.Cap}</Text>
                                    </FlexWrapper>
                                    <Text>{item.Sede.Indirizzo}</Text>
                                    <MapButton
                                        lng={item.Sede.Longitudine}
                                        lat={item.Sede.Latitudine}
                                    />
                                </>
                            ) : (
                                <NoDataText />
                            )}
                        </section>
                        <section>
                            <Label size={'md'} for={'idFiscale'}>
                                Numero identificativi associati
                            </Label>
                            {item.Sede ? (
                                <>
                                    <Text
                                        marginBottom={10}
                                        label={'N° di Registrazione'}
                                        wrap={true}
                                    >
                                        {item.Sede.NumeroRegistrazione}
                                    </Text>
                                    {item.Sede.NumeroIdentificativo && (
                                        <Text
                                            marginBottom={10}
                                            label={'N° Identificativo'}
                                            wrap={true}
                                        >
                                            {item.Sede.NumeroIdentificativo}
                                        </Text>
                                    )}
                                    {item.Sede.Classe >= 0 && (
                                        <Text marginBottom={10} label={'Classe'} wrap={true}>
                                            {SedeClasse[item.Sede.Classe]}
                                        </Text>
                                    )}
                                    {item.Sede.Tipo >= 0 && (
                                        <Text marginBottom={10} label={'Tipo'} wrap={true}>
                                            {SedeTipo[item.Sede.Tipo]}
                                        </Text>
                                    )}
                                    {item.Sede.CarattereAttivita >= 0 && (
                                        <Text
                                            marginBottom={10}
                                            label={'CarattereAttivita'}
                                            wrap={true}
                                        >
                                            {SedeCarattere[item.Sede.CarattereAttivita]}
                                        </Text>
                                    )}
                                    {item.Sede.DataInizioAttivita && (
                                        <Text label={'Inizio Attività'} wrap={true}>
                                            {dayjs(item.Sede.DataInizioAttivita).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </Text>
                                    )}
                                </>
                            ) : (
                                <NoDataText />
                            )}
                        </section>
                    </FlexWrapper>,
                    <FlexWrapper withSections={true}>
                        <section>
                            <Label size={'md'} for={'ateco'}>
                                Classificazione Ateco
                            </Label>
                            {item.Sede && item.Sede.Ateco && item.Sede.Ateco.length > 0 ? (
                                item.Sede.Ateco.map((data: IAteco) => (
                                    <FlexWrapper
                                        key={data.Id}
                                        withSeparator={true}
                                        withMarginBottom={true}
                                    >
                                        <Text weight={'medium'}>{data.Codice}</Text>
                                        <Text>{data.Descrizione}</Text>
                                    </FlexWrapper>
                                ))
                            ) : (
                                <NoDataText />
                            )}
                        </section>
                        <section>
                            <Label size={'md'} for={'masterList'}>
                                Classificazione MasterList
                            </Label>
                            {item.Sede &&
                            item.Sede.MasterList &&
                            item.Sede.MasterList.length > 0 ? (
                                item.Sede.MasterList.map((data: IMasterList) => (
                                    <FlexWrapper
                                        key={data.Id}
                                        withSeparator={true}
                                        withMarginBottom={true}
                                    >
                                        <Text weight={'medium'}>{data.Code}</Text>
                                        <Text>{data.Descrizione}</Text>
                                    </FlexWrapper>
                                ))
                            ) : (
                                <NoDataText />
                            )}
                        </section>
                    </FlexWrapper>,
                    <div>
                        <Label size={'md'} for={'rischio'}>
                            Categorie
                        </Label>
                        {item.Sede && item.Sede.Rischio && item.Sede.Rischio.length > 0 ? (
                            item.Sede.Rischio.map((data: IRischio) => (
                                <FlexWrapper key={data.Id} withSeparator={true}>
                                    <Text>{dayjs(data.Data).format('DD/MM/YYYY')}</Text>
                                    <Text weight={'medium'}>{data.Rischio}</Text>
                                </FlexWrapper>
                            ))
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                    <div>
                        {item.Controlli && item.Controlli.length > 0 ? (
                            item.Controlli.map((data: IControllo, ix: number) => (
                                <article key={ix}>
                                    <Text inline={true} label={'Data:'}>
                                        {dayjs(data.Data).format('DD/MM/YYYY HH:mm')}
                                    </Text>
                                    <Text inline={true} label={'Ragione Sociale:'}>
                                        {data.RagioneSociale}
                                    </Text>
                                    <Text inline={true} label={'Identificativo Fiscale:'}>
                                        {data.IdentificativoFiscale}
                                    </Text>
                                    <Text inline={true} label={'Sede:'}>
                                        {`${data.SedeIndirizzo} - ${data.SedeCitta}`}
                                    </Text>
                                    <Text inline={true} label={'N° Azioni / Campioni:'}>
                                        {`${data.NumAzioni} / ${data.NumCampioni}`}
                                    </Text>
                                    <Text inline={true} label={'Motivo:'}>
                                        {getStringsValuesFromEnumValue(
                                            ControlloMotivo,
                                            data.Motivo,
                                            ', '
                                        )}
                                    </Text>
                                    <Text inline={true} label={'Tipo:'}>
                                        {getStringsValuesFromEnumValue(
                                            ControlloTipo,
                                            data.Tipo,
                                            ', '
                                        )}
                                    </Text>
                                </article>
                            ))
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                ],
            }}
        />
    );
};

export default SedeDetails;
