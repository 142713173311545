import request from '../../shared/lib/request';
import { ISigninData } from '../../shared/interfaces';
const qs = require('querystring');

// API EP: https://auth.demetra.io/connect/token
const API_AUTH = process.env.REACT_APP_DEMETRA_AUTH_API_ENDPOINT;

type AuthParams = {
    readonly client_id: string;
    readonly client_secret: string;
    readonly scope: string;
    readonly grant_type: string;
    readonly username: string;
    readonly password: string;
};

const signin = (data: ISigninData) => {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    const qsData: AuthParams = data;

    return request({
        baseURL: API_AUTH,
        url: '/connect/token',
        method: 'POST',
        headers,
        data: qs.stringify(qsData),
    });
};

const SessionService = {
    signin,
};

export default SessionService;
