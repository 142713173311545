import React, { Component } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';
import classes from './styles.module.scss';
import { connect } from 'react-redux';
import { IDecretoForm, IDecreto } from '../../shared/interfaces';
import Field from '../Field';
import FlexWrapper from '../UI/FlexWrapper';
import Button from '../UI/Button';
import * as actions from '../../store/actions';
import constants from '../../shared/constants';
import Loader from '../UI/Loader';
import ErrorFeedback from '../UI/ErrorFeedback';
import Toast from '../UI/Toast';
import TipoDecretoSelector from '../TipoDecretoSelector';
import DateField from '../UI/DateField';
import { isValidURL } from '../../shared/helpers/utilities';
import dayjs from 'dayjs';

interface IProps {
    idDitta: string;
    idSede: string;
    IdLabAnalisiDitta: string;
    currentData?: IDecreto;
    waitingForSaving: boolean;
    error: any;
    decreti: Array<IDecreto>;
    onUpdate(idDitta: string, idSede: string, id: string, data: IDecretoForm): IDecreto;
    onCloseModal?(): void;
    onCreate(idDitta: string, idSede: string, data: IDecretoForm): IDecreto;
    lastUpdatedDecreto: string;
    lastCreatedDecreto: string;
}

interface IState {
    initialValues: IDecretoForm;
    currentId: string | null;
    tipoDecreto: number;
    date: string;
}

class LaboratorioDecretoForm extends Component<IProps, IState> {
    state = {
        initialValues: {
            IdLabAnalisiDitta: this.props.IdLabAnalisiDitta,
            Data: '',
            Numero: '',
            TipoDecreto: 0,
            Id: constants.EMPTY_UUID,
            Url: null,
        },
        currentId: null,
        tipoDecreto: 0,
        date: null,
    };

    componentDidMount() {
        const { currentData } = this.props;
        if (currentData) {
            const initialValues = {
                IdLabAnalisiDitta: currentData.IdLabAnalisiDitta,
                Data: currentData.Data,
                Numero: currentData.Numero,
                TipoDecreto: currentData.TipoDecreto,
                Id: currentData.Id,
                Url: currentData.Url,
            };

            let tipoDecreto = currentData.TipoDecreto || 0;

            let date = currentData.Data;

            this.setState({
                initialValues,
                currentId: currentData.Id,
                tipoDecreto,
                date,
            });
        }
    }

    onDateChanged = (value: Date) => {
        let date = dayjs(value).format('YYYY-MM-DD');

        this.setState({
            date,
        });
    };

    onTipoChanged = (tipo: number) => {
        this.setState({
            tipoDecreto: tipo,
        });
    };

    onSubmitClicked = (values, { setSubmitting }) => {
        setSubmitting(false);

        let data = {
            ...values,
            Data: dayjs(this.state.date).format('YYYY-MM-DD'),
            TipoDecreto: this.state.tipoDecreto,
        };

        if (this.state.currentId) {
            this.props.onUpdate(this.props.idDitta, this.props.idSede, this.state.currentId, data);
        } else {
            this.props.onCreate(this.props.idDitta, this.props.idSede, data);
        }
    };

    onValidate = (values: FormikValues) => {
        const errors: FormikErrors<any> = {};

        if (!values.Numero) {
            errors.Numero = 'Campo obbligatorio';
        }

        if (values.Url) {
            if (values.Url && !isValidURL(values.Url)) {
                errors.Url = 'Verificare indirizzo per il download.';
            }
        }

        return errors;
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastUpdatedDecreto !== prevProps.lastUpdatedDecreto &&
            this.props.lastUpdatedDecreto &&
            this.props.onCloseModal
        ) {
            Toast({ message: 'Record aggiornato con successo.', type: 'success' });
            this.props.onCloseModal();
        }
        if (
            this.props.lastCreatedDecreto !== prevProps.lastCreatedDecreto &&
            this.props.lastCreatedDecreto &&
            this.props.onCloseModal
        ) {
            Toast({ message: 'Record creato con successo.', type: 'success' });
            this.props.onCloseModal();
        }
    }

    render() {
        const { initialValues, date } = this.state;
        const { waitingForSaving, error } = this.props;

        console.log(date);

        return (
            <section className={classes.LaboratorioDecretoForm}>
                {waitingForSaving && <Loader fullContent={true} />}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={this.onValidate}
                    onSubmit={this.onSubmitClicked}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        label={'Numero'}
                                        type={'text'}
                                        name={'Numero'}
                                        placeholder={'Numero'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Numero}
                                        size={'sm'}
                                        disabled={waitingForSaving}
                                        styles={{
                                            maxWidth: '200px',
                                        }}
                                        error={errors.Numero && touched.Numero && errors.Numero}
                                    />
                                    <DateField
                                        current={date}
                                        label={'Data'}
                                        onDateChanged={this.onDateChanged}
                                    />
                                    <Field
                                        label={'Download link (URL)'}
                                        type={'url'}
                                        name={'Url'}
                                        placeholder={'Download link'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Url}
                                        size={'sm'}
                                        disabled={waitingForSaving}
                                        styles={{
                                            maxWidth: '300px',
                                        }}
                                        error={errors.Url && touched.Url && errors.Url}
                                    />
                                    <TipoDecretoSelector
                                        label={'Tipo decreto'}
                                        selected={this.state.tipoDecreto}
                                        changed={(selected) => this.onTipoChanged(+selected.value)}
                                    />
                                </FlexWrapper>
                                {error && <ErrorFeedback>{error}</ErrorFeedback>}
                                <footer>
                                    <Button
                                        theme={'success'}
                                        type="submit"
                                        disabled={waitingForSaving || isSubmitting}
                                    >
                                        Invia
                                    </Button>
                                </footer>
                            </form>
                        );
                    }}
                </Formik>
            </section>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        waitingForSaving:
            state.laboratorioState.isUpdatingDecreto || state.laboratorioState.isCreatingDecreto,
        error: state.laboratorioState.error,
        decreti: state.laboratorioState.decreti,
        lastUpdatedDecreto: state.laboratorioState.lastUpdatedDecreto,
        lastCreatedDecreto: state.laboratorioState.lastCreatedDecreto,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdate: (idDitta: string, idSede: string, id: string, data: IDecretoForm) =>
            dispatch(actions.updateDecretoSedeLaboratorio(idDitta, idSede, id, data)),
        onCreate: (idDitta: string, idSede: string, data: IDecretoForm) =>
            dispatch(actions.createDecretoSedeLaboratorio(idDitta, idSede, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaboratorioDecretoForm);
