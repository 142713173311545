import React from 'react';
import classes from './styles.module.scss';
import Checkbox from 'react-simple-checkbox';

interface IProps {
    children?: string | object;
    checked?: boolean;
    changed(isChecked: boolean): void;
    dark?: boolean;
}

const CheckBox = (props: IProps) => {
    const { children, checked, changed, dark } = props;

    return (
        <div className={classes.Checkbox}>
            <div className={classes['Checkbox-input']}>
                <Checkbox
                    tickAnimationDuration={250}
                    backAnimationDuration={10}
                    color={{
                        uncheckedBorderColor: '#949EB3',
                        backgroundColor: dark ? '#0D6FEF' : '#949EB3',
                        borderColor: dark ? '#0D6FEF' : '#949EB3',
                    }}
                    size={3}
                    borderThickness={1}
                    // @ts-ignore
                    onChange={changed}
                    checked={checked || false}
                />
            </div>
            <label className={classes['Checkbox-label']}>{children}</label>
        </div>
    );
};

export default CheckBox;
