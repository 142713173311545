import constants from '../constants';
import { ISessionData } from '../interfaces';
import dayjs from 'dayjs';

export const checkSession = (): boolean => {
    const currentDate = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.LAST_SESSION_DATE);

    if (currentDate) {
        return (
            localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN) !== null &&
            dayjs(currentDate).isSame(dayjs().format('YYYY-MM-DD'))
        );
    }

    return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN) !== null;
};

export const resetSession = (): void => {
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);
    //localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_NAME);
    //localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL);
    //localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
};

export const setSession = (session: ISessionData): void => {
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN, session.access_token);
    localStorage.setItem(
        constants.LOCAL_STORAGE.KEYS.LAST_SESSION_DATE,
        dayjs().format('YYYY-MM-DD')
    );
};
