import * as React from 'react';
import classes from './styles.module.scss';
import { IIconButton } from '../../../shared/interfaces';

const IconButton = (props: IIconButton) => {
    const { icon, clicked, disabled, theme, withMargin, size } = props;

    const customClasses = [
        classes.IconButton,
        theme ? classes[`IconButton--${theme}`] : '',
        size ? classes[`IconButton--${size}`] : '',
        withMargin ? classes[`IconButton--withMargin`] : '',
    ].join(' ');

    return (
        <button
            type={'button'}
            onClick={clicked}
            disabled={disabled || false}
            className={customClasses}
        >
            <i className={`fas fa-${icon}`} />
        </button>
    );
};

export default IconButton;
