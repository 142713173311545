import React from 'react';
import classes from './styles.module.scss';
import Label from '../UI/Label';

interface IProps {
    label?: string;
    value: string;
}

const CounterWrapper = (props: IProps) => {
    return (
        <section className={classes.CounterWrapper}>
            {props.label && (
                <Label size={'lg'} for={'counter'}>
                    {props.label}
                </Label>
            )}
            <span>{props.value}</span>
        </section>
    );
};

export default CounterWrapper;
