import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import {
    IControllo,
    IControlloSearchParams,
    IMotivoControllo,
    IProvinceList,
    ISelectOption,
    ITipoControllo,
} from '../../shared/interfaces';
import Field from '../../components/Field';
import Loader from '../../components/UI/Loader';
import CitySelect from '../../components/CitySelect';
import AtecoSelect from '../../components/AtecoSelect';
import MasterListSelect from '../../components/MasterListSelect';
import CheckBox from '../../components/UI/Checkbox';
import constants from '../../shared/constants';
import tables from '../../shared/data/tables';
import Filters from '../../components/Filters';
import DatesRange from '../../components/UI/DatesRange';
import dayjs from 'dayjs';
import Grid from '../../components/Grid';
import { createFile, getControlliRows } from '../../shared/helpers/dataRows';
import ModalWrapper from '../../components/Modal';
import {
    getNumberArrayFromCheckboxItem,
    getValueFromEnumArray,
} from '../../shared/helpers/utilities';
import Tabs from '../../components/Tabs';
import FlexWrapper from '../../components/UI/FlexWrapper';
import CounterWrapper from '../../components/CounterWrapper';
import ControlloDetails from '../../components/ControlloDetails';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
    list: Array<IControllo>;
    waitingForList: boolean;
    error: any;
    onGetData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        tipo: number,
        motivo: number,
        dataInizio: string,
        dataFine: string,
        page: number
    ): Array<IControllo>;
    onResetData(): void;
    waitingForItem: boolean;
    onGetItem(id: string): IControllo;
    totalPages: number;
    totalRecords: number;
    item: IControllo;
    onGetCounter(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        tipo: number,
        motivo: number,
        dataInizio: string,
        dataFine: string
    ): number;
    showCounter: boolean;
    onExportData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        tipo: number,
        motivo: number,
        dataInizio: string,
        dataFine: string
    ): number;
    waitingForFileCreation: boolean;
    stream: ArrayBuffer | null;
}

interface IState {
    searchParams: IControlloSearchParams;
    selected: {
        cities: Array<ISelectOption>;
        ateco: Array<ISelectOption>;
        masterList: Array<ISelectOption>;
    };
    province: IProvinceList;
    tipiControllo: ITipoControllo;
    motiviControllo: IMotivoControllo;
    showDetailsModal: boolean;
}

class Controlli extends Component<IProps & RouteComponentProps, IState> {
    state = {
        searchParams: {
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        },
        selected: {
            cities: [],
            ateco: [],
            masterList: [],
        },
        province: constants.PROVINCE,
        tipiControllo: constants.TIPI_CONTROLLO,
        motiviControllo: constants.MOTIVI_CONTROLLO,
        showDetailsModal: false,
    };

    handleSelectedOptions = (name: string, selected: Array<ISelectOption> | ISelectOption) => {
        let newSelected = {
            ...this.state.selected,
            [name]: selected || [],
        };

        this.setState({
            selected: newSelected,
        });
    };

    handleSearchChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const keyword = e.currentTarget.value;

        let searchParams = {
            ...this.state.searchParams,
            keyword,
        };

        this.setState({
            searchParams,
        });
    };

    onSelectMotivo = (motivo: string, checked: boolean) => {
        let motiviControllo = {
            ...this.state.motiviControllo,
            [motivo]: {
                ...this.state.motiviControllo[motivo],
                checked,
            },
        };

        this.setState(
            {
                motiviControllo,
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onSelectTipo = (tipo: string, checked: boolean) => {
        let tipiControllo = {
            ...this.state.tipiControllo,
            [tipo]: {
                ...this.state.tipiControllo[tipo],
                checked,
            },
        };

        this.setState(
            {
                tipiControllo,
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onSelectProv = (prov: string, checked: boolean) => {
        let province = {
            ...this.state.province,
            [prov]: {
                ...this.state.province[prov],
                checked,
            },
        };

        this.setState({
            province,
        });
    };

    onDatesRangeChanged = (type: 'dataInizio' | 'dataFine', value: Date) => {
        let searchParams = {
            ...this.state.searchParams,
            [type]: dayjs(value).format('YYYY-MM-DD'),
        };

        this.setState({
            searchParams,
        });
    };

    onSearchData = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { masterList, ateco, cities },
            province,
            motiviControllo,
            tipiControllo,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const tipiFilter = getNumberArrayFromCheckboxItem(tipiControllo);
        const motiviFilter = getNumberArrayFromCheckboxItem(motiviControllo);

        this.props.onGetData(
            keyword,
            provFilter,
            cities.map((item) => item.value),
            masterList.map((item) => item.value),
            ateco.map((item) => item.value),
            getValueFromEnumArray(tipiFilter),
            getValueFromEnumArray(motiviFilter),
            dataInizio,
            dataFine,
            page
        );
    };

    onToggleModalVisibility = () => {
        const showDetailsModal = !this.state.showDetailsModal;

        this.setState({ showDetailsModal });
    };

    onRowSelected = (data: Array<any>) => {
        //console.log('Row selected', data[0]);
        this.onToggleModalVisibility();
        if (data && data.length > 0) {
            this.props.onGetItem(data[0].id);
        }
    };

    loadMore = () => {
        let searchParams = {
            ...this.state.searchParams,
            page: this.state.searchParams.page + 1,
        };
        this.setState({ searchParams }, () => {
            this.onSearchData();
        });
    };

    onGetCounterClicked = () => {
        this.props.onResetData();

        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { masterList, ateco, cities },
            province,
            motiviControllo,
            tipiControllo,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const tipiFilter = getNumberArrayFromCheckboxItem(tipiControllo);
        const motiviFilter = getNumberArrayFromCheckboxItem(motiviControllo);

        setTimeout(() => {
            this.props.onGetCounter(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                getValueFromEnumArray(tipiFilter),
                getValueFromEnumArray(motiviFilter),
                dataInizio,
                dataFine,
                page
            );
        }, 300);
    };

    onExportClicked = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { masterList, ateco, cities },
            province,
            motiviControllo,
            tipiControllo,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const tipiFilter = getNumberArrayFromCheckboxItem(tipiControllo);
        const motiviFilter = getNumberArrayFromCheckboxItem(motiviControllo);

        setTimeout(() => {
            this.props.onExportData(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                getValueFromEnumArray(tipiFilter),
                getValueFromEnumArray(motiviFilter),
                dataInizio,
                dataFine,
                page
            );
        }, 300);
    };

    onSubmitClicked = () => {
        this.props.onResetData();

        setTimeout(() => {
            this.onSearchData();
        }, 300);
    };

    resetFilters = () => {
        const searchParams = {
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        };
        const selected = {
            cities: [],
            ateco: [],
            masterList: [],
        };

        const province = constants.PROVINCE;
        const motiviControllo = constants.MOTIVI_CONTROLLO;
        const tipiControllo = constants.TIPI_CONTROLLO;

        this.setState(
            {
                searchParams,
                selected,
                province,
                motiviControllo,
                tipiControllo,
            },
            () => {
                this.props.onResetData();
            }
        );
    };

    resetPaging = () => {
        const searchParams = {
            ...this.state.searchParams,
            page: 1,
        };

        this.setState({ searchParams }, () => {
            this.props.onResetData();
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.stream !== prevProps.stream && this.props.stream) {
            createFile(this.props.stream, 'Elenco_controlli', 'xlsx', true);
        }
    }

    render() {
        const {
            searchParams: { keyword, dataFine, dataInizio },
            province,
            showDetailsModal,
            selected,
            tipiControllo,
            motiviControllo,
        } = this.state;

        const {
            waitingForList,
            list,
            totalRecords,
            item,
            waitingForItem,
            isLastPage,
            showCounter,
            waitingForFileCreation,
        } = this.props;

        const tipiControlloTpl = Object.keys(tipiControllo).map((tipo: string) => {
            return (
                <CheckBox
                    key={tipo}
                    checked={tipiControllo[tipo].checked}
                    changed={(checked: boolean) => this.onSelectTipo(tipo, checked)}
                >
                    {tipiControllo[tipo].label}
                </CheckBox>
            );
        });

        const motiviControlloTpl = Object.keys(motiviControllo).map((motivo: string) => {
            return (
                <CheckBox
                    key={motivo}
                    checked={motiviControllo[motivo].checked}
                    changed={(checked: boolean) => this.onSelectMotivo(motivo, checked)}
                >
                    {motiviControllo[motivo].label}
                </CheckBox>
            );
        });

        const provinceTpl = Object.keys(province).map((prov: string) => {
            return (
                <CheckBox
                    key={prov}
                    checked={province[prov].checked}
                    changed={(checked: boolean) => this.onSelectProv(prov, checked)}
                >
                    {province[prov].label}
                </CheckBox>
            );
        });

        return (
            <AuthWrapper>
                <main className={classes.Controlli}>
                    <Title>{'Controlli'}</Title>
                    <Filters
                        onSubmitClicked={this.onSubmitClicked}
                        onResetClicked={this.resetFilters}
                        onGetCounter={this.onGetCounterClicked}
                        onExportClicked={this.onExportClicked}
                        undoLabel={'Reset filtri'}
                        submitLabel={'Ricerca'}
                    >
                        <Tabs
                            tabs={{
                                titles: ['Generico', 'Tipo', 'Posizione', 'Attività'],
                                items: [
                                    <FlexWrapper noMargin={true} withSections={true}>
                                        <section>
                                            <Field
                                                label={
                                                    'Ricerca per Ragione Sociale, ID Fiscale o PEC'
                                                }
                                                noMargin={true}
                                                name={'search'}
                                                type={'text'}
                                                changed={this.handleSearchChanged}
                                                value={keyword}
                                                placeholder={'Ricerca...'}
                                            />
                                        </section>
                                        <section>
                                            <DatesRange
                                                label={'Range date'}
                                                onStartDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataInizio', value)
                                                }
                                                onEndDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataFine', value)
                                                }
                                                startDate={dataInizio}
                                                endDate={dataFine}
                                            />
                                        </section>
                                    </FlexWrapper>,
                                    <div>
                                        <FlexWrapper
                                            withMarginBottom={true}
                                            label={'Tipi controllo'}
                                            name={'tipi'}
                                        >
                                            {tipiControlloTpl}
                                        </FlexWrapper>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Motivi controllo'}
                                            name={'moviti'}
                                        >
                                            {motiviControlloTpl}
                                        </FlexWrapper>
                                    </div>,
                                    <>
                                        <div>
                                            <CitySelect
                                                label={'Ricerca per città'}
                                                name={'cities'}
                                                selected={selected.cities}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('cities', values)
                                                }
                                            />
                                        </div>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Province'}
                                            name={'province'}
                                        >
                                            {provinceTpl}
                                        </FlexWrapper>
                                    </>,
                                    <>
                                        <div>
                                            <AtecoSelect
                                                label={'Ricerca per Ateco'}
                                                name={'ateco'}
                                                selected={selected.ateco}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('ateco', values)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <MasterListSelect
                                                label={'Ricerca per Master List'}
                                                name={'masterList'}
                                                selected={selected.masterList}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('masterList', values)
                                                }
                                            />
                                        </div>
                                    </>,
                                ],
                            }}
                        />
                    </Filters>
                    {waitingForFileCreation && (
                        <Loader label={'Attendere qualche secondo...'} fullPage={true} />
                    )}
                    {list && list.length > 0 && (
                        <Grid
                            rowHeight={100}
                            currentNumberOfRecords={list.length}
                            totalRecords={totalRecords}
                            rows={getControlliRows(list)}
                            columns={tables.CONTROLLI}
                            onShowMoreClicked={this.loadMore}
                            loading={waitingForList}
                            isLastPage={isLastPage}
                            onRowSelected={this.onRowSelected}
                        />
                    )}
                    {showCounter && !waitingForList && !waitingForItem && (
                        <CounterWrapper value={totalRecords} label={'Record trovati'} />
                    )}
                    {waitingForList && !list.length && (
                        <section className={classes['Controlli-loading']}>
                            <Loader center={true} fullContent={true} />
                        </section>
                    )}
                    <ModalWrapper
                        title={'Dettaglio del Controllo'}
                        visible={showDetailsModal}
                        onCloseClicked={() => this.setState({ showDetailsModal: false })}
                    >
                        {waitingForItem && <Loader center={true} fullContent={true} />}
                        {item && <ControlloDetails item={item} />}
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        list: state.controlloState.list,
        item: state.controlloState.selected,
        waitingForList: state.controlloState.isFetchingList,
        waitingForItem: state.controlloState.isFetchingItem,
        error: state.controlloState.error,
        isLastPage: state.controlloState.isLastPage,
        totalRecords: state.controlloState.totalRecords,
        totalPages: state.controlloState.totalPages,
        showCounter:
            state.controlloState.totalRecords >= 0 &&
            state.controlloState.onlyCounter &&
            !state.controlloState.list.length,
        waitingForFileCreation: state.controlloState.isCreatingFile,
        stream: state.controlloState.stream,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            tipo: number,
            motivo: number,
            dataInizio: string = null,
            dataFine: string = null,
            page: number
        ) =>
            dispatch(
                actions.fetchControlli(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    tipo,
                    motivo,
                    dataInizio,
                    dataFine,
                    page
                )
            ),
        onResetData: () => dispatch(actions.resetControlli()),
        onGetItem: (id: string) => dispatch(actions.fetchControllo(id)),
        onGetCounter: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            tipo: number,
            motivo: number,
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(
                actions.fetchControlliCounter(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    tipo,
                    motivo,
                    dataInizio,
                    dataFine
                )
            ),
        onExportData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            tipo: number,
            motivo: number,
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(
                actions.exportControlliData(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    tipo,
                    motivo,
                    dataInizio,
                    dataFine
                )
            ),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Controlli));
