import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterWrapper from './containers/RouterWrapper';
import Header from './components/Header';

function App() {
    return (
        <BrowserRouter>
            <Header />
            <RouterWrapper />
        </BrowserRouter>
    );
}

export default App;
