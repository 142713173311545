import React, { Component } from 'react';
import classes from './styles.module.scss';
import CheckBox from '../Checkbox';

interface IProps {
    answerChanged(name: string, checked: boolean): void;
    values: {
        yes: boolean;
        no: boolean;
    };
    label: string;
}

class Question extends Component<IProps> {
    render() {
        const { label, values, answerChanged } = this.props;

        return (
            <section
                className={[
                    classes.Question,
                    !values.yes && !values.no ? classes['Question--off'] : '',
                ].join(' ')}
            >
                {label && <label className={classes['Question-label']}>{label}</label>}
                <div className={classes['Question-content']}>
                    <CheckBox
                        changed={(checked: boolean) => answerChanged('yes', checked)}
                        checked={values.yes}
                    >
                        {'SI'}
                    </CheckBox>
                    <CheckBox
                        changed={(checked: boolean) => answerChanged('no', checked)}
                        checked={values.no}
                    >
                        {'NO'}
                    </CheckBox>
                </div>
            </section>
        );
    }
}

export default Question;
