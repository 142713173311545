import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const get = (keyword: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    const endpoint = `/basedata/masterList?stringaRicerca=${keyword}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const MasterListService = {
    get,
};

export default MasterListService;
