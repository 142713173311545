import * as actionTypes from '../actions/actionTypes';
import { IAteco } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    data: Array<IAteco>;
    error: string;
}

const INITIAL_STATE = {
    list: [],
    isFetching: false,
    didInvalidate: false,
    error: null,
};

const fetchAtecoByKeywordStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        error: null,
        didInvalidate: false,
        isFetching: true,
    });
};

const fetchAtecoByKeywordSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.data,
        isFetching: false,
    });
};

const fetchAtecoByKeywordFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetching: false,
        didInvalidate: true,
    });
};

const atecoReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_ATECO_BY_KEYWORD_START:
            return fetchAtecoByKeywordStart(state);
        case actionTypes.FETCH_ATECO_BY_KEYWORD_SUCCESS:
            return fetchAtecoByKeywordSuccess(state, action);
        case actionTypes.FETCH_ATECO_BY_KEYWORD_FAIL:
            return fetchAtecoByKeywordFail(state, action);

        default:
            return state;
    }
};

export default atecoReducer;
