import React, { Component } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';
import classes from './styles.module.scss';
import { connect } from 'react-redux';
import {
    ILaboratorioSedeForm,
    IFormatoreSede,
    ISelectOption,
    ILaboratorioSede,
    ICheckboxItem,
} from '../../shared/interfaces';
import CitySelect from '../CitySelect';
import Field from '../Field';
import FlexWrapper from '../UI/FlexWrapper';
import Button from '../UI/Button';
import * as actions from '../../store/actions';
import constants from '../../shared/constants';
import Loader from '../UI/Loader';
import ErrorFeedback from '../UI/ErrorFeedback';
import Toast from '../UI/Toast';
import CheckBox from '../UI/Checkbox';
import Textarea from '../Textarea';

interface IProps {
    currentData?: ILaboratorioSede;
    waitingForSaving: boolean;
    error: any;
    onUpdate(idFormatore: string, id: string, data: ILaboratorioSedeForm): IFormatoreSede;
    onCloseModal?(): void;
    lastUpdatedSede: string;
}

interface IState {
    initialValues: ILaboratorioSedeForm;
    officeCity: ISelectOption;
    isAccreditato: ICheckboxItem;
    currentId: string | null;
}

class LaboratorioSedeForm extends Component<IProps, IState> {
    state = {
        initialValues: {
            IdLabAnalisiDitta: constants.EMPTY_UUID,
            Indirizzo: '',
            IndirizzoDettagli: '',
            IdCitta: '',
            Citta: '',
            Cap: '',
            ContattiTelefonici: '',
            Email: '',
            ResponsabileLaboratorio: '',
            Id: constants.EMPTY_UUID,
            NumeroIscrizione: '',
            IsAccreditato: false,
            AccreditamentoRiferimento: '',
            AccreditamentoNote: '',
        },
        officeCity: null,
        currentId: null,
        isAccreditato: {
            label: 'Accreditato',
            value: 0,
            checked: true,
        },
    };

    componentDidMount() {
        const { currentData } = this.props;
        if (currentData) {
            const initialValues = {
                Id: currentData.Id,
                IdLabAnalisiDitta: currentData.IdLabAnalisiDitta,
                Indirizzo: currentData.Indirizzo || '',
                IndirizzoDettagli: currentData.IndirizzoDettagli || '',
                IdCitta: currentData.IdCitta,
                Citta: currentData.Citta || '',
                Cap: currentData.Cap || '',
                ContattiTelefonici: currentData.ContattiTelefonici || '',
                Email: currentData.Email || '',
                ResponsabileLaboratorio: currentData.ResponsabileLaboratorio || '',
                NumeroIscrizione: currentData.NumeroIscrizione || '',
                IsAccreditato: currentData.IsAccreditato,
                AccreditamentoRiferimento: currentData.AccreditamentoRiferimento || '',
                AccreditamentoNote: currentData.AccreditamentoNote || '',
            };

            const isAccreditato = {
                ...this.state.isAccreditato,
                checked: currentData.IsAccreditato || false,
            };

            let officeCity: ISelectOption = null;

            if (currentData.IdCitta) {
                officeCity = {
                    value: currentData.IdCitta,
                    label: currentData.Citta,
                };
            }

            this.setState({
                initialValues,
                officeCity,
                currentId: currentData.Id,
                isAccreditato,
            });
        }
    }

    onSubmitClicked = (values, { setSubmitting }) => {
        setSubmitting(false);

        if (this.state.officeCity && this.state.officeCity.value) {
            const { officeCity } = this.state;

            const { currentData } = this.props;

            const data = {
                ...values,
                IdCitta: officeCity.value,
                Citta: officeCity.label,
                IsAccreditato: this.state.isAccreditato.checked || false,
            };

            this.props.onUpdate(currentData.Ditta.Id, currentData.Id, data);
        } else {
            Toast({ message: 'Inserisci la Città.', type: 'error' });
        }
    };

    onValidate = (values: FormikValues) => {
        const errors: FormikErrors<any> = {};

        if (!values.Indirizzo) {
            errors.Indirizzo = 'Campo obbligatorio';
        }

        return errors;
    };

    onOfficeCitySelected = (selected: ISelectOption, setFieldValue) => {
        setFieldValue('Cap', selected.optValue); // Formik trick
        this.setState({
            officeCity: selected,
        });
    };

    onCheckedIsAccreditato = (checked: boolean) => {
        let isAccreditato = {
            ...this.state.isAccreditato,
            checked,
        };

        this.setState({
            isAccreditato,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastUpdatedSede !== prevProps.lastUpdatedSede &&
            this.props.lastUpdatedSede &&
            this.props.onCloseModal
        ) {
            Toast({ message: 'Record aggiornato con successo.', type: 'success' });
            this.props.onCloseModal();
        }
    }

    render() {
        const { initialValues, officeCity } = this.state;
        const { waitingForSaving, error } = this.props;

        return (
            <section className={classes.LaboratorioSedeForm}>
                {waitingForSaving && <Loader fullContent={true} />}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={this.onValidate}
                    onSubmit={this.onSubmitClicked}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        label={'Indirizzo'}
                                        type={'text'}
                                        name={'Indirizzo'}
                                        placeholder={'Indirizzo sede legale'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Indirizzo}
                                        size={'sm'}
                                        disabled={waitingForSaving}
                                        styles={{
                                            maxWidth: '500px',
                                        }}
                                        error={
                                            errors.Indirizzo &&
                                            touched.Indirizzo &&
                                            errors.Indirizzo
                                        }
                                    />
                                    <Field
                                        label={'Dettagli'}
                                        type={'text'}
                                        name={'IndirizzoDettagli'}
                                        placeholder={
                                            'Informazioni utili per identificazione indirizzo'
                                        }
                                        changed={handleChange}
                                        disabled={waitingForSaving}
                                        blurred={handleBlur}
                                        value={values.IndirizzoDettagli}
                                        styles={{
                                            maxWidth: '500px',
                                        }}
                                        size={'sm'}
                                        error={
                                            errors.IndirizzoDettagli &&
                                            touched.IndirizzoDettagli &&
                                            errors.IndirizzoDettagli
                                        }
                                    />
                                </FlexWrapper>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <CitySelect
                                        styles={{
                                            maxWidth: '400px',
                                            marginRight: '20px',
                                        }}
                                        label={'Città'}
                                        single={true}
                                        selected={officeCity}
                                        disabled={waitingForSaving}
                                        onSelectedChanged={(sel: ISelectOption) =>
                                            this.onOfficeCitySelected(sel, setFieldValue)
                                        }
                                    />
                                    <Field
                                        label={'CAP'}
                                        disabled={waitingForSaving}
                                        type={'text'}
                                        name={'Cap'}
                                        placeholder={'CAP'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Cap}
                                        size={'sm'}
                                        styles={{
                                            maxWidth: '70px',
                                        }}
                                        error={errors.Cap && touched.Cap && errors.Cap}
                                    />
                                </FlexWrapper>
                                <FlexWrapper withFields={true} withMarginBottom={true}>
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Contatti telefonici'}
                                        type={'text'}
                                        name={'ContattiTelefonici'}
                                        placeholder={'Inserisci i recapiti telefonici'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.ContattiTelefonici}
                                        size={'sm'}
                                        styles={{
                                            maxWidth: '600px',
                                        }}
                                        error={
                                            errors.ContattiTelefonici &&
                                            touched.ContattiTelefonici &&
                                            errors.ContattiTelefonici
                                        }
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Email'}
                                        type={'text'}
                                        name={'Email'}
                                        placeholder={'Inserisci indirizzo email'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.Email}
                                        styles={{
                                            maxWidth: '400px',
                                        }}
                                        size={'sm'}
                                        error={errors.Email && touched.Email && errors.Email}
                                    />
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Responsabile'}
                                        type={'text'}
                                        name={'ResponsabileLaboratorio'}
                                        placeholder={'Inserisci nominativo'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        styles={{
                                            maxWidth: '400px',
                                        }}
                                        value={values.ResponsabileLaboratorio}
                                        size={'sm'}
                                        error={
                                            errors.ResponsabileLaboratorio &&
                                            touched.ResponsabileLaboratorio &&
                                            errors.ResponsabileLaboratorio
                                        }
                                    />
                                </FlexWrapper>
                                <FlexWrapper withMarginBottom={true}>
                                    <CheckBox
                                        checked={this.state.isAccreditato.checked}
                                        changed={(checked: boolean) =>
                                            this.onCheckedIsAccreditato(checked)
                                        }
                                    >
                                        {this.state.isAccreditato.label}
                                    </CheckBox>
                                </FlexWrapper>
                                <FlexWrapper withMarginBottom={true}>
                                    <Field
                                        disabled={waitingForSaving}
                                        label={'Rif. Accreditamento'}
                                        type={'text'}
                                        name={'AccreditamentoRiferimento'}
                                        placeholder={'Link al documento'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.AccreditamentoRiferimento}
                                        styles={{
                                            maxWidth: '800px',
                                        }}
                                        size={'sm'}
                                        error={
                                            errors.AccreditamentoRiferimento &&
                                            touched.AccreditamentoRiferimento &&
                                            errors.AccreditamentoRiferimento
                                        }
                                    />
                                </FlexWrapper>
                                <FlexWrapper withMarginBottom={true}>
                                    <Textarea
                                        disabled={waitingForSaving}
                                        label={'Note accreditamento'}
                                        name={'AccreditamentoNote'}
                                        placeholder={'Inserisci note'}
                                        changed={handleChange}
                                        blurred={handleBlur}
                                        value={values.AccreditamentoNote}
                                        styles={{
                                            maxWidth: '800px',
                                        }}
                                        error={
                                            errors.AccreditamentoNote &&
                                            touched.AccreditamentoNote &&
                                            errors.AccreditamentoNote
                                        }
                                    />
                                </FlexWrapper>
                                {error && <ErrorFeedback>{error}</ErrorFeedback>}
                                <footer>
                                    <Button
                                        theme={'success'}
                                        type="submit"
                                        disabled={waitingForSaving || isSubmitting}
                                    >
                                        Invia
                                    </Button>
                                </footer>
                            </form>
                        );
                    }}
                </Formik>
            </section>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        waitingForSaving: state.laboratorioState.isUpdatingSede,
        error: state.laboratorioState.error,
        lastUpdatedSede: state.laboratorioState.lastUpdatedSede,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdate: (idLaboratorio: string, idSede: string, data: ILaboratorioSedeForm) =>
            dispatch(actions.updateSedeLaboratorio(idLaboratorio, idSede, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaboratorioSedeForm);
