import React, { Component } from 'react';
import classes from './styles.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import './override.scss';

interface IProps {
    columns: Array<ColDef>;
    rows: Array<any>;
    onShowMoreClicked(): void;
    currentNumberOfRecords: number;
    totalRecords: number;
    loading?: boolean;
    isLastPage?: boolean;
    onRowSelected?(data: Array<any>): void;
    rowHeight?: number;
    rowsRules?: any;
}

class Grid extends Component<IProps> {
    private gridApi: GridApi;

    onGridReady = (params) => {
        this.gridApi = params.api;
    };

    onSelectionChanged = () => {
        const selectedRows = this.gridApi.getSelectedRows();

        if (this.props.onRowSelected) {
            this.props.onRowSelected(selectedRows);
        }
    };

    render() {
        const {
            columns,
            rows,
            onShowMoreClicked,
            loading,
            currentNumberOfRecords,
            totalRecords,
            isLastPage,
            rowHeight,
            rowsRules,
        } = this.props;

        return (
            <section className={classes.Grid}>
                <section className={classes['Grid-info']}>
                    <div>
                        <span>{'Visualizzati'}</span>
                        <strong>{currentNumberOfRecords}</strong>
                        <span>{'di'}</span>
                        <strong>{totalRecords}</strong>
                    </div>
                </section>
                <div
                    className={'ag-theme-alpine'}
                    style={{
                        height: currentNumberOfRecords
                            ? `${currentNumberOfRecords * (rowHeight || 42) + 52}px`
                            : '100px',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        onSelectionChanged={this.onSelectionChanged}
                        onGridReady={this.onGridReady}
                        columnDefs={columns}
                        rowData={rows}
                        gridOptions={{
                            rowHeight: rowHeight || 42,
                        }}
                        rowSelection={'single'}
                        rowClassRules={rowsRules}
                    />
                </div>
                <section className={classes['Grid-info']}>
                    <div>
                        <span>{'Visualizzati'}</span>
                        <strong>{currentNumberOfRecords}</strong>
                        <span>{'di'}</span>
                        <strong>{totalRecords}</strong>
                    </div>
                </section>
                {loading ? (
                    <section className={classes['Grid-loader']}>
                        <Loader center={true} fullContent={true} />
                    </section>
                ) : (
                    !isLastPage && (
                        <footer className={classes['Grid-footer']}>
                            <Button disabled={loading} outline={true} clicked={onShowMoreClicked}>
                                {'Mostra altri'}
                            </Button>
                        </footer>
                    )
                )}
            </section>
        );
    }
}

export default Grid;
