import * as actionTypes from './actionTypes';
import CittaService from '../../services/api/citta';
import { ICitta } from '../../shared/interfaces';

// FETCH CITTA BY KEYWORD
export const fetchCittaByKeywordStart = () => {
    return {
        type: actionTypes.FETCH_CITTA_BY_KEYWORD_START,
    };
};

export const fetchCittaByKeywordSuccess = (data: Array<ICitta>) => {
    return {
        type: actionTypes.FETCH_CITTA_BY_KEYWORD_SUCCESS,
        data,
    };
};

export const fetchCittaByKeywordFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CITTA_BY_KEYWORD_FAIL,
        error,
    };
};

export const fetchCittaByKeyword = (keyword: string) => {
    return (dispatch: any) => {
        dispatch(fetchCittaByKeywordStart());

        CittaService.get(keyword).then(
            (data: Array<ICitta>) => {
                dispatch(fetchCittaByKeywordSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchCittaByKeywordFail(response.data.error));
            }
        );
    };
};
