export default {
    LOCAL_STORAGE: {
        KEYS: {
            USER_ID: 'demetra_user_id',
            USER_NAME: 'demetra_user_name',
            USER_EMAIL: 'demetra_user_email',
            USER_ROLE: 'demetra_user_role',
            AUTH_TOKEN: 'demetra_auth_token',
            LAST_SESSION_DATE: 'demetra_last_session_date',
        },
    },
    EMPTY_UUID: '00000000-0000-0000-0000-000000000000',
    PROVINCE: {
        AG: {
            label: 'Agrigento',
            value: 'AG',
            checked: false,
        },
        CL: {
            label: 'Caltanissetta',
            value: 'CL',
            checked: false,
        },
        CT: {
            label: 'Catania',
            value: 'CT',
            checked: false,
        },
        EN: {
            label: 'Enna',
            value: 'EN',
            checked: false,
        },
        ME: {
            label: 'Messina',
            value: 'ME',
            checked: false,
        },
        PA: {
            label: 'Palermo',
            value: 'PA',
            checked: false,
        },
        RG: {
            label: 'Ragusa',
            value: 'RG',
            checked: false,
        },
        SR: {
            label: 'Siracusa',
            value: 'SR',
            checked: false,
        },
        TP: {
            label: 'Trapani',
            value: 'TP',
            checked: false,
        },
    },
    TIPI_DECRETO: {
        NonDefinito: {
            label: 'Non definito',
            value: 0,
            checked: false,
        },
        Iscrizione: {
            label: 'Iscrizone',
            value: 1,
            checked: false,
        },
        Modifica: {
            label: 'Modifica',
            value: 2,
            checked: false,
        },
        Cancellazione: {
            label: 'Cancellazione',
            value: 3,
            checked: false,
        },
        Sospensione: {
            label: 'Sospensione',
            value: 4,
            checked: false,
        },
        Riattivazione: {
            label: 'Riattivazione',
            value: 5,
            checked: false,
        },
    },
    TIPOLOGIE_OSA: {
        T00_NonDefinito: {
            label: 'Non definito',
            value: 0,
            checked: false,
        },
        T01_ProdIngrosso: {
            label: 'Prod. Ingresso',
            value: 1,
            checked: false,
        },
        T02_PrdoDettaglio: {
            label: 'Prod. Dettaglio',
            value: 2,
            checked: false,
        },
        T03_RistTradizionale: {
            label: 'Rist. Tradizionale',
            value: 3,
            checked: false,
        },
        T04_RistEtnica: {
            label: 'Rist. Etinica',
            value: 4,
            checked: false,
        },
        T05_Bar: {
            label: 'Bar',
            value: 5,
            checked: false,
        },
        T06_Agriturismo: {
            label: 'Agriturismo',
            value: 6,
            checked: false,
        },
        T07_SelfService: {
            label: 'Self Service',
            value: 7,
            checked: false,
        },
        T08_MensaOspedale: {
            label: 'Mensa ospedale',
            value: 8,
            checked: false,
        },
        T09_MensaScuola: {
            label: 'Mensa scuola',
            value: 9,
            checked: false,
        },
        T10_MensaCircondariale: {
            label: 'Mensa circondariale',
            value: 10,
            checked: false,
        },
        T11_MensaUffPubblico: {
            label: 'Mensa uff. Pubblico',
            value: 11,
            checked: false,
        },
        T12_CentroCottura: {
            label: 'Centro cottura',
            value: 12,
            checked: false,
        },
        T13_Catering: {
            label: 'Catering',
            value: 13,
            checked: false,
        },
        T14_CasaRiposo: {
            label: 'Casa riposo',
            value: 14,
            checked: false,
        },
        T15_DistrIngrosso: {
            label: 'Distr. Ingrosso',
            value: 15,
            checked: false,
        },
        T16_GDO: {
            label: 'Grande distribuzione organizzata',
            value: 16,
            checked: false,
        },
        T17_NegozioVicinato: {
            label: 'Negozio Vicinato',
            value: 17,
            checked: false,
        },
    },
    TIPI_CONTROLLO: {
        Audit: {
            label: 'Audit',
            value: 1,
            checked: false,
        },
        Campionamento: {
            label: 'Campionamento',
            value: 2,
            checked: false,
        },
        Ispezione: {
            label: 'Ispezione',
            value: 4,
            checked: false,
        },
        Monitoraggio: {
            label: 'Monitoraggio',
            value: 8,
            checked: false,
        },
        Sorveglianza: {
            label: 'Sorveglianza',
            value: 16,
            checked: false,
        },
        Verifica: {
            label: 'Verifica',
            value: 32,
            checked: false,
        },
    },
    MOTIVI_CONTROLLO: {
        NonSpecificato: {
            label: 'Non specificato',
            value: 1,
            checked: false,
        },
        Allerta: {
            label: 'Allerta',
            value: 2,
            checked: false,
        },
        Documentazione: {
            label: 'Documentazione',
            value: 4,
            checked: false,
        },
        Pianificazione: {
            label: 'Pianificazione',
            value: 8,
            checked: false,
        },
        Segnalazione: {
            label: 'Segnalazione',
            value: 16,
            checked: false,
        },
    },
    CLASSI_CAMPIONE: {
        Alimento: {
            label: 'Alimento',
            value: 0,
            checked: false,
        },
        AcquaPotabile: {
            label: 'Acqua potabile',
            value: 1,
            checked: false,
        },
        AcquaMarina: {
            label: 'Acqua marina',
            value: 2,
            checked: false,
        },
        Fitosanitario: {
            label: 'Fitosanitario',
            value: 3,
            checked: false,
        },
    },
    PROVENIENZE_CAMPIONE: {
        NonDefinito: {
            label: 'Non definito',
            value: 0,
            checked: false,
        },
        Regionale: {
            label: 'Regionale',
            value: 1,
            checked: false,
        },
        Nazionale: {
            label: 'Nazionale',
            value: 2,
            checked: false,
        },
        Comunitaria: {
            label: 'Comunitaria',
            value: 3,
            checked: false,
        },
        ExtraComunitaria: {
            label: 'Extracomunitaria',
            value: 4,
            checked: false,
        },
    },
    CIRCUITI_CAMPIONE: {
        NonDefinito: {
            label: 'Non definito',
            value: 0,
            checked: false,
        },
        Convenzionale: {
            label: 'Convenzionale',
            value: 1,
            checked: false,
        },
        Biologico: {
            label: 'Biologico',
            value: 2,
            checked: false,
        },
    },
    INFRAZIONI: {
        Nessuna: {
            label: 'Nessuna',
            value: 0,
            checked: false,
        },
        A_IgieneGenerale: {
            label: 'Igiene Generale',
            value: 1,
            checked: false,
        },
        B_IgieneHACCPPersonale: {
            label: 'Igiene HACCP Personale',
            value: 2,
            checked: false,
        },
        C_Composizione: {
            label: 'Composizione',
            value: 2,
            checked: false,
        },
        D_Contaminazione: {
            label: 'Contaminazione',
            value: 2,
            checked: false,
        },
        E_Etichettatura: {
            label: 'Etichettatura',
            value: 2,
            checked: false,
        },
        F_Altro: {
            label: 'Altro',
            value: 2,
            checked: false,
        },
    },
};
