import {
    ICampione,
    IControllo,
    IDittaSede,
    IFormatore,
    IFormatoreSede,
    ILabAnalisi,
} from '../interfaces';
import {
    CampioneCircuito,
    CampioneClasse,
    CampioneProvenienza,
    CampioneTipologiaOsa,
    ControlloMotivo,
    ControlloTipo,
    SedeClasse,
    SedeTipo,
} from '../enums';
import { getStringsValuesFromEnumValue } from './utilities';
import constants from '../constants';
import dayjs from 'dayjs';

export const createFile = (
    stream: Blob,
    name: string = 'file',
    ext: string = 'txt',
    hasDate: boolean = false
) => {
    if (!stream) return null;

    const downloadUrl = window.URL.createObjectURL(new Blob([stream]));

    const link = document.createElement('a');

    link.href = downloadUrl;

    link.setAttribute(
        'download',
        `${name}${hasDate ? `_${dayjs().format('DD-MM-YYYY_HH-mm')}` : ''}.${ext}`
    );

    document.body.appendChild(link);

    link.click();
    link.remove();
};

export const getAnagraficaRows = (items: Array<IDittaSede>) => {
    if (!items || !items.length) return [];

    return items.map((item: IDittaSede) => {
        return {
            id: item.IdDittaSede,
            ditta: `${item.IdentificativoFiscale}<br />${item.RagioneSociale || ''}`,
            sede: `<strong style="margin: 0 5px 0 0">${item.Citta}</strong><span>${item.Cap}</span><br />${item.Indirizzo}`,
            attivita: `${item.AtecoPrincipale}<br />${item.MasterListPrincipale}`,
            numeri: `${item.NumeroRegistrazione || ''}<br />${item.NumeroRiconoscimento || ''}`,
            tipo: `${SedeClasse[item.Classe]}<br />${SedeTipo[item.Tipo]}`,
        };
    });
};

export const getControlliRows = (items: Array<IControllo>) => {
    if (!items || !items.length) return [];

    return items.map((item: IControllo) => {
        return {
            id: item.IdControllo,
            data:
                item.Data && dayjs(item.Data).isValid()
                    ? dayjs(item.Data).format('DD/MM/YYYY HH:mm')
                    : '',
            ditta: `${item.IdentificativoFiscale}<br />${item.RagioneSociale || ''}`,
            sede: `<strong style="margin: 0 5px 0 0">${item.SedeCitta}</strong><br />${item.SedeIndirizzo}`,
            tipomotivo: `${getStringsValuesFromEnumValue(ControlloTipo, item.Tipo, ', ')}<br/><em>${
                ControlloMotivo[item.Motivo]
            }</em>`,
            azionicampioni: `${item.NumAzioni} / ${item.NumCampioni}`,
        };
    });
};

export const getCampioniRows = (items: Array<ICampione>) => {
    if (!items || !items.length) return [];

    return items.map((item: ICampione) => {
        return {
            id: item.IdCampione,
            data:
                item.Data && dayjs(item.Data).isValid()
                    ? dayjs(item.Data).format('DD/MM/YYYY HH:mm')
                    : '',
            ditta: `${item.IdentificativoFiscale}<br />${item.RagioneSociale || ''}`,
            sede: `<strong style="margin: 0 5px 0 0">${item.SedeCitta}</strong><br />${item.SedeIndirizzo}`,
            classeprovenienza: `${CampioneClasse[item.Classe]}<br/><em>${
                CampioneProvenienza[item.Provenienza]
            }</em>`,
            circuitotipologiaosa: `${CampioneCircuito[item.Circuito]}<br/><em>${
                constants.TIPOLOGIE_OSA[CampioneTipologiaOsa[item.TipologiaOsa]]
                    ? constants.TIPOLOGIE_OSA[CampioneTipologiaOsa[item.TipologiaOsa]].label
                    : ''
            }</em>`,
        };
    });
};

export const getFormatoriRows = (items: Array<IFormatore>) => {
    if (!items || !items.length) return [];

    return items.map((item: IFormatore) => {
        return {
            id: item.Id,
            numero: `<strong>${item.NumeroIscrizione}</strong><br /><em>N° decreti: ${
                item.Decreti.length
            }</em><br />${
                item.NumeroIscrizioneDataRilascio &&
                dayjs(item.NumeroIscrizioneDataRilascio).isValid()
                    ? dayjs(item.NumeroIscrizioneDataRilascio).format('DD/MM/YYYY')
                    : ''
            }`,
            denominazione: `${item.IdentificativoFiscale}<br />${item.RagioneSociale || ''}`,
            sede: `<strong style="margin: 0 5px 0 0">${item.Citta}</strong><br />${item.Indirizzo}`,
            fadAsync: item.FormazioneADistanzaAsync
                ? '<span style="color: green; font-weight: bold">SI</span>'
                : '<span style="color: red; opacity: 0.7">NO</span>',
            fadSync: item.FormazioneADistanzaSync
                ? '<span style="color: green; font-weight: bold">SI</span>'
                : '<span style="color: red; opacity: 0.7">NO</span>',
            res: item.FormazioneResidenziale
                ? '<span style="color: green; font-weight: bold">SI</span>'
                : '<span style="color: red; opacity: 0.7">NO</span>',
            sediOperative: `
                <ul style="margin: 0; padding: 0 0 0 20px">
                    ${item.Sedi.map((sede: IFormatoreSede) => {
                        return `<li>
                                <strong>${sede.Citta}</strong>
                                <br />
                                <span>${sede.Indirizzo}</span>
                            </li>`;
                    })}
                </ul>
            `,
            stato: item.Stato,
        };
    });
};

export const getLaboratoriRows = (items: Array<ILabAnalisi>) => {
    if (!items || !items.length) return [];

    return items.map((item: ILabAnalisi) => {
        return {
            idDitta: item.Ditta.Id,
            idSede: item.Laboratorio.Id,
            id: item.Laboratorio.IdLabAnalisiDitta,
            numero: `${
                item.Laboratorio.NumeroIscrizione
                    ? `<strong>N° Iscrizione: ${item.Laboratorio.NumeroIscrizione}</strong>`
                    : 'Nessun numero iscrizone'
            }<br />${
                item.Laboratorio.DataScadenzaPerAccreditamento
                    ? `<span>Scad.: ${dayjs(item.Laboratorio.DataScadenzaPerAccreditamento).format(
                          'DD/MM/YYYY'
                      )}</span><br />`
                    : ''
            }<em>N° decreti: ${
                item.Laboratorio.Decreti ? item.Laboratorio.Decreti.length : 0
            }</em>`,
            denominazione: `<strong>${item.Ditta.RagioneSociale || ''}</strong><br/>${
                item.Ditta.IdentificativoFiscale
            }<br />${item.Ditta.Pec}`,
            sede: `<strong style="margin: 0 5px 0 0">${item.Ditta.Citta}</strong><br />${item.Ditta.Indirizzo}<br />${item.Laboratorio.ResponsabileLaboratorio}`,
            accreditato: item.Laboratorio.IsAccreditato
                ? item.Laboratorio.AccreditamentoRiferimento
                    ? `<a href="${item.Laboratorio.AccreditamentoRiferimento}" target="_blank" style="color: green; display: block; font-weight: bold">SI</a>`
                    : `<span style="color: green; font-weight: bold; display: block">SI</span>`
                : `<div style="color: red; opacity: 0.7">NO</div><em>${item.Laboratorio.AccreditamentoNote}</em>`,
            stato: item.Laboratorio.Stato,
        };
    });
};
