import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
}

interface IState {}

class ErrorPage extends Component<IProps & RouteComponentProps, IState> {
    render() {
        return (
            <main className={classes.ErrorPage}>
                <Title size={'md'} color={'red'}>
                    {'Si è verifica un problema. Riprovare più tardi.'}
                </Title>
                <Title size={'sm'} color={'blue'}>
                    {'Se il problema dovesse persistere contattare assistenza.'}
                </Title>
            </main>
        );
    }
}

export default withRouter(ErrorPage);
