import React, { Component } from 'react';
import { it } from 'date-fns/locale';
import Label from '../Label';
// @ts-ignore
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import './styles.scss';
import { getDateFromString } from '../../../shared/helpers/utilities';

interface IProps {
    label?: string;
    onStartDateChanged(date: Date): void;
    onEndDateChanged(date: Date): void;
    startDate: string;
    endDate: string;
}

interface IState {
    startDate: Date | null;
    endDate: Date | null;
}

class DatesRange extends Component<IProps, IState> {
    state = {
        startDate: null,
        endDate: null,
    };

    componentDidMount() {
        this.setStartDate(getDateFromString(this.props.startDate));
        this.setEndDate(getDateFromString(this.props.endDate));
    }

    setStartDate = (value: Date | null) => {
        this.setState({
            startDate: value,
        });
    };

    setEndDate = (value: Date | null) => {
        this.setState({
            endDate: value,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.startDate !== prevState.startDate) {
            this.props.onStartDateChanged(this.state.startDate);
        }
        if (this.state.endDate !== prevState.endDate) {
            this.props.onEndDateChanged(this.state.endDate);
        }

        if (this.props.startDate !== prevProps.startDate) {
            this.setStartDate(getDateFromString(this.props.startDate));
        }

        if (this.props.endDate !== prevProps.endDate) {
            this.setEndDate(getDateFromString(this.props.endDate));
        }
    }

    render() {
        const { label } = this.props;
        const { startDate, endDate } = this.state;

        return (
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={this.setStartDate}
                onEndDateChange={this.setEndDate}
                minimumLength={1}
                format="dd/MM/yyyy"
                locale={it}
            >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                    <>
                        {label && <Label for={'dates'}>{label}</Label>}
                        <div className="date-range">
                            <input
                                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                {...startDateInputProps}
                                placeholder="Data inizio"
                            />
                            <span className="date-range_arrow" />
                            <input
                                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                {...endDateInputProps}
                                placeholder="Data fine"
                            />
                        </div>
                    </>
                )}
            </DateRangePicker>
        );
    }
}

export default DatesRange;
