import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: any;
    for: string;
    error?: any;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    noMargin?: boolean;
    inline?: boolean;
    color?: 'blue' | 'black';
}

const Label = (props: IProps) => {
    return (
        <label
            className={[
                classes.Label,
                props.inline ? classes['Label--inline'] : '',
                props.error ? classes['Label--error'] : '',
                props.size ? classes[`Label--${props.size}`] : '',
                props.noMargin ? classes['Label--noMargin'] : '',
                props.color ? classes[`Label--${props.color}`] : '',
            ].join(' ')}
            htmlFor={props.for}
        >
            {props.children}
        </label>
    );
};

export default Label;
