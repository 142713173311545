import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const exportData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (hasMasterList === true || hasMasterList === false) {
        qs += `&hasMasterList=${hasMasterList}`;
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (hasCategorizzazione === true || hasCategorizzazione === false) {
        qs += `&hasCategorizzazione=${hasCategorizzazione}`;
    }

    if (categorizzazioneMin !== null && +categorizzazioneMin >= 0) {
        qs += `&categorizzazioneMin=${categorizzazioneMin}`;
    }

    if (categorizzazioneMax !== null && +categorizzazioneMax >= 0) {
        qs += `&categorizzazioneMax=${categorizzazioneMax}`;
    }

    let endpoint = `/ditteSedi/export/?${qs}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
        responseType: 'blob',
    });
};

const counter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=true`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (hasMasterList === true || hasMasterList === false) {
        qs += `&hasMasterList=${hasMasterList}`;
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (hasCategorizzazione === true || hasCategorizzazione === false) {
        qs += `&hasCategorizzazione=${hasCategorizzazione}`;
    }

    if (categorizzazioneMin !== null && +categorizzazioneMin >= 0) {
        qs += `&categorizzazioneMin=${categorizzazioneMin}`;
    }

    if (categorizzazioneMax !== null && +categorizzazioneMax >= 0) {
        qs += `&categorizzazioneMax=${categorizzazioneMax}`;
    }

    let endpoint = `/ditteSedi?${qs}&pagina=0`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const get = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null,
    page: number = 1
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (hasMasterList === true || hasMasterList === false) {
        qs += `&hasMasterList=${hasMasterList}`;
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (hasCategorizzazione === true || hasCategorizzazione === false) {
        qs += `&hasCategorizzazione=${hasCategorizzazione}`;
    }

    if (categorizzazioneMin !== null && +categorizzazioneMin >= 0) {
        qs += `&categorizzazioneMin=${categorizzazioneMin}`;
    }

    if (categorizzazioneMax !== null && +categorizzazioneMax >= 0) {
        qs += `&categorizzazioneMax=${categorizzazioneMax}`;
    }

    let endpoint = `/ditteSedi?${qs}&pagina=${page}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const getById = (id: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/ditteSedi/byid/?id=${id}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const DittaSedeService = {
    exportData,
    counter,
    get,
    getById,
};

export default DittaSedeService;
