import request from '../../shared/lib/request';
import constants from '../../shared/constants';
import { IDecretoForm, ILaboratorioDittaForm, ILaboratorioSedeForm } from '../../shared/interfaces';

const exportData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/labAnalisi/export/?${qs}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
        responseType: 'blob',
    });
};

const counter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=true`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/labAnalisi?${qs}&pagina=0`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const get = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/labAnalisi?${qs}&pagina=${page}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const getByDittaIdAndSedeId = (idDitta: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idDitta}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const create = (data: ILaboratorioDittaForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const update = (idLaboratorio: string, data: ILaboratorioDittaForm, isCorrezione: boolean) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = '';

    if (isCorrezione) {
        qs = '?isCorrezione=true';
    }

    let endpoint = `/labAnalisi/${idLaboratorio}${qs}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const remove = (idDitta: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idDitta}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const assegnaNumero = (id: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${id}/assegnaNumero`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data: {},
    });
};

const getSedi = (idLaboratorio: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const createSede = (idLaboratorio: string, data: ILaboratorioSedeForm, assegnaNumero: boolean) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = '';
    if (assegnaNumero) {
        qs = '?assegnaNumero=true';
    }

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi${qs}`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const getSede = (idLaboratorio: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const removeSede = (idLaboratorio: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const updateSede = (idLaboratorio: string, idSede: string, data: ILaboratorioSedeForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const createDecreto = (idLaboratorio: string, idSede: string, data: IDecretoForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}/decreti`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const getDecreto = (idLaboratorio: string, idSede: string, idDecreto: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const removeDecreto = (idLaboratorio: string, idSede: string, idDecreto: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const updateDecreto = (
    idLaboratorio: string,
    idSede: string,
    idDecreto: string,
    data: IDecretoForm
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/labAnalisi/${idLaboratorio}/sedi/${idSede}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const LaboratorioService = {
    counter,
    exportData,
    get,
    create,
    update,
    remove,
    getByDittaIdAndSedeId,
    assegnaNumero,
    getSedi,
    createSede,
    updateSede,
    removeSede,
    getSede,
    createDecreto,
    updateDecreto,
    removeDecreto,
    getDecreto,
};

export default LaboratorioService;
