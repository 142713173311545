import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import './styles.scss';

interface IProps {
    visible: boolean;
    onCloseClicked(): void;
    children: any;
    title?: string;
}

const ModalWrapper = (props: IProps) => {
    const { visible, onCloseClicked, children, title } = props;

    return (
        <Modal open={visible} onClose={onCloseClicked} center>
            {title && <h1 className={`ModalTitle`}>{title}</h1>}
            <div className={'ModalContent'}>{children}</div>
        </Modal>
    );
};

export default ModalWrapper;
