import React, { Component, CSSProperties } from 'react';
import { ICitta, ISelectOption } from '../../shared/interfaces';
import * as actions from '../../store/actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import SelectField from '../UI/Select';
import { getSelectOptionsForCities } from '../../shared/helpers/selects';

const PLACEHOLDER = 'Ricerca città';

interface IProps {
    onGetList(keyword: string): Array<ICitta>;
    label: string;
    name?: string;
    waitingForList: boolean;
    items: Array<ICitta>;
    onSelectedChanged(selected: Array<ISelectOption> | ISelectOption): void;
    selected: Array<ISelectOption> | ISelectOption;
    single?: boolean;
    styles?: CSSProperties;
    labelBlack?: boolean;
}

class CitySelect extends Component<IProps & RouteComponentProps> {
    onKeywordChanged = (value: string) => {
        if (value && value.length > 2) {
            this.props.onGetList(value);
        }
    };

    render() {
        const {
            waitingForList,
            items,
            onSelectedChanged,
            label,
            name,
            selected,
            single,
            styles,
            labelBlack,
        } = this.props;

        return (
            <SelectField
                labelBlack={labelBlack || false}
                styles={styles}
                name={name}
                label={label}
                placeholder={PLACEHOLDER}
                onInputChanged={this.onKeywordChanged}
                selectedChanged={onSelectedChanged}
                isMulti={!single}
                options={getSelectOptionsForCities(items)}
                isLoading={waitingForList}
                selected={selected}
            />
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        items: state.cittaState.list,
        waitingForList: state.cittaState.isFetching,
        itemsError: state.cittaState.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetList: (keyword: string) => dispatch(actions.fetchCittaByKeyword(keyword)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CitySelect));
