import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import { IFormatore } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';
import FormatoreDetails from '../../components/FormatoreDetails';
import IconButton from '../../components/UI/IconButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import ConfirmDialog from '../../components/ConfirmDialog';
import ModalWrapper from '../../components/Modal';
import FormatoreForm from '../../components/FormatoreForm';
import Title from '../../components/UI/Title';
import Toast from '../../components/UI/Toast'; // Import css

interface IProps {
    history: History;
    match: {
        params: { id: string };
    };
    error: any;
    waitingForItem: boolean;
    onGetItem(id: string): IFormatore;
    onRemoveFormatore(id: string): void;
    item: IFormatore;
    lastRemovedItem: string;
    waitingForRemoving: boolean;
}

interface IState {
    showForm: boolean;
}

class Formatore extends Component<IProps & RouteComponentProps, IState> {
    state = {
        showForm: false,
    };

    componentDidMount() {
        const {
            match: {
                params: { id },
            },
        } = this.props;

        if (id) {
            this.props.onGetItem(id);
        } else {
            this.props.history.push(routes.FORMATORI);
        }
    }

    onToggleFormVisibility = () => {
        const showForm = !this.state.showForm;

        this.setState({
            showForm,
        });
    };

    onEditClicked = () => {
        this.onToggleFormVisibility();
    };

    onRemoveClicked = () => {
        const {
            item: { Id },
        } = this.props;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmDialog
                        onClose={onClose}
                        onConfirm={() => this.props.onRemoveItem(Id)}
                        title={'Sei sicuro di voler rimuovere questo soggetto formatore?'}
                        subtitle={"L'operazione NON potrà essere annullata."}
                    />
                );
            },
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastRemovedItem !== prevProps.lastRemovedItem &&
            this.props.lastRemovedItem
        ) {
            Toast({ message: 'Record rimosso con successo.', type: 'success' });
            this.props.history.push(routes.FORMATORI);
        }
    }

    render() {
        const { item, waitingForItem, waitingForRemoving } = this.props;
        const { showForm } = this.state;

        return (
            <AuthWrapper>
                <main className={classes.Formatore}>
                    {(waitingForItem || waitingForRemoving) && (
                        <Loader center={true} fullPage={true} />
                    )}
                    {!waitingForItem && <Title>{'Dettaglio soggetto formatore'}</Title>}
                    {!waitingForItem && (
                        <section className={classes['Formatore-actions']}>
                            <IconButton
                                clicked={this.onRemoveClicked}
                                theme={'danger'}
                                icon={'trash'}
                            />
                            <IconButton clicked={this.onEditClicked} icon={'edit'} />
                        </section>
                    )}
                    {item && <FormatoreDetails />}
                    <ModalWrapper
                        title={'Modifica Soggetto Formatore'}
                        visible={showForm}
                        onCloseClicked={this.onToggleFormVisibility}
                    >
                        <FormatoreForm
                            onCloseModal={this.onToggleFormVisibility}
                            currentData={item}
                        />
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        item: state.formatoreState.selected,
        waitingForItem: state.formatoreState.isFetchingItem,
        error: state.formatoreState.error,
        sedi: state.formatoreState.sedi,
        lastRemovedItem: state.formatoreState.lastRemovedFormatore,
        waitingForRemoving: state.formatoreState.isRemovingFormatore,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onResetData: () => dispatch(actions.resetFormatori()),
        onGetItem: (id: string) => dispatch(actions.fetchFormatore(id)),
        onRemoveItem: (id: string) => dispatch(actions.removeFormatore(id)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Formatore));
