import * as actionTypes from '../actions/actionTypes';
import { IStats } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    stats: IStats;
    error: string;
}

const INITIAL_STATE = {
    stats: null,
    isFetching: false,
    didInvalidate: false,
    error: null,
};

const fetchStatsStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetching: true,
    });
};

const fetchStatsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        stats: action.stats,
        isFetching: false,
    });
};

const fetchStatsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const resetStats = (state = INITIAL_STATE) => {
    return updateObject(state, {
        isFetching: false,
        didInvalidate: false,
        error: null,
    });
};

const statsReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_STATS_START:
            return fetchStatsStart(state, action);
        case actionTypes.FETCH_STATS_SUCCESS:
            return fetchStatsSuccess(state, action);
        case actionTypes.FETCH_STATS_FAIL:
            return fetchStatsFail(state, action);

        case actionTypes.RESET_STATS:
            return resetStats(state);

        default:
            return state;
    }
};

export default statsReducer;
