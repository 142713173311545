import React, { Component } from 'react';
import classes from './styles.module.scss';
import Button from '../UI/Button';

interface IProps {
    onClose(): void;
    onConfirm(): void;
    title: string;
    subtitle: string;
}

class ConfirmDialog extends Component<IProps> {
    render() {
        const { onConfirm, onClose, title, subtitle } = this.props;

        return (
            <div className={classes.ConfirmDialog}>
                <h1>{title}</h1>
                <p>{subtitle}</p>
                <footer>
                    <Button clicked={onClose}>Annulla</Button>
                    <Button
                        theme={'success'}
                        clicked={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        Si, confermo
                    </Button>
                </footer>
            </div>
        );
    }
}

export default ConfirmDialog;
