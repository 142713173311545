import { combineReducers } from 'redux';
import sessionReducer from './session';
import masterListReducer from './masterList';
import atecoReducer from './ateco';
import dittaSedeReducer from './dittaSede';
import cittaReducer from './citta';
import controlloReducer from './controllo';
import campioneReducer from './campione';
import statsReducer from './stats';
import formatoreReducer from './formatore';
import laboratorioReducer from './laboratorio';

export const rootReducer = combineReducers({
    sessionState: sessionReducer,
    masterListState: masterListReducer,
    atecoState: atecoReducer,
    dittaSedeState: dittaSedeReducer,
    cittaState: cittaReducer,
    controlloState: controlloReducer,
    campioneState: campioneReducer,
    formatoreState: formatoreReducer,
    statsState: statsReducer,
    laboratorioState: laboratorioReducer,
});
