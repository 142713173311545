import * as actionTypes from './actionTypes';
import {
    IError,
    IDecreto,
    IDecretoForm,
    ILaboratorioSede,
    ILaboratorioSedeForm,
    IResponse,
    IValidationError,
    ILabAnalisi,
    ILaboratorioDitta,
    ILaboratorioDittaForm,
} from '../../shared/interfaces';
import LaboratorioService from '../../services/api/laboratorio';

// FETCH
export const fetchLaboratoriStart = (page: number) => {
    return {
        type: actionTypes.FETCH_LABORATORI_START,
        page,
    };
};

export const fetchLaboratoriSuccess = (
    list: Array<ILabAnalisi>,
    count: number,
    totalPages: number
) => {
    return {
        type: actionTypes.FETCH_LABORATORI_SUCCESS,
        list,
        count,
        totalPages,
    };
};

export const fetchLaboratoriFail = (error: string) => {
    return {
        type: actionTypes.FETCH_LABORATORI_FAIL,
        error,
    };
};

export const fetchLaboratori = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    return (dispatch: any) => {
        dispatch(fetchLaboratoriStart(page));

        LaboratorioService.get(keyword, province, cities, dataInizio, dataFine, page).then(
            (response: IResponse) => {
                dispatch(
                    fetchLaboratoriSuccess(
                        response.Data,
                        response.DataCount || 0,
                        response.TotalPages || 1
                    )
                );
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchLaboratoriFail(response.data.error));
            }
        );
    };
};

// CREATE
export const createLaboratorioStart = () => {
    return {
        type: actionTypes.CREATE_LABORATORIO_START,
    };
};

export const createLaboratorioSuccess = (item: ILaboratorioDitta) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_SUCCESS,
        item,
    };
};

export const createLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_FAIL,
        error,
    };
};

export const createLaboratorio = (data: ILaboratorioDittaForm) => {
    return (dispatch: any) => {
        dispatch(createLaboratorioStart());

        LaboratorioService.create(data).then(
            (response: IResponse) => {
                dispatch(createLaboratorioSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createLaboratorioFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(
                        createLaboratorioFail('Si è verificato un problema durante il salvataggio')
                    );
                }
            }
        );
    };
};

// UPDATE
export const updateLaboratorioStart = () => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_START,
    };
};

export const updateLaboratorioSuccess = (item: ILaboratorioDittaForm) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_SUCCESS,
        item,
    };
};

export const updateLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_FAIL,
        error,
    };
};

export const updateLaboratorio = (
    id: string,
    data: ILaboratorioDittaForm,
    isCorrezione: boolean = true
) => {
    return (dispatch: any) => {
        dispatch(updateLaboratorioStart());

        LaboratorioService.update(id, data, isCorrezione).then(
            (response: IResponse) => {
                dispatch(updateLaboratorioSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateLaboratorioFail(response.data.error));
            }
        );
    };
};

export const removeLaboratorioStart = () => {
    return {
        type: actionTypes.REMOVE_LABORATORIO_START,
    };
};

export const removeLaboratorioSuccess = (id: string) => {
    return {
        type: actionTypes.REMOVE_LABORATORIO_SUCCESS,
        lastRemovedId: id,
    };
};

export const removeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.REMOVE_LABORATORIO_FAIL,
        error,
    };
};

export const removeLaboratorio = (idLabAnalisiDitta: string, idDitta: string, idSede: string) => {
    return (dispatch: any) => {
        dispatch(removeLaboratorioStart());

        LaboratorioService.remove(idDitta, idSede).then(
            () => {
                dispatch(removeLaboratorioSuccess(idLabAnalisiDitta));
            },
            (response: { data: { error: string } }) => {
                dispatch(removeLaboratorioFail(response.data.error));
            }
        );
    };
};

// DETAIL
export const fetchLaboratorioStart = () => {
    return {
        type: actionTypes.FETCH_LABORATORIO_START,
    };
};

export const fetchLaboratorioSuccess = (item: ILaboratorioSede) => {
    return {
        type: actionTypes.FETCH_LABORATORIO_SUCCESS,
        item,
    };
};

export const fetchLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.FETCH_LABORATORIO_FAIL,
        error,
    };
};

export const fetchLaboratorio = (idDitta: string, idSede) => {
    return (dispatch: any) => {
        dispatch(fetchLaboratorioStart());

        LaboratorioService.getByDittaIdAndSedeId(idDitta, idSede).then(
            (data: ILaboratorioSede) => {
                dispatch(fetchLaboratorioSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchLaboratorioFail(response.data.error));
            }
        );
    };
};

// CREATE SEDE
export const createSedeLaboratorioStart = () => {
    return {
        type: actionTypes.CREATE_LABORATORIO_SEDE_START,
    };
};

export const createSedeLaboratorioSuccess = (office: ILaboratorioSede) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_SEDE_SUCCESS,
        office,
    };
};

export const createSedeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_SEDE_FAIL,
        error,
    };
};

export const createSedeLaboratorio = (
    idLaboratorio: string,
    data: ILaboratorioSedeForm,
    assegnaNumero: boolean
) => {
    return (dispatch: any) => {
        dispatch(createSedeLaboratorioStart());

        LaboratorioService.createSede(idLaboratorio, data, assegnaNumero).then(
            (response: IResponse) => {
                dispatch(createSedeLaboratorioSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createSedeLaboratorioFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(
                        createSedeLaboratorioFail(
                            'Si è verificato un problema durante il salvataggio'
                        )
                    );
                }
            }
        );
    };
};

// UPDATE SEDE
export const updateSedeLaboratorioStart = () => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_SEDE_START,
    };
};

export const updateSedeLaboratorioSuccess = (office: ILaboratorioSede) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_SEDE_SUCCESS,
        office,
    };
};

export const updateSedeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_SEDE_FAIL,
        error,
    };
};

export const updateSedeLaboratorio = (
    idLaboratorio: string,
    idSede: string,
    data: ILaboratorioSedeForm
) => {
    return (dispatch: any) => {
        dispatch(updateSedeLaboratorioStart());

        LaboratorioService.updateSede(idLaboratorio, idSede, data).then(
            (response: IResponse) => {
                dispatch(updateSedeLaboratorioSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateSedeLaboratorioFail(response.data.error));
            }
        );
    };
};

// CREATE DECRETO
export const createDecretoSedeLaboratorioStart = () => {
    return {
        type: actionTypes.CREATE_LABORATORIO_DECRETO_START,
    };
};

export const createDecretoSedeLaboratorioSuccess = (decreto: IDecreto) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_DECRETO_SUCCESS,
        decreto,
    };
};

export const createDecretoSedeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.CREATE_LABORATORIO_DECRETO_FAIL,
        error,
    };
};

export const createDecretoSedeLaboratorio = (
    idLaboratorio: string,
    idSede: string,
    data: IDecretoForm
) => {
    return (dispatch: any) => {
        dispatch(createDecretoSedeLaboratorioStart());

        LaboratorioService.createDecreto(idLaboratorio, idSede, data).then(
            (response: IResponse) => {
                dispatch(createDecretoSedeLaboratorioSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createDecretoSedeLaboratorioFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(
                        createSedeLaboratorioFail(
                            'Si è verificato un problema durante il salvataggio'
                        )
                    );
                }
            }
        );
    };
};

// UPDATE SEDE
export const updateDecretoSedeLaboratorioStart = () => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_DECRETO_START,
    };
};

export const updateDecretoSedeLaboratorioSuccess = (decreto: IDecreto) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_DECRETO_SUCCESS,
        decreto,
    };
};

export const updateDecretoSedeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_LABORATORIO_DECRETO_FAIL,
        error,
    };
};

export const updateDecretoSedeLaboratorio = (
    idLaboratorio: string,
    idSede: string,
    idDecreto: string,
    data: IDecretoForm
) => {
    return (dispatch: any) => {
        dispatch(updateDecretoSedeLaboratorioStart());

        LaboratorioService.updateDecreto(idLaboratorio, idSede, idDecreto, data).then(
            (response: IResponse) => {
                dispatch(updateDecretoSedeLaboratorioSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateDecretoSedeLaboratorioFail(response.data.error));
            }
        );
    };
};

// REMOVE SEDE
export const removeDecretoSedeLaboratorioStart = () => {
    return {
        type: actionTypes.DELETE_LABORATORIO_DECRETO_START,
    };
};

export const removeDecretoSedeLaboratorioSuccess = (lastRemovedDecretoId: string) => {
    return {
        type: actionTypes.DELETE_LABORATORIO_DECRETO_SUCCESS,
        lastRemovedDecretoId,
    };
};

export const removeDecretoSedeLaboratorioFail = (error: string) => {
    return {
        type: actionTypes.DELETE_LABORATORIO_DECRETO_FAIL,
        error,
    };
};

export const removeDecretoSedeLaboratorio = (
    idLaboratorio: string,
    idSede: string,
    idDecreto: string
) => {
    return (dispatch: any) => {
        dispatch(removeDecretoSedeLaboratorioStart());

        LaboratorioService.removeDecreto(idLaboratorio, idSede, idDecreto).then(
            () => {
                dispatch(removeDecretoSedeLaboratorioSuccess(idDecreto));
            },
            (response: { data: { error: string } }) => {
                dispatch(removeDecretoSedeLaboratorioFail(response.data.error));
            }
        );
    };
};

// COUNTER
export const fetchLaboratoriCounterStart = () => {
    return {
        type: actionTypes.FETCH_LABORATORI_COUNTER_START,
    };
};

export const fetchLaboratoriCounterSuccess = (count: number) => {
    return {
        type: actionTypes.FETCH_LABORATORI_COUNTER_SUCCESS,
        count,
    };
};

export const fetchLaboratoriCounterFail = (error: string) => {
    return {
        type: actionTypes.FETCH_LABORATORI_COUNTER_FAIL,
        error,
    };
};

export const fetchLaboratoriCounter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(fetchLaboratoriCounterStart());

        LaboratorioService.counter(keyword, province, cities, dataInizio, dataFine).then(
            (response: IResponse) => {
                dispatch(fetchLaboratoriCounterSuccess(response.DataCount || 0));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchLaboratoriCounterFail(response.data.error));
            }
        );
    };
};

// EXPORT
export const exportLaboratoriDataStart = () => {
    return {
        type: actionTypes.FETCH_LABORATORI_EXPORT_START,
    };
};

export const exportLaboratoriDataSuccess = (stream: Blob) => {
    return {
        type: actionTypes.FETCH_LABORATORI_EXPORT_SUCCESS,
        stream,
    };
};

export const exportLaboratoriDataFail = (error: string) => {
    return {
        type: actionTypes.FETCH_LABORATORI_EXPORT_FAIL,
        error,
    };
};

export const exportLaboratoriData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(exportLaboratoriDataStart());

        LaboratorioService.exportData(keyword, province, cities, dataInizio, dataFine).then(
            (stream: Blob) => {
                dispatch(exportLaboratoriDataSuccess(stream));
            },
            (response: { data: { error: string } }) => {
                dispatch(exportLaboratoriDataFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetLaboratori = () => {
    return {
        type: actionTypes.RESET_LABORATORI,
    };
};
