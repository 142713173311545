export default {
    FORMATORI_COLUMNS: [
        {
            key: 'progressivo',
            label: 'Progressivo',
            styles: {
                width: '15%',
            },
        },
        {
            key: 'indirizzo',
            label: 'Indirizzo',
            styles: {
                width: '50%',
            },
        },
        {
            key: 'altridati',
            label: 'Altri dati',
            styles: {
                width: '20%',
            },
        },
        {
            key: 'actions',
            label: '',
            styles: {
                width: '15%',
            },
        },
    ],
    LABORATORI_COLUMNS: [
        {
            key: 'progressivo',
            label: 'Progressivo',
            styles: {
                width: '15%',
            },
        },
        {
            key: 'indirizzo',
            label: 'Indirizzo',
            styles: {
                width: '50%',
            },
        },
        {
            key: 'altridati',
            label: 'Altri dati',
            styles: {
                width: '20%',
            },
        },
        {
            key: 'actions',
            label: '',
            styles: {
                width: '15%',
            },
        },
    ],
    DECRETI_COLUMNS: [
        {
            key: 'data',
            label: 'Data',
            styles: {
                width: '15%',
            },
        },
        {
            key: 'tipo',
            label: 'Tipo',
            styles: {
                width: '30%',
            },
        },
        {
            key: 'documento',
            label: 'Documento',
            styles: {
                width: '60%',
            },
        },
        {
            key: 'actions',
            label: '',
            styles: {
                width: '15%',
            },
        },
    ],
};
