import * as routes from '../routes';

export default {
    AUTH_NAV: [
        {
            label: 'Anagrafica',
            route: routes.ANAGRAFICA,
        },
        {
            label: 'Controlli',
            route: routes.CONTROLLI,
        },
        {
            label: 'Campioni',
            route: routes.CAMPIONI,
        },
        {
            label: 'Formatori',
            route: routes.FORMATORI,
        },
        {
            label: 'Laboratori',
            route: routes.LABORATORI,
        },
        {
            label: 'Statistiche',
            route: routes.STATISTICHE,
        },
    ],
    DEFAULT_NAV: [
        {
            label: 'Accedi',
            route: routes.SIGNIN,
        },
    ],
};
