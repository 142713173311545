import * as actionTypes from './actionTypes';
import MasterListService from '../../services/api/masterList';
import { IMasterList } from '../../shared/interfaces';

// FETCH MASTER LIST BY KEYWORD
export const fetchMasterListByKeywordStart = () => {
    return {
        type: actionTypes.FETCH_MASTER_LIST_BY_KEYWORD_START,
    };
};

export const fetchMasterListByKeywordSuccess = (data: Array<IMasterList>) => {
    return {
        type: actionTypes.FETCH_MASTER_LIST_BY_KEYWORD_SUCCESS,
        data,
    };
};

export const fetchMasterListByKeywordFail = (error: string) => {
    return {
        type: actionTypes.FETCH_MASTER_LIST_BY_KEYWORD_FAIL,
        error,
    };
};

export const fetchMasterListByKeyword = (keyword: string) => {
    return (dispatch: any) => {
        dispatch(fetchMasterListByKeywordStart());

        MasterListService.get(keyword).then(
            (data: Array<IMasterList>) => {
                dispatch(fetchMasterListByKeywordSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchMasterListByKeywordFail(response.data.error));
            }
        );
    };
};
