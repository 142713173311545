import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const get = (months: number = 12) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `ultimiMesi=${months}`;

    let endpoint = `/stats/numeroAttivita?${qs}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const StatsService = {
    get,
};

export default StatsService;
