import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import {
    ICampione,
    ICampioneSearchParams,
    IProvinceList,
    ITipologieOSA,
    ISelectOption,
    IClassiCampioneList,
    IProvenienzeCampioneList,
    ICircuitiCampioneList,
    IDittaSede,
    IControllo,
} from '../../shared/interfaces';
import Field from '../../components/Field';
import Loader from '../../components/UI/Loader';
import CitySelect from '../../components/CitySelect';
import AtecoSelect from '../../components/AtecoSelect';
import MasterListSelect from '../../components/MasterListSelect';
import CheckBox from '../../components/UI/Checkbox';
import constants from '../../shared/constants';
import tables from '../../shared/data/tables';
import Filters from '../../components/Filters';
import dayjs from 'dayjs';
import DatesRange from '../../components/UI/DatesRange';
import Grid from '../../components/Grid';
import { createFile, getCampioniRows } from '../../shared/helpers/dataRows';
import CounterWrapper from '../../components/CounterWrapper';
import Tabs from '../../components/Tabs';
import FlexWrapper from '../../components/UI/FlexWrapper';
import { getNumberArrayFromCheckboxItem } from '../../shared/helpers/utilities';
import ModalWrapper from '../../components/Modal';
import CampioneDetails from '../../components/CampioneDetails';
import SedeDetails from '../../components/SedeDetails';
import ControlloDetails from '../../components/ControlloDetails';
import Button from '../../components/UI/Button';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
    list: Array<ICampione>;
    waitingForList: boolean;
    error: any;
    onGetData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        classe: Array<number>,
        provenienza: Array<number>,
        circuito: Array<number>,
        tipologiaOsa: Array<number>,
        dataInizio: string,
        dataFine: string,
        page: number
    ): Array<ICampione>;
    onResetData(): void;
    waitingForItem: boolean;
    onGetItem(id: string): ICampione;
    totalPages: number;
    totalRecords: number;
    item: ICampione;
    onGetCounter(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        classe: Array<number>,
        provenienza: Array<number>,
        circuito: Array<number>,
        tipologiaOsa: Array<number>,
        dataInizio: string,
        dataFine: string
    ): number;
    showCounter: boolean;
    onExportData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        masterList: Array<string>,
        ateco: Array<string>,
        classe: Array<number>,
        provenienza: Array<number>,
        circuito: Array<number>,
        tipologiaOsa: Array<number>,
        dataInizio: string,
        dataFine: string
    ): number;
    waitingForFileCreation: boolean;
    stream: ArrayBuffer | null;
    onGetSede(id: string): IDittaSede;
    sede: IDittaSede;
    waitingForSede: boolean;
    onGetControllo(id: string): IControllo;
    controllo: IControllo;
    waitingForControllo: boolean;
}

interface IState {
    searchParams: ICampioneSearchParams;
    selected: {
        cities: Array<ISelectOption>;
        ateco: Array<ISelectOption>;
        masterList: Array<ISelectOption>;
    };
    province: IProvinceList;
    classiCampione: IClassiCampioneList;
    provenienzeCampione: IProvenienzeCampioneList;
    circuitiCampione: ICircuitiCampioneList;
    tipologieOSA: ITipologieOSA;
    showDetailsModal: boolean;
    currentSede: IDittaSede | null;
    currentControllo: IControllo | null;
    modalTitle: string;
}

class Campioni extends Component<IProps & RouteComponentProps, IState> {
    state = {
        searchParams: {
            classe: [],
            provenienza: [],
            circuito: [],
            tipologiaOsa: [],
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        },
        selected: {
            cities: [],
            ateco: [],
            masterList: [],
        },
        province: constants.PROVINCE,
        tipologieOSA: constants.TIPOLOGIE_OSA,
        classiCampione: constants.CLASSI_CAMPIONE,
        provenienzeCampione: constants.PROVENIENZE_CAMPIONE,
        circuitiCampione: constants.CIRCUITI_CAMPIONE,
        showDetailsModal: false,
        currentControllo: null,
        currentSede: null,
        modalTitle: 'Dettaglio del Campione',
    };

    handleSelectedOptions = (name: string, selected: Array<ISelectOption> | ISelectOption) => {
        let newSelected = {
            ...this.state.selected,
            [name]: selected || [],
        };

        this.setState({
            selected: newSelected,
        });
    };

    handleSearchChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const keyword = e.currentTarget.value;

        let searchParams = {
            ...this.state.searchParams,
            keyword,
        };

        this.setState({
            searchParams,
        });
    };

    onSelectProv = (prov: string, checked: boolean) => {
        let province = {
            ...this.state.province,
            [prov]: {
                ...this.state.province[prov],
                checked,
            },
        };

        this.setState({
            province,
        });
    };

    onSelectTipologiaOSA = (value: string, checked: boolean) => {
        let tipologieOSA = {
            ...this.state.tipologieOSA,
            [value]: {
                ...this.state.tipologieOSA[value],
                checked,
            },
        };

        this.setState({
            tipologieOSA,
        });
    };

    onSelectClasseCampione = (value: string, checked: boolean) => {
        let classiCampione = {
            ...this.state.classiCampione,
            [value]: {
                ...this.state.classiCampione[value],
                checked,
            },
        };

        this.setState({
            classiCampione,
        });
    };

    onSelectProvenienzaCampione = (value: string, checked: boolean) => {
        let provenienzeCampione = {
            ...this.state.provenienzeCampione,
            [value]: {
                ...this.state.provenienzeCampione[value],
                checked,
            },
        };

        this.setState({
            provenienzeCampione,
        });
    };

    onSelectCircuitoCampione = (value: string, checked: boolean) => {
        let circuitiCampione = {
            ...this.state.circuitiCampione,
            [value]: {
                ...this.state.circuitiCampione[value],
                checked,
            },
        };

        this.setState({
            circuitiCampione,
        });
    };

    onDatesRangeChanged = (type: 'dataInizio' | 'dataFine', value: Date) => {
        let searchParams = {
            ...this.state.searchParams,
            [type]: dayjs(value).format('YYYY-MM-DD'),
        };

        this.setState({
            searchParams,
        });
    };

    onToggleModalVisibility = () => {
        const showDetailsModal = !this.state.showDetailsModal;

        this.setState({ showDetailsModal });

        if (!showDetailsModal) {
            this.resetModal();
        }
    };

    resetModal = () => {
        this.setState({
            modalTitle: 'Dettaglio del Campione',
            currentControllo: null,
            currentSede: null,
        });
    };

    onRowSelected = (data: Array<any>) => {
        //console.log('Row selected', data[0]);
        this.onToggleModalVisibility();
        if (data && data.length > 0) {
            this.props.onGetItem(data[0].id);
        }
    };

    onGetSede = (id: string) => {
        this.setState(
            {
                modalTitle: 'Tipologia OSA',
            },
            () => {
                this.props.onGetSede(id);
            }
        );
    };

    onGetControllo = (id: string) => {
        this.setState(
            {
                modalTitle: 'Dettaglio del Controllo',
            },
            () => {
                this.props.onGetControllo(id);
            }
        );
    };

    onSubmitClicked = () => {
        this.props.onResetData();

        setTimeout(() => {
            this.onSearchData();
        }, 300);
    };

    loadMore = () => {
        let searchParams = {
            ...this.state.searchParams,
            page: this.state.searchParams.page + 1,
        };
        this.setState({ searchParams }, () => {
            this.onSearchData();
        });
    };

    onGetCounterClicked = () => {
        this.props.onResetData();

        const {
            searchParams: { keyword, dataFine, dataInizio },
            selected: { masterList, ateco, cities },
            province,
            tipologieOSA,
            circuitiCampione,
            provenienzeCampione,
            classiCampione,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const classiFilter = getNumberArrayFromCheckboxItem(classiCampione);
        const provenienzeFilter = getNumberArrayFromCheckboxItem(provenienzeCampione);
        const circuitiFilter = getNumberArrayFromCheckboxItem(circuitiCampione);
        const tipologiaOSAFilter = getNumberArrayFromCheckboxItem(tipologieOSA);

        setTimeout(() => {
            this.props.onGetCounter(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                classiFilter,
                provenienzeFilter,
                circuitiFilter,
                tipologiaOSAFilter.filter((item) => item >= 0),
                dataInizio,
                dataFine
            );
        }, 300);
    };

    onExportClicked = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio },
            selected: { masterList, ateco, cities },
            province,
            tipologieOSA,
            circuitiCampione,
            provenienzeCampione,
            classiCampione,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const classiFilter = getNumberArrayFromCheckboxItem(classiCampione);
        const provenienzeFilter = getNumberArrayFromCheckboxItem(provenienzeCampione);
        const circuitiFilter = getNumberArrayFromCheckboxItem(circuitiCampione);
        const tipologiaOSAFilter = getNumberArrayFromCheckboxItem(tipologieOSA);

        setTimeout(() => {
            this.props.onExportData(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                classiFilter,
                provenienzeFilter,
                circuitiFilter,
                tipologiaOSAFilter.filter((item) => item >= 0),
                dataInizio,
                dataFine
            );
        }, 300);
    };

    onSearchData = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { masterList, ateco, cities },
            province,
            tipologieOSA,
            circuitiCampione,
            provenienzeCampione,
            classiCampione,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const classiFilter = getNumberArrayFromCheckboxItem(classiCampione);
        const provenienzeFilter = getNumberArrayFromCheckboxItem(provenienzeCampione);
        const circuitiFilter = getNumberArrayFromCheckboxItem(circuitiCampione);
        const tipologiaOSAFilter = getNumberArrayFromCheckboxItem(tipologieOSA);

        this.props.onGetData(
            keyword,
            provFilter,
            cities.map((item) => item.value),
            masterList.map((item) => item.value),
            ateco.map((item) => item.value),
            classiFilter,
            provenienzeFilter,
            circuitiFilter,
            tipologiaOSAFilter.filter((item) => item >= 0),
            dataInizio,
            dataFine,
            page
        );
    };

    resetFilters = () => {
        const searchParams = {
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        };
        const selected = {
            cities: [],
            ateco: [],
            masterList: [],
        };

        const province = constants.PROVINCE;
        const tipologieOSA = constants.TIPOLOGIE_OSA;
        const classiCampione = constants.CLASSI_CAMPIONE;
        const circuitiCampione = constants.CIRCUITI_CAMPIONE;
        const provenienzeCampione = constants.PROVENIENZE_CAMPIONE;

        this.setState(
            {
                searchParams,
                selected,
                province,
                tipologieOSA,
                classiCampione,
                circuitiCampione,
                provenienzeCampione,
            },
            () => {
                this.props.onResetData();
            }
        );
    };

    resetPaging = () => {
        const searchParams = {
            ...this.state.searchParams,
            page: 1,
        };

        this.setState({ searchParams }, () => {
            this.props.onResetData();
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.stream !== prevProps.stream && this.props.stream) {
            createFile(this.props.stream, 'Elenco_campioni', 'xlsx', true);
        }

        if (this.props.sede !== prevProps.sede) {
            this.setState({
                currentSede: this.props.sede,
                currentControllo: this.props.sede ? null : this.state.currentControllo,
            });
        }

        if (this.props.controllo !== prevProps.controllo) {
            this.setState({
                currentControllo: this.props.controllo,
                currentSede: this.props.controllo ? null : this.state.currentSede,
            });
        }
    }

    render() {
        const {
            searchParams: { keyword, dataFine, dataInizio },
            province,
            tipologieOSA,
            selected,
            classiCampione,
            circuitiCampione,
            provenienzeCampione,
            showDetailsModal,
            currentControllo,
            currentSede,
            modalTitle,
        } = this.state;

        const {
            waitingForList,
            list,
            totalRecords,
            showCounter,
            waitingForItem,
            isLastPage,
            item,
            waitingForFileCreation,
            waitingForControllo,
            waitingForSede,
        } = this.props;

        const provinceTpl = Object.keys(province).map((prov: string) => {
            return (
                <CheckBox
                    key={prov}
                    checked={province[prov].checked}
                    changed={(checked: boolean) => this.onSelectProv(prov, checked)}
                >
                    {province[prov].label}
                </CheckBox>
            );
        });

        const tipologieOSATpl = Object.keys(tipologieOSA).map((key: string) => {
            return (
                <CheckBox
                    key={key}
                    checked={tipologieOSA[key].checked}
                    changed={(checked: boolean) => this.onSelectTipologiaOSA(key, checked)}
                >
                    {tipologieOSA[key].label}
                </CheckBox>
            );
        });

        const classiCampioneTpl = Object.keys(classiCampione).map((key: string) => {
            return (
                <CheckBox
                    key={key}
                    checked={classiCampione[key].checked}
                    changed={(checked: boolean) => this.onSelectClasseCampione(key, checked)}
                >
                    {classiCampione[key].label}
                </CheckBox>
            );
        });

        const provenienzeCampioneTpl = Object.keys(provenienzeCampione).map((key: string) => {
            return (
                <CheckBox
                    key={key}
                    checked={provenienzeCampione[key].checked}
                    changed={(checked: boolean) => this.onSelectProvenienzaCampione(key, checked)}
                >
                    {provenienzeCampione[key].label}
                </CheckBox>
            );
        });

        const circuitiCampioneTpl = Object.keys(circuitiCampione).map((key: string) => {
            return (
                <CheckBox
                    key={key}
                    checked={circuitiCampione[key].checked}
                    changed={(checked: boolean) => this.onSelectCircuitoCampione(key, checked)}
                >
                    {circuitiCampione[key].label}
                </CheckBox>
            );
        });

        return (
            <AuthWrapper>
                <main className={classes.Campioni}>
                    <Title>{'Campioni'}</Title>
                    <Filters
                        onSubmitClicked={this.onSubmitClicked}
                        onResetClicked={this.resetFilters}
                        onGetCounter={this.onGetCounterClicked}
                        onExportClicked={this.onExportClicked}
                        undoLabel={'Reset filtri'}
                        submitLabel={'Ricerca'}
                    >
                        <Tabs
                            tabs={{
                                titles: [
                                    'Generico',
                                    'Tipo campione',
                                    'Tipologia OSA',
                                    'Posizione',
                                    'Attività',
                                ],
                                items: [
                                    <FlexWrapper noMargin={true} withSections={true}>
                                        <section>
                                            <Field
                                                label={
                                                    'Ricerca per Ragione Sociale, ID Fiscale o PEC'
                                                }
                                                noMargin={true}
                                                name={'search'}
                                                type={'text'}
                                                changed={this.handleSearchChanged}
                                                value={keyword}
                                                placeholder={'Ricerca...'}
                                            />
                                        </section>
                                        <section>
                                            <DatesRange
                                                label={'Range date'}
                                                onStartDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataInizio', value)
                                                }
                                                onEndDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataFine', value)
                                                }
                                                startDate={dataInizio}
                                                endDate={dataFine}
                                            />
                                        </section>
                                    </FlexWrapper>,
                                    <div>
                                        <FlexWrapper
                                            withMarginBottom={true}
                                            label={'Classe'}
                                            name={'classi'}
                                        >
                                            {classiCampioneTpl}
                                        </FlexWrapper>
                                        <FlexWrapper
                                            withMarginBottom={true}
                                            label={'Provenienza'}
                                            name={'provenienze'}
                                        >
                                            {provenienzeCampioneTpl}
                                        </FlexWrapper>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Circuito'}
                                            name={'circuiti'}
                                        >
                                            {circuitiCampioneTpl}
                                        </FlexWrapper>
                                    </div>,
                                    <FlexWrapper>{tipologieOSATpl}</FlexWrapper>,
                                    <>
                                        <div>
                                            <CitySelect
                                                label={'Ricerca per città'}
                                                name={'cities'}
                                                selected={selected.cities}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('cities', values)
                                                }
                                            />
                                        </div>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Province'}
                                            name={'province'}
                                        >
                                            {provinceTpl}
                                        </FlexWrapper>
                                    </>,
                                    <>
                                        <div>
                                            <AtecoSelect
                                                label={'Ricerca per Ateco'}
                                                name={'ateco'}
                                                selected={selected.ateco}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('ateco', values)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <MasterListSelect
                                                label={'Ricerca per Master List'}
                                                name={'masterList'}
                                                selected={selected.masterList}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('masterList', values)
                                                }
                                            />
                                        </div>
                                    </>,
                                ],
                            }}
                        />
                    </Filters>
                    {waitingForFileCreation && (
                        <Loader label={'Attendere qualche secondo...'} fullPage={true} />
                    )}
                    {list && list.length > 0 && (
                        <Grid
                            rowHeight={100}
                            currentNumberOfRecords={list.length}
                            totalRecords={totalRecords}
                            rows={getCampioniRows(list)}
                            columns={tables.CAMPIONI}
                            onShowMoreClicked={this.loadMore}
                            loading={waitingForList}
                            isLastPage={isLastPage}
                            onRowSelected={this.onRowSelected}
                        />
                    )}
                    {showCounter && !waitingForList && !waitingForItem && (
                        <CounterWrapper value={totalRecords} label={'Record trovati'} />
                    )}
                    {waitingForList && !list.length && (
                        <section className={classes['Campioni-loading']}>
                            <Loader center={true} fullContent={true} />
                        </section>
                    )}
                    <ModalWrapper
                        title={modalTitle}
                        visible={showDetailsModal}
                        onCloseClicked={() => this.setState({ showDetailsModal: false })}
                    >
                        {(waitingForItem || waitingForSede || waitingForControllo) && (
                            <Loader center={true} fullContent={true} />
                        )}
                        {item &&
                            !waitingForItem &&
                            !waitingForControllo &&
                            !waitingForSede &&
                            !currentControllo &&
                            !currentSede && (
                                <CampioneDetails
                                    onSedeClicked={this.onGetSede}
                                    onControlloClicked={this.onGetControllo}
                                    item={item}
                                />
                            )}
                        {currentSede &&
                            !waitingForItem &&
                            !waitingForControllo &&
                            !waitingForSede && <SedeDetails item={currentSede} />}
                        {currentControllo &&
                            !waitingForItem &&
                            !waitingForControllo &&
                            !waitingForSede && <ControlloDetails item={currentControllo} />}
                        {(currentControllo || currentSede) && (
                            <section className={classes.ActionsFooter}>
                                <Button theme={'success'} outline={true} clicked={this.resetModal}>
                                    {'Torna al Campione'}
                                </Button>
                            </section>
                        )}
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        list: state.campioneState.list,
        item: state.campioneState.selected,
        sede: state.dittaSedeState.selected,
        waitingForSede: state.dittaSedeState.isFetchingItem,
        controllo: state.controlloState.selected,
        waitingForControllo: state.controlloState.isFetchingItem,
        waitingForList: state.campioneState.isFetchingList,
        waitingForItem: state.campioneState.isFetchingItem,
        error: state.campioneState.error,
        isLastPage: state.campioneState.isLastPage,
        totalRecords: state.campioneState.totalRecords,
        totalPages: state.campioneState.totalPages,
        showCounter:
            state.campioneState.totalRecords >= 0 &&
            state.campioneState.onlyCounter &&
            !state.campioneState.list.length,
        waitingForFileCreation: state.campioneState.isCreatingFile,
        stream: state.campioneState.stream,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            classe: Array<number> = [],
            provenienza: Array<number> = [],
            circuito: Array<number> = [],
            tipologiaOsa: Array<number> = [],
            dataInizio: string = null,
            dataFine: string = null,
            page: number
        ) =>
            dispatch(
                actions.fetchCampioni(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    classe,
                    provenienza,
                    circuito,
                    tipologiaOsa,
                    dataInizio,
                    dataFine,
                    page
                )
            ),
        onResetData: () => dispatch(actions.resetCampioni()),
        onGetItem: (id: string) => dispatch(actions.fetchCampione(id)),
        onGetCounter: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            classe: Array<number> = [],
            provenienza: Array<number> = [],
            circuito: Array<number> = [],
            tipologiaOsa: Array<number> = [],
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(
                actions.fetchCampioniCounter(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    classe,
                    provenienza,
                    circuito,
                    tipologiaOsa,
                    dataInizio,
                    dataFine
                )
            ),
        onExportData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            masterList: Array<string>,
            ateco: Array<string>,
            classe: Array<number> = [],
            provenienza: Array<number> = [],
            circuito: Array<number> = [],
            tipologiaOsa: Array<number> = [],
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(
                actions.exportCampioniData(
                    keyword,
                    province,
                    cities,
                    masterList,
                    ateco,
                    classe,
                    provenienza,
                    circuito,
                    tipologiaOsa,
                    dataInizio,
                    dataFine
                )
            ),
        onGetSede: (id: string) => dispatch(actions.fetchDittaSediById(id)),
        onGetControllo: (id: string) => dispatch(actions.fetchControllo(id)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campioni));
