import React, { Component, CSSProperties } from 'react';
import Select from 'react-select';
import classes from './styles.module.scss';
import { ISelectOption } from '../../../shared/interfaces';
import _ from 'lodash';
import Label from '../Label';

interface IProps {
    label?: any;
    options?: Array<ISelectOption>;
    isMulti?: boolean;
    selectedChanged(selected: Array<ISelectOption> | ISelectOption): void;
    isLoading?: boolean;
    onInputChanged(keyword: string): void;
    placeholder?: string;
    noOptionsMessage?: string;
    name?: string;
    selected?: Array<ISelectOption> | ISelectOption;
    styles?: CSSProperties;
    labelBlack?: boolean;
}

interface IState {}

const WAIT = 500; // milliseconds

class SelectField extends Component<IProps, IState> {
    debouncedLoadOptions: (value: string) => void;
    constructor(props) {
        super(props);

        const loadOptions = (inputValue) => this.onInputChange(inputValue);
        this.debouncedLoadOptions = _.debounce(loadOptions, WAIT);
    }

    onInputChange(inputValue) {
        this.props.onInputChanged(inputValue);
    }

    handleChange = (selectedOption: Array<ISelectOption> | ISelectOption) => {
        const { selectedChanged } = this.props;
        selectedChanged(selectedOption);
    };

    render() {
        const {
            isMulti,
            options,
            isLoading,
            placeholder,
            noOptionsMessage,
            label,
            name,
            selected,
            styles,
            labelBlack,
        } = this.props;

        return (
            <section style={styles} className={classes.SelectField}>
                {label && (
                    <Label color={labelBlack ? 'black' : 'blue'} for={name}>
                        {label}
                    </Label>
                )}
                <Select
                    placeholder={placeholder || 'Ricerca'}
                    noOptionsMessage={() => (
                        <span>{noOptionsMessage || 'Nessun record trovato'}</span>
                    )}
                    isLoading={isLoading || false}
                    onInputChange={(inputValue) => this.debouncedLoadOptions(inputValue)}
                    value={selected}
                    isMulti={isMulti || false}
                    onChange={this.handleChange}
                    options={options}
                />
            </section>
        );
    }
}

export default SelectField;
