import React, { Component } from 'react';
import classes from './styles.module.scss';
import Button from '../UI/Button';

interface IProps {
    onSubmitClicked(): void;
    onResetClicked(): void;
    onGetCounter?(): void;
    onExportClicked?(): void;
    children: any;
    submitLabel: any;
    undoLabel: any;
}

class Filters extends Component<IProps> {
    render() {
        const {
            onSubmitClicked,
            children,
            submitLabel,
            undoLabel,
            onResetClicked,
            onGetCounter,
            onExportClicked,
        } = this.props;

        return (
            <section className={classes.Filters}>
                <div className={classes['Filters-content']}>{children}</div>
                <footer className={classes['Filters-footer']}>
                    <Button theme={'danger'} clicked={onResetClicked}>
                        {undoLabel}
                    </Button>
                    <section className={classes['Filters-right']}>
                        {onExportClicked && (
                            <Button theme={'success'} outline={true} clicked={onExportClicked}>
                                {'Esporta su Excel'}
                            </Button>
                        )}
                        {onGetCounter && (
                            <Button outline={true} clicked={onGetCounter}>
                                {'Conta'}
                            </Button>
                        )}
                        <Button clicked={onSubmitClicked}>{submitLabel}</Button>
                    </section>
                </footer>
            </section>
        );
    }
}

export default Filters;
