// SESSION / AUTH
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// BASE DATA
export const FETCH_MASTER_LIST_BY_KEYWORD_START = 'FETCH_MASTER_LIST_BY_KEYWORD_START';
export const FETCH_MASTER_LIST_BY_KEYWORD_SUCCESS = 'FETCH_MASTER_LIST_BY_KEYWORD_SUCCESS';
export const FETCH_MASTER_LIST_BY_KEYWORD_FAIL = 'FETCH_MASTER_LIST_BY_KEYWORD_FAIL';

export const FETCH_ATECO_BY_KEYWORD_START = 'FETCH_ATECO_BY_KEYWORD_START';
export const FETCH_ATECO_BY_KEYWORD_SUCCESS = 'FETCH_ATECO_BY_KEYWORD_SUCCESS';
export const FETCH_ATECO_BY_KEYWORD_FAIL = 'FETCH_ATECO_BY_KEYWORD_FAIL';

export const FETCH_CITTA_BY_KEYWORD_START = 'FETCH_CITTA_BY_KEYWORD_START';
export const FETCH_CITTA_BY_KEYWORD_SUCCESS = 'FETCH_CITTA_BY_KEYWORD_SUCCESS';
export const FETCH_CITTA_BY_KEYWORD_FAIL = 'FETCH_CITTA_BY_KEYWORD_FAIL';

// DITTA SEDE
export const FETCH_DITTA_SEDI_START = 'FETCH_DITTA_SEDI_START';
export const FETCH_DITTA_SEDI_SUCCESS = 'FETCH_DITTA_SEDI_SUCCESS';
export const FETCH_DITTA_SEDI_FAIL = 'FETCH_DITTA_SEDI_FAIL';

export const FETCH_DITTA_SEDI_BY_ID_START = 'FETCH_DITTA_SEDI_BY_ID_START';
export const FETCH_DITTA_SEDI_BY_ID_SUCCESS = 'FETCH_DITTA_SEDI_BY_ID_SUCCESS';
export const FETCH_DITTA_SEDI_BY_ID_FAIL = 'FETCH_DITTA_SEDI_BY_ID_FAIL';

export const FETCH_DITTA_SEDI_COUNTER_START = 'FETCH_DITTA_SEDI_COUNTER_START';
export const FETCH_DITTA_SEDI_COUNTER_SUCCESS = 'FETCH_DITTA_SEDI_COUNTER_SUCCESS';
export const FETCH_DITTA_SEDI_COUNTER_FAIL = 'FETCH_DITTA_SEDI_COUNTER_FAIL';

export const FETCH_DITTA_SEDI_EXPORT_START = 'FETCH_DITTA_SEDI_EXPORT_START';
export const FETCH_DITTA_SEDI_EXPORT_SUCCESS = 'FETCH_DITTA_SEDI_EXPORT_SUCCESS';
export const FETCH_DITTA_SEDI_EXPORT_FAIL = 'FETCH_DITTA_SEDI_EXPORT_FAIL';

export const RESET_DITTA_SEDI = 'RESET_DITTA_SEDI';

// CONTROLLO
export const FETCH_CONTROLLI_START = 'FETCH_CONTROLLI_START';
export const FETCH_CONTROLLI_SUCCESS = 'FETCH_CONTROLLI_SUCCESS';
export const FETCH_CONTROLLI_FAIL = 'FETCH_CONTROLLI_FAIL';

export const FETCH_CONTROLLO_START = 'FETCH_CONTROLLO_START';
export const FETCH_CONTROLLO_SUCCESS = 'FETCH_CONTROLLO_SUCCESS';
export const FETCH_CONTROLLO_FAIL = 'FETCH_CONTROLLO_FAIL';

export const FETCH_CONTROLLI_COUNTER_START = 'FETCH_CONTROLLI_COUNTER_START';
export const FETCH_CONTROLLI_COUNTER_SUCCESS = 'FETCH_CONTROLLI_COUNTER_SUCCESS';
export const FETCH_CONTROLLI_COUNTER_FAIL = 'FETCH_CONTROLLI_COUNTER_FAIL';

export const FETCH_CONTROLLI_EXPORT_START = 'FETCH_CONTROLLI_EXPORT_START';
export const FETCH_CONTROLLI_EXPORT_SUCCESS = 'FETCH_CONTROLLI_EXPORT_SUCCESS';
export const FETCH_CONTROLLI_EXPORT_FAIL = 'FETCH_CONTROLLI_EXPORT_FAIL';

export const RESET_CONTROLLI = 'RESET_CONTROLLI';

// FORMATORE
export const FETCH_FORMATORI_START = 'FETCH_FORMATORI_START';
export const FETCH_FORMATORI_SUCCESS = 'FETCH_FORMATORI_SUCCESS';
export const FETCH_FORMATORI_FAIL = 'FETCH_FORMATORI_FAIL';

export const FETCH_FORMATORE_START = 'FETCH_FORMATORE_START';
export const FETCH_FORMATORE_SUCCESS = 'FETCH_FORMATORE_SUCCESS';
export const FETCH_FORMATORE_FAIL = 'FETCH_FORMATORE_FAIL';

export const FETCH_FORMATORI_COUNTER_START = 'FETCH_FORMATORI_COUNTER_START';
export const FETCH_FORMATORI_COUNTER_SUCCESS = 'FETCH_FORMATORI_COUNTER_SUCCESS';
export const FETCH_FORMATORI_COUNTER_FAIL = 'FETCH_FORMATORI_COUNTER_FAIL';

export const FETCH_FORMATORI_EXPORT_START = 'FETCH_FORMATORI_EXPORT_START';
export const FETCH_FORMATORI_EXPORT_SUCCESS = 'FETCH_FORMATORI_EXPORT_SUCCESS';
export const FETCH_FORMATORI_EXPORT_FAIL = 'FETCH_FORMATORI_EXPORT_FAIL';

export const CREATE_FORMATORE_START = 'CREATE_FORMATORE_START';
export const CREATE_FORMATORE_SUCCESS = 'CREATE_FORMATORE_SUCCESS';
export const CREATE_FORMATORE_FAIL = 'CREATE_FORMATORE_FAIL';

export const UPDATE_FORMATORE_START = 'UPDATE_FORMATORE_START';
export const UPDATE_FORMATORE_SUCCESS = 'UPDATE_FORMATORE_SUCCESS';
export const UPDATE_FORMATORE_FAIL = 'UPDATE_FORMATORE_FAIL';

export const REMOVE_FORMATORE_START = 'REMOVE_FORMATORE_START';
export const REMOVE_FORMATORE_SUCCESS = 'REMOVE_FORMATORE_SUCCESS';
export const REMOVE_FORMATORE_FAIL = 'REMOVE_FORMATORE_FAIL';

export const FORMATORE_ASSIGN_NUMBER_START = 'FORMATORE_ASSIGN_NUMBER_START';
export const FORMATORE_ASSIGN_NUMBER_SUCCESS = 'FORMATORE_ASSIGN_NUMBER_SUCCESS';
export const FORMATORE_ASSIGN_NUMBER_FAIL = 'FORMATORE_ASSIGN_NUMBER_FAIL';

export const FETCH_FORMATORI_SEDI_START = 'FETCH_FORMATORI_SEDI_START';
export const FETCH_FORMATORI_SEDI_SUCCESS = 'FETCH_FORMATORI_SEDI_SUCCESS';
export const FETCH_FORMATORI_SEDI_FAIL = 'FETCH_FORMATORI_SEDI_FAIL';

export const FETCH_FORMATORE_SEDE_START = 'FETCH_FORMATORE_SEDE_START';
export const FETCH_FORMATORE_SEDE_SUCCESS = 'FETCH_FORMATORE_SEDE_SUCCESS';
export const FETCH_FORMATORE_SEDE_FAIL = 'FETCH_FORMATORE_SEDE_FAIL';

export const CREATE_FORMATORE_SEDE_START = 'CREATE_FORMATORE_SEDE_START';
export const CREATE_FORMATORE_SEDE_SUCCESS = 'CREATE_FORMATORE_SEDE_SUCCESS';
export const CREATE_FORMATORE_SEDE_FAIL = 'CREATE_FORMATORE_SEDE_FAIL';

export const UPDATE_FORMATORE_SEDE_START = 'UPDATE_FORMATORE_SEDE_START';
export const UPDATE_FORMATORE_SEDE_SUCCESS = 'UPDATE_FORMATORE_SEDE_SUCCESS';
export const UPDATE_FORMATORE_SEDE_FAIL = 'UPDATE_FORMATORE_SEDE_FAIL';

export const DELETE_FORMATORE_SEDE_START = 'DELETE_FORMATORE_SEDE_START';
export const DELETE_FORMATORE_SEDE_SUCCESS = 'DELETE_FORMATORE_SEDE_SUCCESS';
export const DELETE_FORMATORE_SEDE_FAIL = 'DELETE_FORMATORE_SEDE_FAIL';

export const CREATE_FORMATORE_DECRETO_START = 'CREATE_FORMATORE_DECRETO_START';
export const CREATE_FORMATORE_DECRETO_SUCCESS = 'CREATE_FORMATORE_DECRETO_SUCCESS';
export const CREATE_FORMATORE_DECRETO_FAIL = 'CREATE_FORMATORE_DECRETO_FAIL';

export const UPDATE_FORMATORE_DECRETO_START = 'UPDATE_FORMATORE_DECRETO_START';
export const UPDATE_FORMATORE_DECRETO_SUCCESS = 'UPDATE_FORMATORE_DECRETO_SUCCESS';
export const UPDATE_FORMATORE_DECRETO_FAIL = 'UPDATE_FORMATORE_DECRETO_FAIL';

export const DELETE_FORMATORE_DECRETO_START = 'DELETE_FORMATORE_DECRETO_START';
export const DELETE_FORMATORE_DECRETO_SUCCESS = 'DELETE_FORMATORE_DECRETO_SUCCESS';
export const DELETE_FORMATORE_DECRETO_FAIL = 'DELETE_FORMATORE_DECRETO_FAIL';

export const RESET_FORMATORI = 'RESET_FORMATORI';

// LABORATORIO
export const FETCH_LABORATORI_START = 'FETCH_LABORATORI_START';
export const FETCH_LABORATORI_SUCCESS = 'FETCH_LABORATORI_SUCCESS';
export const FETCH_LABORATORI_FAIL = 'FETCH_LABORATORI_FAIL';

export const FETCH_LABORATORIO_START = 'FETCH_LABORATORIO_START';
export const FETCH_LABORATORIO_SUCCESS = 'FETCH_LABORATORIO_SUCCESS';
export const FETCH_LABORATORIO_FAIL = 'FETCH_LABORATORIO_FAIL';

export const FETCH_LABORATORI_COUNTER_START = 'FETCH_LABORATORI_COUNTER_START';
export const FETCH_LABORATORI_COUNTER_SUCCESS = 'FETCH_LABORATORI_COUNTER_SUCCESS';
export const FETCH_LABORATORI_COUNTER_FAIL = 'FETCH_LABORATORI_COUNTER_FAIL';

export const FETCH_LABORATORI_EXPORT_START = 'FETCH_LABORATORI_EXPORT_START';
export const FETCH_LABORATORI_EXPORT_SUCCESS = 'FETCH_LABORATORI_EXPORT_SUCCESS';
export const FETCH_LABORATORI_EXPORT_FAIL = 'FETCH_LABORATORI_EXPORT_FAIL';

export const CREATE_LABORATORIO_START = 'CREATE_LABORATORIO_START';
export const CREATE_LABORATORIO_SUCCESS = 'CREATE_LABORATORIO_SUCCESS';
export const CREATE_LABORATORIO_FAIL = 'CREATE_LABORATORIO_FAIL';

export const UPDATE_LABORATORIO_START = 'UPDATE_LABORATORIO_START';
export const UPDATE_LABORATORIO_SUCCESS = 'UPDATE_LABORATORIO_SUCCESS';
export const UPDATE_LABORATORIO_FAIL = 'UPDATE_LABORATORIO_FAIL';

export const REMOVE_LABORATORIO_START = 'REMOVE_LABORATORIO_START';
export const REMOVE_LABORATORIO_SUCCESS = 'REMOVE_LABORATORIO_SUCCESS';
export const REMOVE_LABORATORIO_FAIL = 'REMOVE_LABORATORIO_FAIL';

export const LABORATORIO_ASSIGN_NUMBER_START = 'LABORATORIO_ASSIGN_NUMBER_START';
export const LABORATORIO_ASSIGN_NUMBER_SUCCESS = 'LABORATORIO_ASSIGN_NUMBER_SUCCESS';
export const LABORATORIO_ASSIGN_NUMBER_FAIL = 'LABORATORIO_ASSIGN_NUMBER_FAIL';

export const FETCH_LABORATORI_SEDI_START = 'FETCH_LABORATORI_SEDI_START';
export const FETCH_LABORATORI_SEDI_SUCCESS = 'FETCH_LABORATORI_SEDI_SUCCESS';
export const FETCH_LABORATORI_SEDI_FAIL = 'FETCH_LABORATORI_SEDI_FAIL';

export const FETCH_LABORATORIO_SEDE_START = 'FETCH_LABORATORIO_SEDE_START';
export const FETCH_LABORATORIO_SEDE_SUCCESS = 'FETCH_LABORATORIO_SEDE_SUCCESS';
export const FETCH_LABORATORIO_SEDE_FAIL = 'FETCH_LABORATORIO_SEDE_FAIL';

export const CREATE_LABORATORIO_SEDE_START = 'CREATE_LABORATORIO_SEDE_START';
export const CREATE_LABORATORIO_SEDE_SUCCESS = 'CREATE_LABORATORIO_SEDE_SUCCESS';
export const CREATE_LABORATORIO_SEDE_FAIL = 'CREATE_LABORATORIO_SEDE_FAIL';

export const UPDATE_LABORATORIO_SEDE_START = 'UPDATE_LABORATORIO_SEDE_START';
export const UPDATE_LABORATORIO_SEDE_SUCCESS = 'UPDATE_LABORATORIO_SEDE_SUCCESS';
export const UPDATE_LABORATORIO_SEDE_FAIL = 'UPDATE_LABORATORIO_SEDE_FAIL';

export const DELETE_LABORATORIO_SEDE_START = 'DELETE_LABORATORIO_SEDE_START';
export const DELETE_LABORATORIO_SEDE_SUCCESS = 'DELETE_LABORATORIO_SEDE_SUCCESS';
export const DELETE_LABORATORIO_SEDE_FAIL = 'DELETE_LABORATORIO_SEDE_FAIL';

export const CREATE_LABORATORIO_DECRETO_START = 'CREATE_LABORATORIO_DECRETO_START';
export const CREATE_LABORATORIO_DECRETO_SUCCESS = 'CREATE_LABORATORIO_DECRETO_SUCCESS';
export const CREATE_LABORATORIO_DECRETO_FAIL = 'CREATE_LABORATORIO_DECRETO_FAIL';

export const UPDATE_LABORATORIO_DECRETO_START = 'UPDATE_LABORATORIO_DECRETO_START';
export const UPDATE_LABORATORIO_DECRETO_SUCCESS = 'UPDATE_LABORATORIO_DECRETO_SUCCESS';
export const UPDATE_LABORATORIO_DECRETO_FAIL = 'UPDATE_LABORATORIO_DECRETO_FAIL';

export const DELETE_LABORATORIO_DECRETO_START = 'DELETE_LABORATORIO_DECRETO_START';
export const DELETE_LABORATORIO_DECRETO_SUCCESS = 'DELETE_LABORATORIO_DECRETO_SUCCESS';
export const DELETE_LABORATORIO_DECRETO_FAIL = 'DELETE_LABORATORIO_DECRETO_FAIL';

export const RESET_LABORATORI = 'RESET_LABORATORI';

// CAMPIONE
export const FETCH_CAMPIONI_START = 'FETCH_CAMPIONI_START';
export const FETCH_CAMPIONI_SUCCESS = 'FETCH_CAMPIONI_SUCCESS';
export const FETCH_CAMPIONI_FAIL = 'FETCH_CAMPIONI_FAIL';

export const FETCH_CAMPIONE_START = 'FETCH_CAMPIONE_START';
export const FETCH_CAMPIONE_SUCCESS = 'FETCH_CAMPIONE_SUCCESS';
export const FETCH_CAMPIONE_FAIL = 'FETCH_CAMPIONE_FAIL';

export const FETCH_CAMPIONI_COUNTER_START = 'FETCH_CAMPIONI_COUNTER_START';
export const FETCH_CAMPIONI_COUNTER_SUCCESS = 'FETCH_CAMPIONI_COUNTER_SUCCESS';
export const FETCH_CAMPIONI_COUNTER_FAIL = 'FETCH_CAMPIONI_COUNTER_FAIL';

export const FETCH_CAMPIONI_EXPORT_START = 'FETCH_CAMPIONI_EXPORT_START';
export const FETCH_CAMPIONI_EXPORT_SUCCESS = 'FETCH_CAMPIONI_EXPORT_SUCCESS';
export const FETCH_CAMPIONI_EXPORT_FAIL = 'FETCH_CAMPIONI_EXPORT_FAIL';

export const RESET_CAMPIONI = 'RESET_CAMPIONI';

// STATS
export const FETCH_STATS_START = 'FETCH_STATS_START';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAIL = 'FETCH_STATS_FAIL';

export const RESET_STATS = 'RESET_STATS';
