import React, { Component } from 'react';
import classes from './styles.module.scss';
import { IStatRow, IStatTotals } from '../../shared/interfaces';

interface IProps {
    rows: Array<IStatRow>;
    type: string;
    showEffect?: boolean;
    totals?: IStatTotals;
    columns: Array<string>;
    cellClicked?(prov: string): void;
    goToAnagrafica?(citta: string, idCitta: string): void;
}

class StatsTable extends Component<IProps> {
    render() {
        const { type, showEffect, rows, totals, columns, cellClicked, goToAnagrafica } = this.props;

        const rowsTpl = rows.map((item: IStatRow, ix: number) => (
            <tr key={ix}>
                <td
                    className={
                        (cellClicked || goToAnagrafica) && item.sigla !== 'altre'
                            ? classes['StatsTable-buttonColumn']
                            : ''
                    }
                >
                    {type === 'master' ? (
                        cellClicked && item.sigla !== 'Altre' ? (
                            <button onClick={() => cellClicked(item.sigla)}>
                                {item.provincia}
                            </button>
                        ) : (
                            <span>{item.provincia}</span>
                        )
                    ) : goToAnagrafica && item.sigla !== 'Altre' ? (
                        <button onClick={() => goToAnagrafica(item.citta, item.idCitta)}>
                            {item.citta}
                        </button>
                    ) : (
                        <span>{item.citta}</span>
                    )}
                </td>
                <td>
                    <span>{item.osa}</span>
                    {item.deltaOsa && <small>({item.deltaOsa})</small>}
                </td>
                <td>
                    <span>{item.stabilimenti}</span>
                    {item.deltaStabilimenti && <small>({item.deltaStabilimenti})</small>}
                </td>
                <td>
                    <span>{item.attivita}</span>
                    {item.deltaAttivita && <small>({item.deltaAttivita})</small>}
                </td>
            </tr>
        ));

        return (
            <table
                className={[
                    classes.StatsTable,
                    showEffect ? classes['StatsTable--effect'] : '',
                ].join(' ')}
            >
                <thead>
                    <tr>
                        {columns.map((col: string, ix: number) => (
                            <th key={ix}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>{rowsTpl}</tbody>
                {totals && (
                    <tfoot>
                        <tr>
                            <td>{totals.label}</td>
                            <td>{totals.osa}</td>
                            <td>{totals.stabilimenti}</td>
                            <td>{totals.attivita}</td>
                        </tr>
                    </tfoot>
                )}
            </table>
        );
    }
}

export default StatsTable;
