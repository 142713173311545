import * as React from 'react';
import classes from './styles.module.scss';
import { IButton } from '../../../shared/interfaces';

const Button = (props: IButton) => {
    const {
        children,
        weight,
        clicked,
        size,
        shadowed,
        pullRight,
        disabled,
        type,
        outline,
        theme,
    } = props;

    const customClasses = [
        classes.Button,
        weight ? classes[`Button--${weight}`] : '',
        size ? classes[`Button--${size}`] : '',
        theme ? classes[`Button--${theme}`] : '',
        shadowed ? classes[`Button--shadowed`] : '',
        pullRight ? classes[`Button--pullRight`] : '',
        outline ? classes[`Button--outline`] : '',
    ].join(' ');

    return (
        <button
            type={type || 'button'}
            onClick={clicked}
            disabled={disabled || false}
            className={customClasses}
        >
            {children}
        </button>
    );
};

export default Button;
