import React, { Component } from 'react';
import { IAteco, ISelectOption } from '../../shared/interfaces';
import * as actions from '../../store/actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import SelectField from '../UI/Select';
import { getSelectOptionsForAteco } from '../../shared/helpers/selects';

const PLACEHOLDER = 'Ricerca ateco';

interface IProps {
    onGetList(keyword: string): Array<IAteco>;
    label: any;
    name?: string;
    waitingForList: boolean;
    items: Array<IAteco>;
    onSelectedChanged(selected: Array<ISelectOption> | ISelectOption): void;
    selected: Array<ISelectOption> | ISelectOption;
}

class AtecoSelect extends Component<IProps & RouteComponentProps> {
    onKeywordChanged = (value: string) => {
        if (value && value.length > 2) {
            this.props.onGetList(value);
        }
    };

    render() {
        const { waitingForList, items, onSelectedChanged, name, label, selected } = this.props;

        return (
            <SelectField
                placeholder={PLACEHOLDER}
                name={name}
                label={label}
                onInputChanged={this.onKeywordChanged}
                selectedChanged={onSelectedChanged}
                isMulti={true}
                options={getSelectOptionsForAteco(items)}
                isLoading={waitingForList}
                selected={selected}
            />
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        items: state.atecoState.list,
        waitingForList: state.atecoState.isFetching,
        itemsError: state.atecoState.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetList: (keyword: string) => dispatch(actions.fetchAtecoByKeyword(keyword)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtecoSelect));
