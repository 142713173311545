import React, { Component } from 'react';
import { it } from 'date-fns/locale';
import Label from '../Label';
// @ts-ignore
import { DatePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import './styles.scss';
import { getDateFromString } from '../../../shared/helpers/utilities';
import dayjs from 'dayjs';

interface IProps {
    label?: string;
    onDateChanged(date: Date): void;
    current: string;
}

interface IState {
    date: Date | null;
}

class DateField extends Component<IProps, IState> {
    state = {
        date: null,
    };

    componentDidMount() {
        if (this.props.current) {
            this.setDate(getDateFromString(this.props.current));
        }
    }

    setDate = (value: Date) => {
        this.setState({
            date: value,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.date !== prevState.date) {
            this.props.onDateChanged(this.state.date);
        }

        if (this.props.current !== prevProps.current && this.props.current) {
            this.setDate(getDateFromString(this.props.current));
        }
    }

    render() {
        const { label } = this.props;
        const { date } = this.state;

        return (
            <DatePicker date={date} onDateChange={this.setDate} format="dd/MM/yyyy" locale={it}>
                {({ inputProps }) => (
                    <>
                        {label && <Label for={'dates'}>{label}</Label>}
                        <input
                            className={'inputDate'}
                            {...inputProps}
                            placeholder={'Data'}
                            value={date ? dayjs(date).format('DD/MM/YYYY') : ''}
                        />
                    </>
                )}
            </DatePicker>
        );
    }
}

export default DateField;
