import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../../shared/routes';

// HOC for private Routes
import PrivateRoute from '../../hocs/PrivateRoute';

// Routes
import Homepage from '../../routes/Homepage';
import Signin from '../../routes/Signin';
import Anagrafica from '../../routes/Anagrafica';
import Controlli from '../../routes/Controlli';
import Campioni from '../../routes/Campioni';
import Formatori from '../../routes/Formatori';
import Statistiche from '../../routes/Statistiche';
import Formatore from '../../routes/Formatore';
import Laboratori from '../../routes/Laboratori';
import Laboratorio from '../../routes/Laboratorio';
import ErrorPage from '../../routes/ErrorPage';

class RouterWrapper extends Component {
    render() {
        return (
            <Switch>
                <Route exact={true} path={routes.HOMEPAGE} component={Homepage} />
                <Route exact={true} path={routes.SIGNIN} component={Signin} />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.ANAGRAFICA}
                    component={Anagrafica}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.CONTROLLI}
                    component={Controlli}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.CAMPIONI}
                    component={Campioni}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.FORMATORI}
                    component={Formatori}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.FORMATORE}
                    component={Formatore}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.LABORATORI}
                    component={Laboratori}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.LABORATORIO}
                    component={Laboratorio}
                />
                <PrivateRoute
                    redirectTo={routes.SIGNIN}
                    exact={true}
                    path={routes.STATISTICHE}
                    component={Statistiche}
                />
                <Route exact={true} path={routes.ERROR_PAGE} component={ErrorPage} />
                <Redirect to={routes.SIGNIN} />
            </Switch>
        );
    }
}

export default RouterWrapper;
