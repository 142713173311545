import React from 'react';
import Text from '../UI/Text';

interface IProps {
    text?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    weight?: 'regular' | 'medium' | 'bold';
    color?: 'red' | 'grey';
}

const NoDataText = (props: IProps) => {
    return (
        <Text size={props.size} weight={props.weight} color={props.color || 'grey'}>
            {props.text || 'Dati non presenti'}
        </Text>
    );
};

export default NoDataText;
