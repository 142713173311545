import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import { ILaboratorioSede } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';
import IconButton from '../../components/UI/IconButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import ConfirmDialog from '../../components/ConfirmDialog';
import ModalWrapper from '../../components/Modal';
import Title from '../../components/UI/Title';
import Toast from '../../components/UI/Toast';
import LaboratorioDetails from '../../components/LaboratorioDetails';
import LaboratorioForm from '../../components/LaboratorioForm'; // Import css

interface IProps {
    history: History;
    match: {
        params: { idDitta: string; idSede: string };
    };
    error: any;
    waitingForItem: boolean;
    onGetItem(idDitta: string, idSede: string): ILaboratorioSede;
    onRemove(idLabAnalisiDitta: string, idDitta: string, id: string): void;
    item: ILaboratorioSede;
    lastRemovedItem: string;
    waitingForRemoving: boolean;
}

interface IState {
    showForm: boolean;
}

class Laboratorio extends Component<IProps & RouteComponentProps, IState> {
    state = {
        showForm: false,
    };

    componentDidMount() {
        const {
            match: {
                params: { idDitta, idSede },
            },
        } = this.props;

        if (idDitta && idSede) {
            this.props.onGetItem(idDitta, idSede);
        } else {
            this.props.history.push(routes.FORMATORI);
        }
    }

    onToggleFormVisibility = () => {
        const showForm = !this.state.showForm;

        this.setState({
            showForm,
        });
    };

    onEditClicked = () => {
        this.onToggleFormVisibility();
    };

    onRemoveClicked = () => {
        const {
            item: { IdLabAnalisiDitta, Id, Ditta },
        } = this.props;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmDialog
                        onClose={onClose}
                        onConfirm={() => this.props.onRemove(IdLabAnalisiDitta, Ditta.Id, Id)}
                        title={'Sei sicuro di voler rimuovere questo laboratorio?'}
                        subtitle={"L'operazione NON potrà essere annullata."}
                    />
                );
            },
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastRemovedItem !== prevProps.lastRemovedItem &&
            this.props.lastRemovedItem
        ) {
            Toast({ message: 'Record rimosso con successo.', type: 'success' });
            this.props.history.push(routes.LABORATORI);
        }
    }

    render() {
        const { item, waitingForItem, waitingForRemoving } = this.props;
        const { showForm } = this.state;

        return (
            <AuthWrapper>
                <main className={classes.Laboratorio}>
                    {(waitingForItem || waitingForRemoving) && (
                        <Loader center={true} fullPage={true} />
                    )}
                    {!waitingForItem && <Title>{'Dettaglio laboratorio'}</Title>}
                    {!waitingForItem && (
                        <section className={classes['Laboratorio-actions']}>
                            <IconButton
                                clicked={this.onRemoveClicked}
                                theme={'danger'}
                                icon={'trash'}
                            />
                            <IconButton clicked={this.onEditClicked} icon={'edit'} />
                        </section>
                    )}
                    {item && <LaboratorioDetails />}
                    <ModalWrapper
                        title={'Modifica laboratorio'}
                        visible={showForm}
                        onCloseClicked={this.onToggleFormVisibility}
                    >
                        <LaboratorioForm
                            onCloseModal={this.onToggleFormVisibility}
                            currentData={item ? item.Ditta : null}
                        />
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        item: state.laboratorioState.selected,
        waitingForItem: state.laboratorioState.isFetchingItem,
        error: state.laboratorioState.error,
        sedi: state.laboratorioState.sedi,
        lastRemovedItem: state.laboratorioState.lastRemovedLaboratorio,
        waitingForRemoving: state.laboratorioState.isRemovingLaboratorio,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onResetData: () => dispatch(actions.resetLaboratori()),
        onGetItem: (idDitta: string, idSede: string) =>
            dispatch(actions.fetchLaboratorio(idDitta, idSede)),
        onRemove: (idLabAnalisiDitta: string, idDitta: string, id: string) =>
            dispatch(actions.removeLaboratorio(idLabAnalisiDitta, idDitta, id)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Laboratorio));
