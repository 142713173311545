import * as actionTypes from '../actions/actionTypes';
import { IDittaSede, IDittaSedeItem } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    list: Array<IDittaSede>;
    error: string;
    page: number;
    count: number;
    totalPages: number;
    item: IDittaSedeItem;
    stream: Blob;
}

const INITIAL_STATE = {
    list: [],
    selected: null,
    isFetchingList: false,
    isFetchingItem: false,
    didInvalidate: false,
    error: null,
    currentPage: 1,
    isLastPage: false,
    totalRecords: 0,
    totalPages: 0,
    onlyCounter: false,
    stream: null,
    isCreatingFile: false,
};

const fetchDittaSediStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.page === 1 ? [] : state.list,
        currentPage: action.page,
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: action.page === 1 ? 0 : state.totalRecords,
        totalPages: action.page === 1 ? 0 : state.totalPages,
        onlyCounter: false,
    });
};

const fetchDittaSediSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldPaginatedList: Array<IDittaSede> = [...state.list];
    const updatedPaginatedList = oldPaginatedList.concat(action.list);

    return updateObject(state, {
        list: updatedPaginatedList,
        isFetchingList: false,
        isLastPage: state.currentPage === action.totalPages,
        totalRecords: action.count,
        totalPages: action.totalPages,
    });
};

const fetchDittaSediFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const fetchDittaSediByIdStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingItem: true,
        selected: null,
        onlyCounter: false,
    });
};

const fetchDittaSediByIdSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingItem: false,
        selected: action.item,
    });
};

const fetchDittaSediByIdFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingItem: false,
        didInvalidate: true,
    });
};

const fetchCounterStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: 0,
        list: [],
        onlyCounter: true,
    });
};

const fetchCounterSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingList: false,
        totalRecords: action.count,
    });
};

const fetchCounterFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const exportStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFile: true,
    });
};

const exportSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFile: false,
        stream: action.stream,
    });
};

const exportFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingFile: false,
        didInvalidate: true,
    });
};

const resetDittaSedi = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        isFetching: false,
        didInvalidate: false,
        error: null,
        currentPage: 1,
        isLastPage: false,
        totalRecords: 0,
        onlyCounter: false,
    });
};

const dittaSedeReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_DITTA_SEDI_START:
            return fetchDittaSediStart(state, action);
        case actionTypes.FETCH_DITTA_SEDI_SUCCESS:
            return fetchDittaSediSuccess(state, action);
        case actionTypes.FETCH_DITTA_SEDI_FAIL:
            return fetchDittaSediFail(state, action);

        case actionTypes.FETCH_DITTA_SEDI_BY_ID_START:
            return fetchDittaSediByIdStart(state, action);
        case actionTypes.FETCH_DITTA_SEDI_BY_ID_SUCCESS:
            return fetchDittaSediByIdSuccess(state, action);
        case actionTypes.FETCH_DITTA_SEDI_BY_ID_FAIL:
            return fetchDittaSediByIdFail(state, action);

        case actionTypes.FETCH_DITTA_SEDI_COUNTER_START:
            return fetchCounterStart(state);
        case actionTypes.FETCH_DITTA_SEDI_COUNTER_SUCCESS:
            return fetchCounterSuccess(state, action);
        case actionTypes.FETCH_DITTA_SEDI_COUNTER_FAIL:
            return fetchCounterFail(state, action);

        case actionTypes.FETCH_DITTA_SEDI_EXPORT_START:
            return exportStart(state);
        case actionTypes.FETCH_DITTA_SEDI_EXPORT_SUCCESS:
            return exportSuccess(state, action);
        case actionTypes.FETCH_DITTA_SEDI_EXPORT_FAIL:
            return exportFail(state, action);

        case actionTypes.RESET_DITTA_SEDI:
            return resetDittaSedi(state);

        default:
            return state;
    }
};

export default dittaSedeReducer;
