import * as actionTypes from './actionTypes';
import {
    IError,
    IFormatore,
    IDecreto,
    IDecretoForm,
    IFormatoreForm,
    IFormatoreSede,
    IFormatoreSedeForm,
    IResponse,
    IValidationError,
} from '../../shared/interfaces';
import FormatoreService from '../../services/api/formatore';

// FETCH
export const fetchFormatoriStart = (page: number) => {
    return {
        type: actionTypes.FETCH_FORMATORI_START,
        page,
    };
};

export const fetchFormatoriSuccess = (
    list: Array<IFormatore>,
    count: number,
    totalPages: number
) => {
    return {
        type: actionTypes.FETCH_FORMATORI_SUCCESS,
        list,
        count,
        totalPages,
    };
};

export const fetchFormatoriFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FORMATORI_FAIL,
        error,
    };
};

export const fetchFormatori = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    return (dispatch: any) => {
        dispatch(fetchFormatoriStart(page));

        FormatoreService.get(keyword, province, cities, dataInizio, dataFine, page).then(
            (response: IResponse) => {
                dispatch(
                    fetchFormatoriSuccess(
                        response.Data,
                        response.DataCount || 0,
                        response.TotalPages || 1
                    )
                );
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchFormatoriFail(response.data.error));
            }
        );
    };
};

// CREATE
export const createFormatoreStart = () => {
    return {
        type: actionTypes.CREATE_FORMATORE_START,
    };
};

export const createFormatoreSuccess = (item: IFormatore) => {
    return {
        type: actionTypes.CREATE_FORMATORE_SUCCESS,
        item,
    };
};

export const createFormatoreFail = (error: string) => {
    return {
        type: actionTypes.CREATE_FORMATORE_FAIL,
        error,
    };
};

export const createFormatore = (data: IFormatoreForm, assegnaNumero: boolean) => {
    return (dispatch: any) => {
        dispatch(createFormatoreStart());

        FormatoreService.create(data, assegnaNumero).then(
            (response: IResponse) => {
                dispatch(createFormatoreSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createFormatoreFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(
                        createFormatoreFail('Si è verificato un problema durante il salvataggio')
                    );
                }
            }
        );
    };
};

// UPDATE
export const updateFormatoreStart = () => {
    return {
        type: actionTypes.UPDATE_FORMATORE_START,
    };
};

export const updateFormatoreSuccess = (item: IFormatore) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_SUCCESS,
        item,
    };
};

export const updateFormatoreFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_FAIL,
        error,
    };
};

export const updateFormatore = (id: string, data: IFormatoreForm, isCorrezione: boolean = true) => {
    return (dispatch: any) => {
        dispatch(updateFormatoreStart());

        FormatoreService.update(id, data, isCorrezione).then(
            (response: IResponse) => {
                dispatch(updateFormatoreSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateFormatoreFail(response.data.error));
            }
        );
    };
};

export const removeFormatoreStart = () => {
    return {
        type: actionTypes.REMOVE_FORMATORE_START,
    };
};

export const removeFormatoreSuccess = (id: string) => {
    return {
        type: actionTypes.REMOVE_FORMATORE_SUCCESS,
        lastRemovedId: id,
    };
};

export const removeFormatoreFail = (error: string) => {
    return {
        type: actionTypes.REMOVE_FORMATORE_FAIL,
        error,
    };
};

export const removeFormatore = (id: string) => {
    return (dispatch: any) => {
        dispatch(removeFormatoreStart());

        FormatoreService.remove(id).then(
            () => {
                dispatch(removeFormatoreSuccess(id));
            },
            (response: { data: { error: string } }) => {
                dispatch(removeFormatoreFail(response.data.error));
            }
        );
    };
};

// DETAIL
export const fetchFormatoreStart = () => {
    return {
        type: actionTypes.FETCH_FORMATORE_START,
    };
};

export const fetchFormatoreSuccess = (item: IFormatore) => {
    return {
        type: actionTypes.FETCH_FORMATORE_SUCCESS,
        item,
    };
};

export const fetchFormatoreFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FORMATORE_FAIL,
        error,
    };
};

export const fetchFormatore = (id: string) => {
    return (dispatch: any) => {
        dispatch(fetchFormatoreStart());

        FormatoreService.getById(id).then(
            (data: IFormatore) => {
                dispatch(fetchFormatoreSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchFormatoreFail(response.data.error));
            }
        );
    };
};

// CREATE SEDE
export const createSedeStart = () => {
    return {
        type: actionTypes.CREATE_FORMATORE_SEDE_START,
    };
};

export const createSedeSuccess = (office: IFormatoreSede) => {
    return {
        type: actionTypes.CREATE_FORMATORE_SEDE_SUCCESS,
        office,
    };
};

export const createSedeFail = (error: string) => {
    return {
        type: actionTypes.CREATE_FORMATORE_SEDE_FAIL,
        error,
    };
};

export const createSede = (idFormatore: string, data: IFormatoreSedeForm) => {
    return (dispatch: any) => {
        dispatch(createSedeStart());

        FormatoreService.createSede(idFormatore, data).then(
            (response: IResponse) => {
                dispatch(createSedeSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createSedeFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(createSedeFail('Si è verificato un problema durante il salvataggio'));
                }
            }
        );
    };
};

// UPDATE SEDE
export const updateSedeStart = () => {
    return {
        type: actionTypes.UPDATE_FORMATORE_SEDE_START,
    };
};

export const updateSedeSuccess = (office: IFormatoreSede) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_SEDE_SUCCESS,
        office,
    };
};

export const updateSedeFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_SEDE_FAIL,
        error,
    };
};

export const updateSede = (idFormatore: string, idSede: string, data: IFormatoreSedeForm) => {
    return (dispatch: any) => {
        dispatch(updateSedeStart());

        FormatoreService.updateSede(idFormatore, idSede, data).then(
            (response: IResponse) => {
                dispatch(updateSedeSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateSedeFail(response.data.error));
            }
        );
    };
};

// REMOVE SEDE
export const removeSedeStart = () => {
    return {
        type: actionTypes.DELETE_FORMATORE_SEDE_START,
    };
};

export const removeSedeSuccess = (lastRemovedSedeId: string) => {
    return {
        type: actionTypes.DELETE_FORMATORE_SEDE_SUCCESS,
        lastRemovedSedeId,
    };
};

export const removeSedeFail = (error: string) => {
    return {
        type: actionTypes.DELETE_FORMATORE_SEDE_FAIL,
        error,
    };
};

export const removeSede = (idFormatore: string, idSede: string) => {
    return (dispatch: any) => {
        dispatch(removeSedeStart());

        FormatoreService.removeSede(idFormatore, idSede).then(
            () => {
                dispatch(removeSedeSuccess(idSede));
            },
            (response: { data: { error: string } }) => {
                dispatch(removeSedeFail(response.data.error));
            }
        );
    };
};

// CREATE DECRETO
export const createDecretoStart = () => {
    return {
        type: actionTypes.CREATE_FORMATORE_DECRETO_START,
    };
};

export const createDecretoSuccess = (decreto: IDecreto) => {
    return {
        type: actionTypes.CREATE_FORMATORE_DECRETO_SUCCESS,
        decreto,
    };
};

export const createDecretoFail = (error: string) => {
    return {
        type: actionTypes.CREATE_FORMATORE_DECRETO_FAIL,
        error,
    };
};

export const createDecreto = (idFormatore: string, data: IDecretoForm) => {
    return (dispatch: any) => {
        dispatch(createDecretoStart());

        FormatoreService.createDecreto(idFormatore, data).then(
            (response: IResponse) => {
                dispatch(createDecretoSuccess(response.Data));
            },
            (errorResponse: IError) => {
                if (errorResponse.ValidationErrors) {
                    dispatch(
                        createDecretoFail(
                            errorResponse.ValidationErrors.map(
                                (item: IValidationError) => item.ErrorMessage
                            ).join('. ')
                        )
                    );
                } else {
                    dispatch(createSedeFail('Si è verificato un problema durante il salvataggio'));
                }
            }
        );
    };
};

// UPDATE SEDE
export const updateDecretoStart = () => {
    return {
        type: actionTypes.UPDATE_FORMATORE_DECRETO_START,
    };
};

export const updateDecretoSuccess = (decreto: IDecreto) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_DECRETO_SUCCESS,
        decreto,
    };
};

export const updateDecretoFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_FORMATORE_DECRETO_FAIL,
        error,
    };
};

export const updateDecreto = (idFormatore: string, idDecreto: string, data: IDecretoForm) => {
    return (dispatch: any) => {
        dispatch(updateDecretoStart());

        FormatoreService.updateDecreto(idFormatore, idDecreto, data).then(
            (response: IResponse) => {
                dispatch(updateDecretoSuccess(response.Data));
            },
            (response: { data: { error: string } }) => {
                dispatch(updateDecretoFail(response.data.error));
            }
        );
    };
};

// REMOVE SEDE
export const removeDecretoStart = () => {
    return {
        type: actionTypes.DELETE_FORMATORE_DECRETO_START,
    };
};

export const removeDecretoSuccess = (lastRemovedDecretoId: string) => {
    return {
        type: actionTypes.DELETE_FORMATORE_DECRETO_SUCCESS,
        lastRemovedDecretoId,
    };
};

export const removeDecretoFail = (error: string) => {
    return {
        type: actionTypes.DELETE_FORMATORE_DECRETO_FAIL,
        error,
    };
};

export const removeDecreto = (idFormatore: string, idDecreto: string) => {
    return (dispatch: any) => {
        dispatch(removeDecretoStart());

        FormatoreService.removeDecreto(idFormatore, idDecreto).then(
            () => {
                dispatch(removeDecretoSuccess(idDecreto));
            },
            (response: { data: { error: string } }) => {
                dispatch(removeDecretoFail(response.data.error));
            }
        );
    };
};

// COUNTER
export const fetchFormatoriCounterStart = () => {
    return {
        type: actionTypes.FETCH_FORMATORI_COUNTER_START,
    };
};

export const fetchFormatoriCounterSuccess = (count: number) => {
    return {
        type: actionTypes.FETCH_FORMATORI_COUNTER_SUCCESS,
        count,
    };
};

export const fetchFormatoriCounterFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FORMATORI_COUNTER_FAIL,
        error,
    };
};

export const fetchFormatoriCounter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(fetchFormatoriCounterStart());

        FormatoreService.counter(keyword, province, cities, dataInizio, dataFine).then(
            (response: IResponse) => {
                dispatch(fetchFormatoriCounterSuccess(response.DataCount || 0));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchFormatoriCounterFail(response.data.error));
            }
        );
    };
};

// EXPORT
export const exportFormatoriDataStart = () => {
    return {
        type: actionTypes.FETCH_FORMATORI_EXPORT_START,
    };
};

export const exportFormatoriDataSuccess = (stream: Blob) => {
    return {
        type: actionTypes.FETCH_FORMATORI_EXPORT_SUCCESS,
        stream,
    };
};

export const exportFormatoriDataFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FORMATORI_EXPORT_FAIL,
        error,
    };
};

export const exportFormatoriData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(exportFormatoriDataStart());

        FormatoreService.exportData(keyword, province, cities, dataInizio, dataFine).then(
            (stream: Blob) => {
                dispatch(exportFormatoriDataSuccess(stream));
            },
            (response: { data: { error: string } }) => {
                dispatch(exportFormatoriDataFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetFormatori = () => {
    return {
        type: actionTypes.RESET_FORMATORI,
    };
};
