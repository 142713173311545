import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: string;
    size?: 'sm' | 'md' | 'lg';
    color?: 'blue' | 'black' | 'red';
}

const Title = (props: IProps) => {
    return (
        <h1
            className={[
                classes.Title,
                props.size ? classes[`Title--${props.size}`] : '',
                props.color ? classes[`Title--${props.color}`] : '',
            ].join(' ')}
        >
            {props.children}
        </h1>
    );
};

export default Title;
