import React, { CSSProperties } from 'react';
import classes from './styles.module.scss';
import Label from '../Label';
import FlexWrapper from '../FlexWrapper';

interface IProps {
    children: string | number;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    weight?: 'regular' | 'medium' | 'bold';
    color?: 'red' | 'grey' | 'green';
    label?: string;
    inline?: boolean;
    wrap?: boolean;
    marginBottom?: number;
}

const Text = (props: IProps) => {
    let styles: CSSProperties = {};
    if (props.marginBottom) {
        styles = {
            marginBottom: `${props.marginBottom}px`,
        };
    }

    return !props.inline ? (
        <>
            {props.label && (
                <Label
                    color={'black'}
                    inline={props.inline || false}
                    noMargin={props.inline}
                    for={props.label}
                >
                    {props.label}
                </Label>
            )}
            <span
                style={styles}
                className={[
                    classes.Text,
                    props.size ? classes[`Text--${props.size}`] : '',
                    props.weight ? classes[`Text--${props.weight}`] : '',
                    props.color ? classes[`Text--${props.color}`] : '',
                    props.wrap ? classes[`Text--wrap`] : '',
                ].join(' ')}
            >
                {props.children}
            </span>
        </>
    ) : (
        <FlexWrapper withMarginBottom={props.marginBottom > 0} alignItems={'center'}>
            {props.label && (
                <Label
                    color={'black'}
                    inline={props.inline || false}
                    noMargin={!props.inline}
                    for={props.label}
                >
                    {props.label}
                </Label>
            )}
            <span
                className={[
                    classes.Text,
                    props.size ? classes[`Text--${props.size}`] : '',
                    props.weight ? classes[`Text--${props.weight}`] : '',
                    props.color ? classes[`Text--${props.color}`] : '',
                    props.wrap ? classes[`Text--wrap`] : '',
                ].join(' ')}
            >
                {props.children}
            </span>
        </FlexWrapper>
    );
};

export default Text;
