import React from 'react';
import { ICampione } from '../../shared/interfaces';
import DetailsWrapper from '../DetailsWrapper';
import Text from '../UI/Text';
import NoDataText from '../NoDataText';
import {
    CampioneCircuito,
    CampioneClasse,
    CampioneProvenienza,
    CampioneTipologiaOsa,
} from '../../shared/enums';
import constants from '../../shared/constants';
import dayjs from 'dayjs';

interface IProps {
    item: ICampione;
    onSedeClicked?(id: string): void;
    onControlloClicked?(id: string): void;
}

const CampioneDetails = (props: IProps) => {
    const { item, onSedeClicked, onControlloClicked } = props;

    return (
        <DetailsWrapper
            actions={[
                {
                    label: 'Info Sede',
                    onClick: () => onSedeClicked(item.IdSede),
                },
                {
                    label: 'Info Controllo',
                    onClick: () => onControlloClicked(item.Campione.IdControllo),
                },
            ]}
            title={
                <Text size={'md'} weight={'medium'}>
                    {item.RagioneSociale}
                </Text>
            }
            subtitle={
                <Text inline={true} label={'ID Fiscale'}>
                    {item.IdentificativoFiscale}
                </Text>
            }
            tabs={{
                titles: ['Campione'],
                items: [
                    <div>
                        {item.Campione ? (
                            <article>
                                <Text inline={true} label={'Inizio:'}>
                                    {dayjs(item.Campione.DataInizio).format('DD/MM/YYYY HH:mm')}
                                </Text>
                                <Text inline={true} label={'Fine:'}>
                                    {dayjs(item.Campione.DataFine).format('DD/MM/YYYY HH:mm')}
                                </Text>
                                <Text marginBottom={10} inline={true}>
                                    {`${item.Campione.Elemento} - ${item.Campione.Laboratorio}`}
                                </Text>
                                <Text inline={true} label={'Classe:'}>
                                    {CampioneClasse[item.Campione.Classe]}
                                </Text>
                                <Text inline={true} label={'Provenienza:'}>
                                    {CampioneProvenienza[item.Campione.Provenienza]}
                                </Text>
                                {item.Campione.TipologiaOsa >= 0 &&
                                    CampioneTipologiaOsa[item.Campione.TipologiaOsa] && (
                                        <Text inline={true} label={'Tipologia OSA:'}>
                                            {
                                                constants.TIPOLOGIE_OSA[
                                                    CampioneTipologiaOsa[item.Campione.TipologiaOsa]
                                                ].label
                                            }
                                        </Text>
                                    )}
                                <Text inline={true} label={'Circuito:'}>
                                    {CampioneCircuito[item.Campione.Circuito]}
                                </Text>
                            </article>
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                ],
            }}
        />
    );
};

export default CampioneDetails;
