import * as actionTypes from './actionTypes';
import AtecoService from '../../services/api/ateco';
import { IAteco } from '../../shared/interfaces';

// FETCH ATECO BY KEYWORD
export const fetchAtecoByKeywordStart = () => {
    return {
        type: actionTypes.FETCH_ATECO_BY_KEYWORD_START,
    };
};

export const fetchAtecoByKeywordSuccess = (data: Array<IAteco>) => {
    return {
        type: actionTypes.FETCH_ATECO_BY_KEYWORD_SUCCESS,
        data,
    };
};

export const fetchAtecoByKeywordFail = (error: string) => {
    return {
        type: actionTypes.FETCH_ATECO_BY_KEYWORD_FAIL,
        error,
    };
};

export const fetchAtecoByKeyword = (keyword: string) => {
    return (dispatch: any) => {
        dispatch(fetchAtecoByKeywordStart());

        AtecoService.get(keyword).then(
            (data: Array<IAteco>) => {
                dispatch(fetchAtecoByKeywordSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchAtecoByKeywordFail(response.data.error));
            }
        );
    };
};
