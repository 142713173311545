import dayjs from 'dayjs';

export const isValidEmail = (email: string): boolean => {
    if (!email || email.trim() === '') {
        return false;
    }

    const regExp =
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const matcher = new RegExp(regExp);
    const keywordTest = email.trim();

    return matcher.test(keywordTest);
};

export const isValidSecureURL = (str: string) => {
    if (!str.trim().length) return false;

    const matcher = new RegExp(
        '^(https:\\/\\/www\\.|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
    );

    return matcher.test(str);
};

export const isValidURL = (str: string) => {
    const matcher = new RegExp(
        '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
    );

    return matcher.test(str);
};

export const getBrowserNavigatorLanguage = () =>
    navigator.language.toLowerCase().split(/[_-]+/)[0] || 'en';

export const randomId = (): string => {
    return '_' + Math.random().toString(36).substr(2, 18);
};

export const randomShortId = (): string => {
    return '_' + Math.random().toString(36).substr(2, 3);
};

export const getRandomArbitrary = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const compareStrings = (string1: string, string2: string) => {
    return string1 === string2;
};

export const updateObject = (oldState: any, updatedObject: any) => {
    return {
        ...oldState,
        ...updatedObject,
    };
};

export const getDateFromString = (date: string): Date | null => {
    const dayDate = dayjs(date).isValid() ? dayjs(date) : null;

    if (dayDate) {
        return new Date(+dayDate.format('YYYY'), +dayDate.format('M') - 1, +dayDate.format('D'));
    }

    return null;
};

export const sortByKeyProperty = (list: Array<any>, property: string): Array<any> => {
    return list.sort((a, b) => (a[property] > b[property] ? 1 : -1));
};

export const uniqueValuesFromArray = (values: Array<any>) =>
    values.filter((v, i) => values.indexOf(v) === i);

export const findMaxNumberValue = (values: Array<number>): number => {
    return Math.max.apply(null, values);
};

export const findMinNumberValue = (values: Array<number>): number => {
    return Math.min.apply(null, values);
};

export const setDocumentTitle = (title: string) => {
    if (!title) return null;

    document.title = title;
};

export const getKeysFromEnumValue = (enumValues: any, enumValue: number): Array<string> => {
    let test: Array<string> = [];

    for (let value in enumValues) {
        if (+value <= enumValue) {
            test.push(enumValues[+value]);
        }
    }

    console.log(test);

    return test.map((item: string) => item);
};

export const getStringsValuesFromEnumValue = (
    enumValues: any,
    enumValue: number,
    separator: string = ' '
): string => {
    let test: Array<string> = [];

    for (let value in enumValues) {
        if (+value <= enumValue) {
            test.push(enumValues[+value]);
        }
    }

    return test.map((item: string) => item).join(separator);
};

export const getNumberArrayFromCheckboxItem = (items: any): Array<number> => {
    return Object.keys(items)
        .filter((key: string) => items[key].checked)
        .map((obj, key) => {
            return +items[obj].value;
        });
};

export const getValueFromEnumArray = (values: Array<number>): number => {
    if (!values) return 0;
    return values.reduce((a: number, b: number) => a + b, 0);
};

export const getItalianPeriod = (period: string): string => {
    if (period.toLowerCase().includes('january')) {
        return period.replace('January', 'Gennaio');
    }
    if (period.toLowerCase().includes('february')) {
        return period.replace('February', 'Febbraio');
    }
    if (period.toLowerCase().includes('march')) {
        return period.replace('March', 'Marzo');
    }
    if (period.toLowerCase().includes('april')) {
        return period.replace('April', 'Aprile');
    }
    if (period.toLowerCase().includes('may')) {
        return period.replace('May', 'Maggio');
    }
    if (period.toLowerCase().includes('june')) {
        return period.replace('June', 'Giugno');
    }
    if (period.toLowerCase().includes('july')) {
        return period.replace('July', 'Luglio');
    }
    if (period.toLowerCase().includes('august')) {
        return period.replace('August', 'Agosto');
    }
    if (period.toLowerCase().includes('september')) {
        return period.replace('September', 'Settembre');
    }
    if (period.toLowerCase().includes('october')) {
        return period.replace('October', 'Ottobre');
    }
    if (period.toLowerCase().includes('november')) {
        return period.replace('November', 'Novembre');
    }
    if (period.toLowerCase().includes('december')) {
        return period.replace('December', 'Dicembre');
    }

    return 'Mese non trovato';
};
