import * as actionTypes from '../actions/actionTypes';
import { ISessionData } from '../../shared/interfaces';
import { checkSession } from '../../shared/helpers/session';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    session: ISessionData;
    error: string;
}

const INITIAL_STATE = {
    session: null,
    isFetching: false,
    didInvalidate: false,
    authenticated: checkSession(),
    logoutCompleted: false,
    error: null,
};

const signinStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        session: null,
        authenticated: false,
        error: null,
        didInvalidate: false,
        isFetching: true,
        logoutCompleted: false,
    });
};

const signinSuccess = (state = INITIAL_STATE, action: IAction) => {
    const authenticated = action.session !== undefined && action.session !== null;

    return updateObject(state, {
        session: action.session,
        authenticated,
        isFetching: false,
    });
};

const signinFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetching: false,
        didInvalidate: true,
    });
};

const resetSessionSuccess = (state = INITIAL_STATE) => {
    return updateObject(state, {
        session: null,
        authenticated: false,
        logoutCompleted: true,
        isFetching: false,
        error: null,
        didInvalidate: false,
    });
};

const sessionReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // SIGNIN
        case actionTypes.SIGNIN_START:
            return signinStart(state);
        case actionTypes.SIGNIN_SUCCESS:
            return signinSuccess(state, action);
        case actionTypes.SIGNIN_FAIL:
            return signinFail(state, action);

        // LOGOUT
        case actionTypes.LOGOUT_SUCCESS:
            return resetSessionSuccess(state);

        default:
            return state;
    }
};

export default sessionReducer;
