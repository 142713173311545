import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const exportData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number,
    motivo: number,
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    if (tipo) {
        qs += `&tipo=${tipo}`;
    }

    if (motivo) {
        qs += `&motivo=${motivo}`;
    }

    let endpoint = `/controlli/export/?${qs}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
        responseType: 'blob',
    });
};

const counter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number,
    motivo: number,
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=true`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    if (tipo) {
        qs += `&tipo=${tipo}`;
    }

    if (motivo) {
        qs += `&motivo=${motivo}`;
    }

    let endpoint = `/controlli?${qs}&pagina=0`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const get = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number,
    motivo: number,
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (masterList && masterList.length > 0) {
        masterList.forEach((id: string) => (qs += `&masterList=${id}`));
    }

    if (ateco && ateco.length > 0) {
        ateco.forEach((id: string) => (qs += `&ateco=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    if (tipo) {
        qs += `&tipo=${tipo}`;
    }

    if (motivo) {
        qs += `&motivo=${motivo}`;
    }

    let endpoint = `/controlli?${qs}&pagina=${page}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const getById = (id: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/controlli/byid/?id=${id}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const ControlloService = {
    counter,
    exportData,
    get,
    getById,
};

export default ControlloService;
