import React, { Component } from 'react';
import { IDecreto, ILaboratorioSede } from '../../shared/interfaces';
import columns from '../../shared/data/columns';
import { connect } from 'react-redux';
import DetailsWrapper from '../DetailsWrapper';
import Text from '../UI/Text';
import Label from '../UI/Label';
import FlexWrapper from '../UI/FlexWrapper';
import NoDataText from '../NoDataText';
import dayjs from 'dayjs';
import List from '../UI/List';
import { FormatoreTipoDecretoEnum } from '../../shared/enums';
import * as actions from '../../store/actions';
import IconButton from '../UI/IconButton';
import ModalWrapper from '../Modal';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../ConfirmDialog';
import LaboratorioDecretoForm from '../LaboratorioDecretoForm';
import LaboratorioSedeForm from '../LaboratorioSedeForm';

interface IProps {
    item: ILaboratorioSede;
    onRemoveDecreto(idDitta: string, idSede: string, id: string): void;
    lastRemovedDecreto: string;
}

interface IState {
    showSedeForm: boolean;
    showDecretoForm: boolean;
    selectedDecreto: IDecreto;
}

class LaboratorioDetails extends Component<IProps, IState> {
    state = {
        showSedeForm: false,
        showDecretoForm: false,
        selectedDecreto: null,
    };

    onToggleDecretoFormVisibility = () => {
        const showDecretoForm = !this.state.showDecretoForm;

        if (!showDecretoForm) {
            this.setState({
                selectedDecreto: null,
            });
        }

        this.setState({
            showDecretoForm,
        });
    };

    onToggleSedeFormVisibility = () => {
        const showSedeForm = !this.state.showSedeForm;

        this.setState({
            showSedeForm,
        });
    };

    onRemoveDecretoClicked = (id: string) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmDialog
                        onClose={onClose}
                        onConfirm={() =>
                            this.props.onRemoveDecreto(
                                this.props.item.Ditta.Id,
                                this.props.item.Id,
                                id
                            )
                        }
                        title={'Sei sicuro di voler rimuovere questo decreto?'}
                        subtitle={"L'operazione NON potrà essere annullata."}
                    />
                );
            },
        });
    };

    render() {
        const { item } = this.props;
        const { showSedeForm, showDecretoForm } = this.state;

        return (
            <>
                <DetailsWrapper
                    title={
                        <Text size={'md'} weight={'medium'}>
                            {item.Ditta.RagioneSociale}
                        </Text>
                    }
                    subtitle={
                        <>
                            <Text marginBottom={20} inline={true} label={'ID Fiscale'}>
                                {item.Ditta.IdentificativoFiscale}
                            </Text>
                            {item.NumeroIscrizione && (
                                <Text inline={true} label={'N° Iscrizione'}>
                                    {item.NumeroIscrizione}
                                </Text>
                            )}
                        </>
                    }
                    tabs={{
                        titles: [
                            `Sede`,
                            `Decreti (${item.Decreti ? item.Decreti.length : 0})`,
                            'Ditta',
                            'Titolarità',
                        ],
                        items: [
                            <>
                                <FlexWrapper withSections={true}>
                                    <section>
                                        <Label size={'md'} for={'sede legale'}>
                                            Indirizzo
                                        </Label>
                                        <FlexWrapper withSeparator={true}>
                                            <Text weight={'medium'}>{item.Citta}</Text>
                                            <Text>{item.Cap}</Text>
                                        </FlexWrapper>
                                        <Text marginBottom={20}>{item.Indirizzo}</Text>
                                        <Label size={'md'} for={'sede legale'}>
                                            Responsabile
                                        </Label>
                                        {item.ResponsabileLaboratorio ? (
                                            <Text weight={'medium'}>
                                                {item.ResponsabileLaboratorio}
                                            </Text>
                                        ) : (
                                            <NoDataText />
                                        )}
                                    </section>
                                    <section>
                                        <div style={{ marginBottom: '20px' }}>
                                            {item.IsAccreditato ? (
                                                <Text color={'green'} size={'md'} weight={'medium'}>
                                                    Accreditata
                                                </Text>
                                            ) : (
                                                <Text size={'md'} color={'red'} weight={'medium'}>
                                                    NON accreditata
                                                </Text>
                                            )}
                                        </div>
                                        <Label size={'md'} for={'sede legale'}>
                                            Note accreditamento
                                        </Label>
                                        <Text marginBottom={20} weight={'medium'}>
                                            {item.AccreditamentoNote}
                                        </Text>
                                        {item.DataScadenzaPerAccreditamento && (
                                            <>
                                                <Label size={'md'} for={'sede legale'}>
                                                    Data scadenza accreditamento
                                                </Label>

                                                <Text weight={'medium'}>
                                                    {dayjs(
                                                        item.DataScadenzaPerAccreditamento
                                                    ).format('DD/MM/YYYY')}
                                                </Text>
                                            </>
                                        )}
                                    </section>
                                </FlexWrapper>
                                <FlexWrapper justifyFlexEnd={true}>
                                    <IconButton
                                        withMargin={true}
                                        size={'sm'}
                                        clicked={this.onToggleSedeFormVisibility}
                                        icon={'edit'}
                                    />
                                </FlexWrapper>
                            </>,
                            <div>
                                <List
                                    withMarginBottom={true}
                                    columns={columns.DECRETI_COLUMNS}
                                    items={item.Decreti.map((decreto: IDecreto) => {
                                        return (
                                            <>
                                                <div style={columns.DECRETI_COLUMNS[0].styles}>
                                                    <FlexWrapper>
                                                        <Text size={'sm'} weight={'medium'}>
                                                            {`${decreto.Numero}`}
                                                        </Text>
                                                    </FlexWrapper>
                                                    <Text size={'sm'}>
                                                        {dayjs(decreto.Data).format('DD/MM/YYYY')}
                                                    </Text>
                                                </div>
                                                <div style={columns.DECRETI_COLUMNS[1].styles}>
                                                    <Text size={'sm'}>
                                                        {
                                                            FormatoreTipoDecretoEnum[
                                                                decreto.TipoDecreto
                                                            ]
                                                        }
                                                    </Text>
                                                </div>
                                                <div style={columns.DECRETI_COLUMNS[2].styles}>
                                                    {decreto.Url ? (
                                                        <>
                                                            <a href={decreto.Url} download>
                                                                {'Scarica'}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <NoDataText />
                                                    )}
                                                </div>
                                                <div style={columns.LABORATORI_COLUMNS[3].styles}>
                                                    <FlexWrapper justifyFlexEnd={true}>
                                                        <IconButton
                                                            size={'sm'}
                                                            withMargin={true}
                                                            theme={'danger'}
                                                            clicked={() =>
                                                                this.onRemoveDecretoClicked(
                                                                    decreto.Id
                                                                )
                                                            }
                                                            icon={'trash'}
                                                        />
                                                        <IconButton
                                                            size={'sm'}
                                                            clicked={() =>
                                                                this.setState(
                                                                    {
                                                                        selectedDecreto: decreto,
                                                                    },
                                                                    () => {
                                                                        this.onToggleDecretoFormVisibility();
                                                                    }
                                                                )
                                                            }
                                                            icon={'edit'}
                                                        />
                                                    </FlexWrapper>
                                                </div>
                                            </>
                                        );
                                    })}
                                />
                                <FlexWrapper justifyFlexEnd={true}>
                                    <IconButton
                                        theme={'info'}
                                        withMargin={true}
                                        size={'sm'}
                                        clicked={this.onToggleDecretoFormVisibility}
                                        icon={'plus'}
                                    />
                                </FlexWrapper>
                            </div>,
                            <div>
                                <Label size={'md'} for={'sede legale'}>
                                    Sede legale
                                </Label>
                                <FlexWrapper withSeparator={true}>
                                    <Text weight={'medium'}>{item.Ditta.Citta}</Text>
                                    <Text>{item.Ditta.Cap}</Text>
                                </FlexWrapper>
                                <Text marginBottom={20}>{item.Ditta.Indirizzo}</Text>
                                <Label size={'md'} for={'altri dati'}>
                                    Altri dati
                                </Label>
                                {item.Ditta.Pec ? (
                                    <FlexWrapper withSeparator={true}>
                                        <Text weight={'medium'}>{'PEC:'}</Text>
                                        <Text>{item.Ditta.Pec}</Text>
                                    </FlexWrapper>
                                ) : (
                                    <NoDataText />
                                )}
                            </div>,
                            <div>
                                <Label size={'md'} for={'legaleRappresentante'}>
                                    Legale rappresentante
                                </Label>
                                {item.Ditta.LegaleRappresentante ? (
                                    <FlexWrapper withSeparator={true} withMarginBottom={true}>
                                        <Text>{item.Ditta.LegaleRappresentante.Cognome}</Text>
                                        <Text>{item.Ditta.LegaleRappresentante.Nome}</Text>
                                        <Text weight={'medium'}>
                                            {item.Ditta.LegaleRappresentante.CodiceFiscale}
                                        </Text>
                                        <Text wrap={true}>
                                            {dayjs(
                                                item.Ditta.LegaleRappresentante.DataNascita
                                            ).format('DD/MM/YYYY')}
                                        </Text>
                                    </FlexWrapper>
                                ) : (
                                    <NoDataText />
                                )}
                            </div>,
                        ],
                    }}
                />
                <ModalWrapper
                    title={'Modifica sede laboratorio'}
                    visible={showSedeForm}
                    onCloseClicked={this.onToggleSedeFormVisibility}
                >
                    <LaboratorioSedeForm
                        onCloseModal={this.onToggleSedeFormVisibility}
                        currentData={item}
                    />
                </ModalWrapper>
                <ModalWrapper
                    title={this.state.selectedDecreto ? 'Modifica decreto' : 'Crea decreto'}
                    visible={showDecretoForm}
                    onCloseClicked={this.onToggleDecretoFormVisibility}
                >
                    <LaboratorioDecretoForm
                        onCloseModal={this.onToggleDecretoFormVisibility}
                        currentData={this.state.selectedDecreto}
                        IdLabAnalisiDitta={item.IdLabAnalisiDitta}
                        idDitta={item.Ditta.Id}
                        idSede={item.Id}
                    />
                </ModalWrapper>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        item: state.laboratorioState.selected,
        waitingForItem:
            state.laboratorioState.isFetchingItem || state.laboratorioState.isRemovingDecreto,
        error: state.laboratorioState.error,
        lastRemovedSede: state.laboratorioState.lastRemovedSede,
        lastRemovedDecreto: state.laboratorioState.lastRemovedDecreto,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onRemoveDecreto: (idDitta: string, idSede: string, id: string) =>
            dispatch(actions.removeDecretoSedeLaboratorio(idDitta, idSede, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaboratorioDetails);
