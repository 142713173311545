import * as actionTypes from '../actions/actionTypes';
import { ICitta } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    data: Array<ICitta>;
    error: string;
}

const INITIAL_STATE = {
    list: [],
    isFetching: false,
    didInvalidate: false,
    error: null,
};

const fetchCittaByKeywordStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        error: null,
        didInvalidate: false,
        isFetching: true,
    });
};

const fetchCittaByKeywordSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.data,
        isFetching: false,
    });
};

const fetchCittaByKeywordFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetching: false,
        didInvalidate: true,
    });
};

const cittaReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_CITTA_BY_KEYWORD_START:
            return fetchCittaByKeywordStart(state);
        case actionTypes.FETCH_CITTA_BY_KEYWORD_SUCCESS:
            return fetchCittaByKeywordSuccess(state, action);
        case actionTypes.FETCH_CITTA_BY_KEYWORD_FAIL:
            return fetchCittaByKeywordFail(state, action);

        default:
            return state;
    }
};

export default cittaReducer;
