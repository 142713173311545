import React, { Component, ReactNode } from 'react';
import classes from './styles.module.scss';
import { IColumn } from '../../../shared/interfaces';
import NoDataText from '../../NoDataText';

interface IProps {
    columns: Array<IColumn>;
    items: Array<ReactNode>;
    withMarginBottom?: boolean;
}

class List extends Component<IProps> {
    render() {
        const { items, columns, withMarginBottom } = this.props;

        return (
            <section
                className={[
                    classes.List,
                    withMarginBottom ? classes['List--withMarginBottom'] : '',
                ].join(' ')}
            >
                <div className={classes['List-header']}>
                    {columns.map((column: IColumn) => (
                        <div style={column.styles} key={column.key}>
                            {column.label}
                        </div>
                    ))}
                </div>
                <div className={classes['List-body']}>
                    {items && items.length > 0 ? (
                        items.map((item: ReactNode, index: number) => (
                            <div key={index} className={classes['List-row']}>
                                {item}
                            </div>
                        ))
                    ) : (
                        <div className={classes['List-row']}>
                            <div>
                                <NoDataText />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

export default List;
