import React, { Component } from 'react';
import { History } from 'history';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routes from '../../shared/routes';
import classes from './styles.module.scss';
import Field from '../Field';
import { IField, ISessionData, ISigninData } from '../../shared/interfaces';
import Loader from '../UI/Loader';
import { Formik } from 'formik';
import Button from '../UI/Button';

interface IProps {
    isAuthenticated?: boolean;
    waitingForSignin?: boolean;
    sessionError?: string;
    resetUserError?: string;
    onSignin(data: ISigninData): ISessionData;
    history: History;
    session: ISessionData;
}

interface IState {
    errorAuth: string;
    data: {
        username: string;
        password: string;
    };
    fields: {
        username: IField;
        password: IField;
    };
}

class SigninForm extends Component<IProps, IState> {
    state = {
        errorAuth: null,
        data: {
            username: '',
            password: '',
        },
        fields: {
            username: {
                name: 'username',
                type: 'text',
                placeholder: 'Username',
                label: 'Username',
            },
            password: {
                name: 'password',
                type: 'password',
                placeholder: 'Password',
                label: 'Password',
            },
        },
    };

    onHandleSubmit = (values, { setSubmitting }) => {
        this.setState({
            errorAuth: null,
        });

        const {
            REACT_APP_DEMETRA_CLIENT_ID,
            REACT_APP_DEMETRA_CLIENT_SECRET,
            REACT_APP_DEMETRA_SCOPE,
            REACT_APP_DEMETRA_GRANT_TYPE,
        } = process.env;

        const model = {
            client_id: REACT_APP_DEMETRA_CLIENT_ID,
            client_secret: REACT_APP_DEMETRA_CLIENT_SECRET,
            scope: REACT_APP_DEMETRA_SCOPE,
            grant_type: REACT_APP_DEMETRA_GRANT_TYPE,
            username: values.username,
            password: values.password,
        };

        this.props.onSignin(model);
    };

    onValidate = (values) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = 'Campo obbligatorio';
        }
        if (!values.password) {
            errors.password = 'Campo obbligatorio';
        }
        return errors;
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.session !== prevProps.session && this.props.session) {
            this.props.history.push(routes.ANAGRAFICA);
        }

        if (this.props.sessionError !== prevProps.sessionError && this.props.sessionError) {
            this.setState({
                errorAuth: this.props.sessionError,
            });
        }
    }

    render() {
        const { data, fields, errorAuth } = this.state;

        const { waitingForSignin } = this.props;

        return (
            <div className={classes.SigninForm}>
                <div className={classes['SigninForm-wrapper']}>
                    <Formik
                        initialValues={data}
                        validate={this.onValidate}
                        onSubmit={this.onHandleSubmit}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form noValidate={true} onSubmit={handleSubmit}>
                                <section className={classes['SigninForm-content']}>
                                    {Object.keys(fields).map((fieldName, index) => {
                                        return (
                                            <Field
                                                textAlign={'center'}
                                                key={index}
                                                name={fields[fieldName].name}
                                                type={fields[fieldName].type}
                                                changed={handleChange}
                                                blurred={handleBlur}
                                                value={values[fields[fieldName].name]}
                                                placeholder={fields[fieldName].placeholder}
                                                error={
                                                    errors[fields[fieldName].name] &&
                                                    touched[fields[fieldName].name] &&
                                                    errors[fields[fieldName].name]
                                                }
                                            />
                                        );
                                    })}
                                </section>
                                <p
                                    className={[
                                        classes['SigninForm-error'],
                                        errorAuth ? classes['is-visible'] : '',
                                    ].join(' ')}
                                >
                                    {errorAuth}
                                </p>
                                <footer className={classes['SigninForm-footer']}>
                                    <Button
                                        size={'lg'}
                                        weight={'bold'}
                                        type={'submit'}
                                        disabled={
                                            waitingForSignin ||
                                            values.password.trim().length === 0 ||
                                            values.username.trim().length === 0
                                        }
                                    >
                                        {'Accedi'}
                                    </Button>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </div>
                {waitingForSignin && <Loader fullPage={true} size={'md'} />}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        waitingForSignin: state.sessionState.isFetching,
        sessionError: state.sessionState.error,
        isAuthenticated: state.sessionState.authenticated,
        session: state.sessionState.session,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignin: (data: ISigninData) => dispatch(actions.signin(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SigninForm));
