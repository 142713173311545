import React from 'react';
import { IAzione, ICampioneControllo, IControllo } from '../../shared/interfaces';
import DetailsWrapper from '../DetailsWrapper';
import Text from '../UI/Text';
import NoDataText from '../NoDataText';
import {
    AzioneInfrazioniEnum,
    AzioneTipoEnum,
    CampioneCircuito,
    CampioneClasse,
    CampioneProvenienza,
    CampioneTipologiaOsa,
    ControlloMotivo,
    ControlloTipo,
} from '../../shared/enums';
import {
    getKeysFromEnumValue,
    getStringsValuesFromEnumValue,
} from '../../shared/helpers/utilities';
import constants from '../../shared/constants';
import dayjs from 'dayjs';

interface IProps {
    item: IControllo;
}

const ControlloDetails = (props: IProps) => {
    const { item } = props;

    return (
        <DetailsWrapper
            title={
                <Text size={'md'} weight={'medium'}>
                    {item.RagioneSociale}
                </Text>
            }
            subtitle={
                <Text inline={true} label={'ID Fiscale'}>
                    {item.IdentificativoFiscale}
                </Text>
            }
            tabs={{
                titles: ['Controllo', 'Campioni', 'Azioni'],
                items: [
                    <div>
                        <Text marginBottom={10} inline={true} label={'Inizio:'}>
                            {dayjs(item.Controllo.DataInizio).format('DD/MM/YYYY HH:mm')}
                        </Text>
                        <Text marginBottom={10} inline={true} label={'Fine:'}>
                            {dayjs(item.Controllo.DataFine).format('DD/MM/YYYY HH:mm')}
                        </Text>
                        <Text marginBottom={10} inline={true} label={'Tipo:'}>
                            {getStringsValuesFromEnumValue(ControlloTipo, item.Tipo, ', ')}
                        </Text>
                        <Text inline={true} label={'Motivo:'}>
                            {getStringsValuesFromEnumValue(ControlloMotivo, item.Motivo, ', ')}
                        </Text>
                    </div>,
                    <div>
                        {item.Controllo &&
                        item.Controllo.Campioni &&
                        item.Controllo.Campioni.length > 0 ? (
                            item.Controllo.Campioni.map((data: ICampioneControllo) => (
                                <article key={data.Id}>
                                    <Text inline={true} label={'Inizio:'}>
                                        {dayjs(data.DataInizio).format('DD/MM/YYYY HH:mm')}
                                    </Text>
                                    <Text inline={true} label={'Fine:'}>
                                        {dayjs(data.DataFine).format('DD/MM/YYYY HH:mm')}
                                    </Text>
                                    <Text marginBottom={10} inline={true}>
                                        {`${data.Elemento} - ${data.Laboratorio}`}
                                    </Text>
                                    <Text inline={true} label={'Classe:'}>
                                        {CampioneClasse[data.Classe]}
                                    </Text>
                                    <Text inline={true} label={'Provenienza:'}>
                                        {CampioneProvenienza[data.Provenienza]}
                                    </Text>
                                    {data.TipologiaOsa >= 0 &&
                                        CampioneTipologiaOsa[data.TipologiaOsa] && (
                                            <Text inline={true} label={'Tipologia OSA:'}>
                                                {
                                                    constants.TIPOLOGIE_OSA[
                                                        CampioneTipologiaOsa[data.TipologiaOsa]
                                                    ].label
                                                }
                                            </Text>
                                        )}
                                    <Text inline={true} label={'Circuito:'}>
                                        {CampioneCircuito[data.Circuito]}
                                    </Text>
                                </article>
                            ))
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                    <div>
                        {item.Controllo &&
                        item.Controllo.Azioni &&
                        item.Controllo.Azioni.length > 0 ? (
                            item.Controllo.Azioni.map((data: IAzione) => (
                                <article key={data.Id}>
                                    {data.Infrazioni >= 0 && (
                                        <Text inline={true} label={'Infrazioni:'}>
                                            {getKeysFromEnumValue(
                                                AzioneInfrazioniEnum,
                                                data.Infrazioni
                                            )
                                                .map(
                                                    (item: string) =>
                                                        constants.INFRAZIONI[item].label
                                                )
                                                .join(', ')}
                                        </Text>
                                    )}
                                    {data.Tipo >= 0 && (
                                        <Text inline={true} label={'Tipo:'}>
                                            {getStringsValuesFromEnumValue(
                                                AzioneTipoEnum,
                                                data.Tipo
                                            )}
                                        </Text>
                                    )}
                                </article>
                            ))
                        ) : (
                            <NoDataText />
                        )}
                    </div>,
                ],
            }}
        />
    );
};

export default ControlloDetails;
