import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: string;
}

const ErrorFeedback = (props: IProps) => {
    return <section className={classes.ErrorFeedback}>{props.children}</section>;
};

export default ErrorFeedback;
