import * as actionTypes from './actionTypes';
import { IStats } from '../../shared/interfaces';
import StatsService from '../../services/api/stats';

// FETCH
export const fetchStatsStart = () => {
    return {
        type: actionTypes.FETCH_STATS_START,
    };
};

export const fetchStatsSuccess = (data: Array<IStats>) => {
    return {
        type: actionTypes.FETCH_STATS_SUCCESS,
        stats: data,
    };
};

export const fetchStatsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_STATS_FAIL,
        error,
    };
};

export const fetchStats = (month: number = 12) => {
    return (dispatch: any) => {
        dispatch(fetchStatsStart());

        StatsService.get(month).then(
            (response: Array<IStats>) => {
                dispatch(fetchStatsSuccess(response));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchStatsFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetStats = () => {
    return {
        type: actionTypes.RESET_STATS,
    };
};
