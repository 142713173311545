import * as actionTypes from './actionTypes';
import { ISessionData, ISigninData } from '../../shared/interfaces';
import SessionService from '../../services/api/session';
import { resetSession, setSession } from '../../shared/helpers/session';

// SIGNIN
export const signinStart = () => {
    return {
        type: actionTypes.SIGNIN_START,
    };
};

export const signinSuccess = (session: ISessionData) => {
    return {
        type: actionTypes.SIGNIN_SUCCESS,
        session,
    };
};

export const signinFail = (error: string) => {
    resetSession();

    return {
        type: actionTypes.SIGNIN_FAIL,
        error,
    };
};

export const signin = (data: ISigninData) => {
    return (dispatch: any) => {
        dispatch(signinStart());

        SessionService.signin(data).then(
            (session: ISessionData) => {
                setSession(session);

                dispatch(signinSuccess(session));
            },
            (response: { data: { error: string } }) => {
                dispatch(signinFail(response.data.error));
            }
        );
    };
};

export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS,
    };
};

export const logout = () => {
    return (dispatch: any) => {
        resetSession();

        dispatch(logoutSuccess());
    };
};
