import * as actionTypes from './actionTypes';
import { IDittaSede, IDittaSedeItem, IResponse } from '../../shared/interfaces';
import DittaSedeService from '../../services/api/dittaSede';

// FETCH
export const fetchDittaSediStart = (page: number) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_START,
        page,
    };
};

export const fetchDittaSediSuccess = (
    list: Array<IDittaSede>,
    count: number,
    totalPages: number
) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_SUCCESS,
        list,
        count,
        totalPages,
    };
};

export const fetchDittaSediFail = (error: string) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_FAIL,
        error,
    };
};

export const fetchDittaSedi = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null,
    page: number = 1
) => {
    return (dispatch: any) => {
        dispatch(fetchDittaSediStart(page));

        DittaSedeService.get(
            keyword,
            province,
            cities,
            hasMasterList,
            masterList,
            ateco,
            hasCategorizzazione,
            categorizzazioneMin,
            categorizzazioneMax,
            page
        ).then(
            (response: IResponse) => {
                dispatch(
                    fetchDittaSediSuccess(
                        response.Data,
                        response.DataCount || 0,
                        response.TotalPages || 1
                    )
                );
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchDittaSediFail(response.data.error));
            }
        );
    };
};

export const fetchDittaSediByIdStart = () => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_BY_ID_START,
    };
};

export const fetchDittaSediByIdSuccess = (item: IDittaSedeItem) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_BY_ID_SUCCESS,
        item,
    };
};

export const fetchDittaSediByIdFail = (error: string) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_BY_ID_FAIL,
        error,
    };
};

export const fetchDittaSediById = (id: string) => {
    return (dispatch: any) => {
        dispatch(fetchDittaSediByIdStart());

        DittaSedeService.getById(id).then(
            (data: IDittaSedeItem) => {
                dispatch(fetchDittaSediByIdSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchDittaSediByIdFail(response.data.error));
            }
        );
    };
};

// COUNTER
export const fetchDittaSediCounterStart = () => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_COUNTER_START,
    };
};

export const fetchDittaSediCounterSuccess = (count: number) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_COUNTER_SUCCESS,
        count,
    };
};

export const fetchDittaSediCounterFail = (error: string) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_COUNTER_FAIL,
        error,
    };
};

export const fetchDittaSediCounter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null
) => {
    return (dispatch: any) => {
        dispatch(fetchDittaSediCounterStart());

        DittaSedeService.counter(
            keyword,
            province,
            cities,
            hasMasterList,
            masterList,
            ateco,
            hasCategorizzazione,
            categorizzazioneMin,
            categorizzazioneMax
        ).then(
            (response: IResponse) => {
                dispatch(fetchDittaSediCounterSuccess(response.DataCount || 0));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchDittaSediCounterFail(response.data.error));
            }
        );
    };
};

// EXPORT
export const exportDittaSediDataStart = () => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_EXPORT_START,
    };
};

export const exportDittaSediDataSuccess = (stream: Blob) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_EXPORT_SUCCESS,
        stream,
    };
};

export const exportDittaSediDataFail = (error: string) => {
    return {
        type: actionTypes.FETCH_DITTA_SEDI_EXPORT_FAIL,
        error,
    };
};

export const exportDittaSediData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    hasMasterList: boolean | null = null,
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    hasCategorizzazione: boolean | null = null,
    categorizzazioneMin: number | null = null,
    categorizzazioneMax: number | null = null
) => {
    return (dispatch: any) => {
        dispatch(exportDittaSediDataStart());

        DittaSedeService.exportData(
            keyword,
            province,
            cities,
            hasMasterList,
            masterList,
            ateco,
            hasCategorizzazione,
            categorizzazioneMin,
            categorizzazioneMax
        ).then(
            (stream: Blob) => {
                dispatch(exportDittaSediDataSuccess(stream));
            },
            (response: { data: { error: string } }) => {
                dispatch(exportDittaSediDataFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetDittaSedi = () => {
    return {
        type: actionTypes.RESET_DITTA_SEDI,
    };
};
