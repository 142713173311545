import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import * as QueryString from 'query-string';
import {
    IDittaSede,
    IDittaSedeItem,
    IDittaSedeSearchParams,
    IProvinceList,
    ISelectOption,
} from '../../shared/interfaces';
import Field from '../../components/Field';
import Loader from '../../components/UI/Loader';
import CitySelect from '../../components/CitySelect';
import AtecoSelect from '../../components/AtecoSelect';
import MasterListSelect from '../../components/MasterListSelect';
import CheckBox from '../../components/UI/Checkbox';
import constants from '../../shared/constants';
import tables from '../../shared/data/tables';
import Question from '../../components/UI/Question';
import Filters from '../../components/Filters';
import Grid from '../../components/Grid';
import { createFile, getAnagraficaRows } from '../../shared/helpers/dataRows';
import Tabs from '../../components/Tabs';
import FlexWrapper from '../../components/UI/FlexWrapper';
import ModalWrapper from '../../components/Modal';
import CounterWrapper from '../../components/CounterWrapper';
import SedeDetails from '../../components/SedeDetails';
import Text from '../../components/UI/Text';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
    location: { search: string };
    list: Array<IDittaSede>;
    waitingForList: boolean;
    error: any;
    onGetList(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        hasMasterList: boolean,
        masterList: Array<string>,
        ateco: Array<string>,
        hasCategorizzazione: boolean,
        categorizzazioneMin: number,
        categorizzazioneMax: number,
        page: number
    ): Array<IDittaSede>;
    onResetData(): void;
    waitingForItem: boolean;
    onGetItem(id: string): IDittaSedeItem;
    totalPages: number;
    totalRecords: number;
    item: IDittaSedeItem;
    onGetCounter(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        hasMasterList: boolean,
        masterList: Array<string>,
        ateco: Array<string>,
        hasCategorizzazione: boolean,
        categorizzazioneMin: number,
        categorizzazioneMax: number
    ): number;
    showCounter: boolean;
    onExportData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        hasMasterList: boolean,
        masterList: Array<string>,
        ateco: Array<string>,
        hasCategorizzazione: boolean,
        categorizzazioneMin: number,
        categorizzazioneMax: number
    ): number;
    waitingForFileCreation: boolean;
    stream: ArrayBuffer | null;
}

interface IState {
    searchParams: IDittaSedeSearchParams;
    selected: {
        cities: Array<ISelectOption>;
        ateco: Array<ISelectOption>;
        masterList: Array<ISelectOption>;
    };
    mlClassified: {
        yes: boolean;
        no: boolean;
    };
    hasCatRischio: {
        yes: boolean;
        no: boolean;
    };
    province: IProvinceList;
    showDetailsModal: boolean;
    filterCityFromQS: {
        citta: string | null;
        id: string | null;
    };
}

class Anagrafica extends Component<IProps & RouteComponentProps, IState> {
    state = {
        searchParams: {
            keyword: '',
            catMin: null,
            catMax: null,
            page: 1,
        },
        selected: {
            cities: [],
            ateco: [],
            masterList: [],
        },
        mlClassified: {
            yes: false,
            no: false,
        },
        hasCatRischio: {
            yes: false,
            no: false,
        },
        province: constants.PROVINCE,
        showDetailsModal: false,
        filterCityFromQS: {
            citta: null,
            id: null,
        },
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onResetData();

        const {
            location: { search },
        } = this.props;

        if (search) {
            const params = QueryString.parse(search);

            let filterCityFromQS = {
                id: typeof params.idCitta === 'string' ? params.idCitta : null,
                citta: typeof params.citta === 'string' ? params.citta : null,
            };

            this.setState(
                {
                    filterCityFromQS,
                },
                () => {
                    this.onSearchData();
                }
            );
        }
    }

    handleSelectedOptions = (name: string, selected: Array<ISelectOption> | ISelectOption) => {
        let newSelected = {
            ...this.state.selected,
            [name]: selected || [],
        };

        this.setState(
            {
                selected: newSelected,
            },
            () => {
                this.resetPaging();
            }
        );
    };

    loadMore = () => {
        let searchParams = {
            ...this.state.searchParams,
            page: this.state.searchParams.page + 1,
        };
        this.setState({ searchParams }, () => {
            this.onSearchData();
        });
    };

    handleFieldChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value =
            e.currentTarget.type === 'text'
                ? e.currentTarget.value
                : +e.currentTarget.value > 100
                ? 100
                : +e.currentTarget.value;
        const name = e.currentTarget.name;

        let searchParams = {
            ...this.state.searchParams,
            [name]: value,
        };

        this.setState(
            {
                searchParams,
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onHasCatRischioChanged = (name: string, checked: boolean) => {
        let hasCatRischio = {
            ...this.state.hasCatRischio,
            [name]: checked,
        };

        if (name === 'yes' && hasCatRischio.no) {
            hasCatRischio = {
                ...this.state.hasCatRischio,
                no: false,
            };
        }

        if (name === 'no' && hasCatRischio.yes) {
            hasCatRischio = {
                ...this.state.hasCatRischio,
                yes: false,
            };
        }

        this.setState(
            {
                hasCatRischio: {
                    ...hasCatRischio,
                    [name]: checked,
                },
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onClassifiedChanged = (name: string, checked: boolean) => {
        let mlClassified = {
            ...this.state.mlClassified,
            [name]: checked,
        };

        if (name === 'yes' && mlClassified.no) {
            mlClassified = {
                ...this.state.mlClassified,
                no: false,
            };
        }

        if (name === 'no' && mlClassified.yes) {
            mlClassified = {
                ...this.state.mlClassified,
                yes: false,
            };
        }

        this.setState(
            {
                mlClassified: {
                    ...mlClassified,
                    [name]: checked,
                },
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onSelectProv = (prov: string, checked: boolean) => {
        let province = {
            ...this.state.province,
            [prov]: {
                ...this.state.province[prov],
                checked,
            },
        };

        this.setState(
            {
                province,
            },
            () => {
                this.resetPaging();
            }
        );
    };

    onExportClicked = () => {
        const {
            searchParams: { keyword, catMin, catMax },
            selected: { masterList, ateco, cities },
            mlClassified,
            hasCatRischio,
            province,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const mlClassifiedFilter = mlClassified.yes || mlClassified.no ? mlClassified.yes : null;
        const hasCatRischioFilter =
            hasCatRischio.yes || hasCatRischio.no ? hasCatRischio.yes : null;

        setTimeout(() => {
            this.props.onExportData(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                mlClassifiedFilter,
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                hasCatRischioFilter,
                catMin,
                catMax
            );
        }, 300);
    };

    onGetCounterClicked = () => {
        this.props.onResetData();

        const {
            searchParams: { keyword, catMin, catMax },
            selected: { masterList, ateco, cities },
            mlClassified,
            hasCatRischio,
            province,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const mlClassifiedFilter = mlClassified.yes || mlClassified.no ? mlClassified.yes : null;
        const hasCatRischioFilter =
            hasCatRischio.yes || hasCatRischio.no ? hasCatRischio.yes : null;

        setTimeout(() => {
            this.props.onGetCounter(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                mlClassifiedFilter,
                masterList.map((item) => item.value),
                ateco.map((item) => item.value),
                hasCatRischioFilter,
                catMin,
                catMax
            );
        }, 300);
    };

    onSubmitClicked = () => {
        this.props.onResetData();

        setTimeout(() => {
            this.onSearchData();
        }, 300);
    };

    onSearchData = () => {
        const {
            searchParams: { keyword, catMin, catMax, page },
            selected: { masterList, ateco, cities },
            mlClassified,
            hasCatRischio,
            province,
            filterCityFromQS,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);
        const mlClassifiedFilter = mlClassified.yes || mlClassified.no ? mlClassified.yes : null;
        const hasCatRischioFilter =
            hasCatRischio.yes || hasCatRischio.no ? hasCatRischio.yes : null;

        this.props.onGetList(
            keyword,
            provFilter,
            filterCityFromQS.id ? [filterCityFromQS.id] : cities.map((item) => item.value),
            mlClassifiedFilter,
            masterList.map((item) => item.value),
            ateco.map((item) => item.value),
            hasCatRischioFilter,
            catMin,
            catMax,
            page
        );
    };

    resetFilters = () => {
        let resetRoute = this.state.filterCityFromQS.id !== null;

        const searchParams = {
            keyword: '',
            catMin: null,
            catMax: null,
            page: 1,
        };
        const selected = {
            cities: [],
            ateco: [],
            masterList: [],
        };

        const filterCityFromQS = {
            id: null,
            citta: null,
        };

        this.setState({
            filterCityFromQS,
        });

        const mlClassified = {
            yes: false,
            no: false,
        };

        const hasCatRischio = {
            yes: false,
            no: false,
        };

        const province = constants.PROVINCE;

        this.setState(
            {
                searchParams,
                selected,
                mlClassified,
                hasCatRischio,
                province,
                filterCityFromQS,
            },
            () => {
                this.props.onResetData();

                if (resetRoute) {
                    this.props.history.push('/anagrafica');
                }
            }
        );
    };

    resetPaging = () => {
        const searchParams = {
            ...this.state.searchParams,
            page: 1,
        };

        this.setState({ searchParams }, () => {
            this.props.onResetData();
        });
    };

    onRowSelected = (data: Array<any>) => {
        //console.log('Row selected', data[0]);
        this.onToggleModalVisibility();
        if (data && data.length > 0) {
            this.props.onGetItem(data[0].id);
        }
    };

    onToggleModalVisibility = () => {
        const showDetailsModal = !this.state.showDetailsModal;

        this.setState({ showDetailsModal });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.stream !== prevProps.stream && this.props.stream) {
            createFile(this.props.stream, 'Elenco_sedi_operative', 'xlsx', true);
        }
    }

    render() {
        const {
            searchParams: { keyword, catMin, catMax },
            mlClassified,
            selected,
            province,
            showDetailsModal,
            hasCatRischio,
            filterCityFromQS,
        } = this.state;

        const {
            waitingForList,
            list,
            totalRecords,
            waitingForItem,
            item,
            showCounter,
            waitingForFileCreation,
            isLastPage,
        } = this.props;

        const provinceTpl = Object.keys(province).map((prov: string) => {
            return (
                <CheckBox
                    key={prov}
                    checked={province[prov].checked}
                    changed={(checked: boolean) => this.onSelectProv(prov, checked)}
                >
                    {province[prov].label}
                </CheckBox>
            );
        });

        return (
            <AuthWrapper>
                <main className={classes.Anagrafica}>
                    <Title>{'Anagrafica'}</Title>
                    <Filters
                        onSubmitClicked={this.onSubmitClicked}
                        onResetClicked={this.resetFilters}
                        onGetCounter={this.onGetCounterClicked}
                        onExportClicked={this.onExportClicked}
                        undoLabel={'Reset filtri'}
                        submitLabel={'Ricerca'}
                    >
                        <Tabs
                            tabs={{
                                titles: ['Generico', 'Posizione', 'Attività'],
                                items: [
                                    <div>
                                        <Field
                                            label={'Ricerca per Ragione Sociale, ID Fiscale o PEC'}
                                            noMargin={true}
                                            name={'keyword'}
                                            type={'text'}
                                            changed={this.handleFieldChanged}
                                            value={keyword}
                                            placeholder={'Ricerca...'}
                                        />
                                    </div>,
                                    <>
                                        <div>
                                            {filterCityFromQS.id ? (
                                                <Text
                                                    marginBottom={20}
                                                    label={'Città preselezionata'}
                                                >
                                                    {filterCityFromQS.citta}
                                                </Text>
                                            ) : (
                                                <CitySelect
                                                    label={'Ricerca per città'}
                                                    name={'cities'}
                                                    selected={selected.cities}
                                                    onSelectedChanged={(
                                                        values: Array<ISelectOption>
                                                    ) =>
                                                        this.handleSelectedOptions('cities', values)
                                                    }
                                                />
                                            )}
                                        </div>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Province'}
                                            name={'province'}
                                        >
                                            {provinceTpl}
                                        </FlexWrapper>
                                    </>,
                                    <>
                                        <div>
                                            <AtecoSelect
                                                label={'Ricerca per Ateco'}
                                                name={'ateco'}
                                                selected={selected.ateco}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('ateco', values)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <MasterListSelect
                                                label={'Ricerca per Master List'}
                                                name={'masterList'}
                                                selected={selected.masterList}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('masterList', values)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <Question
                                                answerChanged={this.onClassifiedChanged}
                                                values={mlClassified}
                                                label={'Classificato Master List'}
                                            />
                                        </div>
                                        <div>
                                            <Question
                                                answerChanged={this.onHasCatRischioChanged}
                                                values={hasCatRischio}
                                                label={'Categorizzazione rischio presente'}
                                            />
                                        </div>
                                        <div>
                                            <FlexWrapper noMargin={true} withFields={true}>
                                                <Field
                                                    label={'Cat. Min.'}
                                                    noMargin={true}
                                                    name={'catMin'}
                                                    type={'number'}
                                                    changed={this.handleFieldChanged}
                                                    value={catMin || ''}
                                                    placeholder={'Min.'}
                                                    min={0}
                                                    max={100}
                                                />
                                                <Field
                                                    label={'Cat. Max.'}
                                                    noMargin={true}
                                                    name={'catMax'}
                                                    type={'number'}
                                                    changed={this.handleFieldChanged}
                                                    value={catMax || ''}
                                                    placeholder={'Max'}
                                                    min={catMin || 0}
                                                    max={100}
                                                />
                                            </FlexWrapper>
                                        </div>
                                    </>,
                                ],
                            }}
                        />
                    </Filters>
                    {waitingForFileCreation && (
                        <Loader label={'Attendere qualche secondo...'} fullPage={true} />
                    )}
                    {list && list.length > 0 && (
                        <Grid
                            rowHeight={200}
                            currentNumberOfRecords={list.length}
                            totalRecords={totalRecords}
                            rows={getAnagraficaRows(list)}
                            columns={tables.ANAGRAFICA}
                            onShowMoreClicked={this.loadMore}
                            loading={waitingForList}
                            onRowSelected={this.onRowSelected}
                            isLastPage={isLastPage}
                        />
                    )}
                    {showCounter && !waitingForList && !waitingForItem && (
                        <CounterWrapper value={totalRecords} label={'Record trovati'} />
                    )}
                    {waitingForList && !list.length && (
                        <section className={classes['Anagrafica-loading']}>
                            <Loader center={true} fullContent={true} />
                        </section>
                    )}
                    <ModalWrapper
                        title={'Dettaglio OSA'}
                        visible={showDetailsModal}
                        onCloseClicked={() => this.setState({ showDetailsModal: false })}
                    >
                        {waitingForItem && <Loader center={true} fullContent={true} />}
                        {item && <SedeDetails item={item} />}
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        list: state.dittaSedeState.list,
        item: state.dittaSedeState.selected,
        waitingForList: state.dittaSedeState.isFetchingList,
        waitingForItem: state.dittaSedeState.isFetchingItem,
        error: state.dittaSedeState.error,
        isLastPage: state.dittaSedeState.isLastPage,
        totalRecords: state.dittaSedeState.totalRecords,
        totalPages: state.dittaSedeState.totalPages,
        showCounter:
            state.dittaSedeState.totalRecords >= 0 &&
            state.dittaSedeState.onlyCounter &&
            !state.dittaSedeState.list.length,
        waitingForFileCreation: state.dittaSedeState.isCreatingFile,
        stream: state.dittaSedeState.stream,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetList: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            hasMasterList: boolean,
            masterList: Array<string>,
            ateco: Array<string>,
            hasCategorizzazione: boolean,
            categorizzazioneMin: number,
            categorizzazioneMax: number,
            page: number
        ) =>
            dispatch(
                actions.fetchDittaSedi(
                    keyword,
                    province,
                    cities,
                    hasMasterList,
                    masterList,
                    ateco,
                    hasCategorizzazione,
                    categorizzazioneMin,
                    categorizzazioneMax,
                    page
                )
            ),
        onResetData: () => dispatch(actions.resetDittaSedi()),
        onGetItem: (id: string) => dispatch(actions.fetchDittaSediById(id)),
        onGetCounter: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            hasMasterList: boolean,
            masterList: Array<string>,
            ateco: Array<string>,
            hasCategorizzazione: boolean,
            categorizzazioneMin: number,
            categorizzazioneMax: number
        ) =>
            dispatch(
                actions.fetchDittaSediCounter(
                    keyword,
                    province,
                    cities,
                    hasMasterList,
                    masterList,
                    ateco,
                    hasCategorizzazione,
                    categorizzazioneMin,
                    categorizzazioneMax
                )
            ),
        onExportData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            hasMasterList: boolean,
            masterList: Array<string>,
            ateco: Array<string>,
            hasCategorizzazione: boolean,
            categorizzazioneMin: number,
            categorizzazioneMax: number
        ) =>
            dispatch(
                actions.exportDittaSediData(
                    keyword,
                    province,
                    cities,
                    hasMasterList,
                    masterList,
                    ateco,
                    hasCategorizzazione,
                    categorizzazioneMin,
                    categorizzazioneMax
                )
            ),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Anagrafica));
