import request from '../../shared/lib/request';
import constants from '../../shared/constants';
import { IDecretoForm, IFormatoreForm, IFormatoreSedeForm } from '../../shared/interfaces';

const exportData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/formatori/export/?${qs}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
        responseType: 'blob',
    });
};

const counter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=true`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/formatori?${qs}&pagina=0`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const get = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = `isCountOnly=false`;

    if (keyword && keyword.trim().length > 0) {
        qs += `&stringaRicerca=${keyword}`;
    }

    if (province && province.length > 0) {
        province.forEach((pr: string) => (qs += `&provincia=${pr.toUpperCase()}`));
    }

    if (cities && cities.length > 0) {
        cities.forEach((id: string) => (qs += `&citta=${id}`));
    }

    if (dataInizio) {
        qs += `&dataInizio=${dataInizio}`;
    }

    if (dataFine) {
        qs += `&dataFine=${dataFine}`;
    }

    let endpoint = `/formatori?${qs}&pagina=${page}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const getById = (id: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${id}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const create = (data: IFormatoreForm, assegnaNumero: boolean) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = '';
    if (assegnaNumero) {
        qs = '?assegnaNumero=true';
    }

    let endpoint = `/formatori${qs}`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const update = (idFormatore: string, data: IFormatoreForm, isCorrezione: boolean) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let qs = '';

    if (isCorrezione) {
        qs = '?isCorrezione=true';
    }

    let endpoint = `/formatori/${idFormatore}${qs}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const remove = (idFormatore: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const assegnaNumero = (id: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${id}/assegnaNumero`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data: {},
    });
};

const getSedi = (idFormatore: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/sedi`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const createSede = (idFormatore: string, data: IFormatoreSedeForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/sedi`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const getSede = (idFormatore: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const removeSede = (idFormatore: string, idSede: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const updateSede = (idFormatore: string, idSede: string, data: IFormatoreSedeForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/sedi/${idSede}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const createDecreto = (idFormatore: string, data: IDecretoForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/decreti`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data,
    });
};

const getDecreto = (idFormatore: string, idDecreto: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers,
    });
};

const removeDecreto = (idFormatore: string, idDecreto: string) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'DELETE',
        headers,
    });
};

const updateDecreto = (idFormatore: string, idDecreto: string, data: IDecretoForm) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    let endpoint = `/formatori/${idFormatore}/decreti/${idDecreto}`;

    return request({
        url: endpoint,
        method: 'PUT',
        headers,
        data,
    });
};

const FormatoreService = {
    counter,
    exportData,
    get,
    create,
    update,
    remove,
    getById,
    assegnaNumero,
    getSedi,
    createSede,
    updateSede,
    removeSede,
    getSede,
    createDecreto,
    updateDecreto,
    removeDecreto,
    getDecreto,
};

export default FormatoreService;
