import React from 'react';
import Chart from 'react-google-charts';
import classes from './styles.module.scss';
import Loader from '../UI/Loader';

interface IProps {
    data: Array<Array<string | number>>;
}

const StatsChart = (props: IProps) => {
    return (
        <section className={classes.StatsChart}>
            <Chart
                width={1000}
                height={600}
                chartType="Bar"
                loader={<Loader fullContent={true} />}
                data={props.data}
                options={{
                    chartArea: { width: '80%' },
                    hAxis: {
                        minValue: 0,
                    },
                    vAxis: {
                        format: 'long',
                    },
                }}
                legendToggle
            />
        </section>
    );
};

export default StatsChart;
