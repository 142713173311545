import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from '../../shared/routes';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import {
    ILabAnalisi,
    ILaboratorioSearchParams,
    ILaboratorioSede,
    IProvinceList,
    ISelectOption,
} from '../../shared/interfaces';
import Field from '../../components/Field';
import Loader from '../../components/UI/Loader';
import CitySelect from '../../components/CitySelect';
import CheckBox from '../../components/UI/Checkbox';
import constants from '../../shared/constants';
import tables from '../../shared/data/tables';
import Filters from '../../components/Filters';
import DatesRange from '../../components/UI/DatesRange';
import Grid from '../../components/Grid';
import { createFile, getLaboratoriRows } from '../../shared/helpers/dataRows';
import Tabs from '../../components/Tabs';
import FlexWrapper from '../../components/UI/FlexWrapper';
import CounterWrapper from '../../components/CounterWrapper';
import Title from '../../components/UI/Title';
import IconButton from '../../components/UI/IconButton';
import ModalWrapper from '../../components/Modal';
import LaboratorioForm from '../../components/LaboratorioForm';
import dayjs from 'dayjs';

interface IProps {
    history: History;
    list: Array<ILabAnalisi>;
    waitingForList: boolean;
    error: any;
    onGetData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        dataInizio: string,
        dataFine: string,
        page: number
    ): Array<ILabAnalisi>;
    onResetData(): void;
    totalPages: number;
    totalRecords: number;
    onGetCounter(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        dataInizio: string,
        dataFine: string
    ): number;
    showCounter: boolean;
    onExportData(
        keyword: string,
        province: Array<string>,
        cities: Array<string>,
        dataInizio: string,
        dataFine: string
    ): number;
    waitingForFileCreation: boolean;
    stream: ArrayBuffer | null;
    sedi: Array<ILaboratorioSede>;
}

interface IState {
    searchParams: ILaboratorioSearchParams;
    selected: {
        cities: Array<ISelectOption>;
    };
    province: IProvinceList;
    showForm: boolean;
}

class Laboratori extends Component<IProps & RouteComponentProps, IState> {
    state = {
        searchParams: {
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        },
        selected: {
            cities: [],
        },
        province: constants.PROVINCE,
        showForm: false,
    };

    handleSelectedOptions = (name: string, selected: Array<ISelectOption> | ISelectOption) => {
        let newSelected = {
            ...this.state.selected,
            [name]: selected || [],
        };

        this.setState({
            selected: newSelected,
        });
    };

    handleSearchChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const keyword = e.currentTarget.value;

        let searchParams = {
            ...this.state.searchParams,
            keyword,
        };

        this.setState({
            searchParams,
        });
    };

    onSelectProv = (prov: string, checked: boolean) => {
        let province = {
            ...this.state.province,
            [prov]: {
                ...this.state.province[prov],
                checked,
            },
        };

        this.setState({
            province,
        });
    };

    onDatesRangeChanged = (type: 'dataInizio' | 'dataFine', value: Date) => {
        let searchParams = {
            ...this.state.searchParams,
            [type]: dayjs(value).format('YYYY-MM-DD'),
        };

        this.setState({
            searchParams,
        });
    };

    onSearchData = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { cities },
            province,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);

        this.props.onGetData(
            keyword,
            provFilter,
            cities.map((item) => item.value),
            dataInizio,
            dataFine,
            page
        );
    };

    onRowSelected = (data: Array<any>) => {
        if (data && data.length > 0) {
            this.props.history.push(
                routes.LABORATORIO.replace(':idDitta', data[0].idDitta).replace(
                    ':idSede',
                    data[0].idSede
                )
            );
        }
    };

    loadMore = () => {
        let searchParams = {
            ...this.state.searchParams,
            page: this.state.searchParams.page + 1,
        };
        this.setState({ searchParams }, () => {
            this.onSearchData();
        });
    };

    onGetCounterClicked = () => {
        this.props.onResetData();

        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { cities },
            province,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);

        setTimeout(() => {
            this.props.onGetCounter(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                dataInizio,
                dataFine,
                page
            );
        }, 300);
    };

    onExportClicked = () => {
        const {
            searchParams: { keyword, dataFine, dataInizio, page },
            selected: { cities },
            province,
        } = this.state;

        const provFilter = Object.keys(province).filter((prov) => province[prov].checked);

        setTimeout(() => {
            this.props.onExportData(
                keyword,
                provFilter,
                cities.map((item) => item.value),
                dataInizio,
                dataFine,
                page
            );
        }, 300);
    };

    onSubmitClicked = () => {
        this.props.onResetData();

        setTimeout(() => {
            this.onSearchData();
        }, 300);
    };

    resetFilters = () => {
        const searchParams = {
            dataInizio: null,
            dataFine: null,
            keyword: '',
            page: 1,
        };
        const selected = {
            cities: [],
        };

        const province = constants.PROVINCE;

        this.setState(
            {
                searchParams,
                selected,
                province,
            },
            () => {
                this.props.onResetData();
            }
        );
    };

    resetPaging = () => {
        const searchParams = {
            ...this.state.searchParams,
            page: 1,
        };

        this.setState({ searchParams }, () => {
            this.props.onResetData();
        });
    };

    onToggleFormVisibility = () => {
        const showForm = !this.state.showForm;

        this.setState({
            showForm,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.stream !== prevProps.stream && this.props.stream) {
            createFile(this.props.stream, 'Elenco_laboratori', 'xlsx', true);
        }
    }

    render() {
        const {
            searchParams: { keyword, dataFine, dataInizio },
            province,
            selected,
            showForm,
        } = this.state;

        const {
            waitingForList,
            list,
            totalRecords,
            isLastPage,
            showCounter,
            waitingForFileCreation,
        } = this.props;

        const provinceTpl = Object.keys(province).map((prov: string) => {
            return (
                <CheckBox
                    key={prov}
                    checked={province[prov].checked}
                    changed={(checked: boolean) => this.onSelectProv(prov, checked)}
                >
                    {province[prov].label}
                </CheckBox>
            );
        });

        const rowClassRules = {
            'Grid-row-chiusa': function (params) {
                return params.data.stato === 2;
            },

            'Grid-row-sospesa': function (params) {
                return params.data.stato === 1;
            },
        };

        return (
            <AuthWrapper>
                <main className={classes.Laboratori}>
                    <header>
                        <Title>{'Laboratori'}</Title>
                        <IconButton clicked={this.onToggleFormVisibility} icon={'plus'} />
                    </header>
                    <Filters
                        onSubmitClicked={this.onSubmitClicked}
                        onResetClicked={this.resetFilters}
                        onGetCounter={this.onGetCounterClicked}
                        onExportClicked={this.onExportClicked}
                        undoLabel={'Reset filtri'}
                        submitLabel={'Ricerca'}
                    >
                        <Tabs
                            tabs={{
                                titles: ['Generico', 'Posizione'],
                                items: [
                                    <FlexWrapper noMargin={true} withSections={true}>
                                        <section>
                                            <Field
                                                label={
                                                    'Ricerca per Ragione Sociale, ID Fiscale o PEC'
                                                }
                                                noMargin={true}
                                                name={'search'}
                                                type={'text'}
                                                changed={this.handleSearchChanged}
                                                value={keyword}
                                                placeholder={'Ricerca...'}
                                            />
                                        </section>
                                        <section>
                                            <DatesRange
                                                label={'Range date'}
                                                onStartDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataInizio', value)
                                                }
                                                onEndDateChanged={(value: Date) =>
                                                    this.onDatesRangeChanged('dataFine', value)
                                                }
                                                startDate={dataInizio}
                                                endDate={dataFine}
                                            />
                                        </section>
                                    </FlexWrapper>,
                                    <>
                                        <div>
                                            <CitySelect
                                                label={'Ricerca per città'}
                                                name={'cities'}
                                                selected={selected.cities}
                                                onSelectedChanged={(values: Array<ISelectOption>) =>
                                                    this.handleSelectedOptions('cities', values)
                                                }
                                            />
                                        </div>
                                        <FlexWrapper
                                            noMargin={true}
                                            label={'Province'}
                                            name={'province'}
                                        >
                                            {provinceTpl}
                                        </FlexWrapper>
                                    </>,
                                ],
                            }}
                        />
                    </Filters>
                    {waitingForFileCreation && (
                        <Loader label={'Attendere qualche secondo...'} fullPage={true} />
                    )}
                    {list && list.length > 0 && (
                        <Grid
                            rowHeight={125}
                            currentNumberOfRecords={list.length}
                            totalRecords={totalRecords}
                            rows={getLaboratoriRows(list)}
                            columns={tables.LABORATORI}
                            onShowMoreClicked={this.loadMore}
                            loading={waitingForList}
                            isLastPage={isLastPage}
                            onRowSelected={this.onRowSelected}
                            rowsRules={rowClassRules}
                        />
                    )}
                    {showCounter && !waitingForList && (
                        <CounterWrapper value={totalRecords} label={'Record trovati'} />
                    )}
                    {waitingForList && !list.length && (
                        <section className={classes['Laboratori-loading']}>
                            <Loader center={true} fullContent={true} />
                        </section>
                    )}
                    <ModalWrapper
                        title={'Creazione Laboratorio'}
                        visible={showForm}
                        onCloseClicked={() => this.setState({ showForm: false })}
                    >
                        <LaboratorioForm onCloseModal={this.onToggleFormVisibility} />
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        list: state.laboratorioState.list,
        waitingForList: state.laboratorioState.isFetchingList,
        error: state.laboratorioState.error,
        isLastPage: state.laboratorioState.isLastPage,
        totalRecords: state.laboratorioState.totalRecords,
        totalPages: state.laboratorioState.totalPages,
        showCounter:
            state.laboratorioState.totalRecords >= 0 &&
            state.laboratorioState.onlyCounter &&
            !state.laboratorioState.list.length,
        waitingForFileCreation: state.laboratorioState.isCreatingFile,
        stream: state.laboratorioState.stream,
        sedi: state.laboratorioState.sedi,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            dataInizio: string = null,
            dataFine: string = null,
            page: number
        ) =>
            dispatch(
                actions.fetchLaboratori(keyword, province, cities, dataInizio, dataFine, page)
            ),
        onResetData: () => dispatch(actions.resetLaboratori()),
        onGetCounter: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(
                actions.fetchLaboratoriCounter(keyword, province, cities, dataInizio, dataFine)
            ),
        onExportData: (
            keyword: string,
            province: Array<string>,
            cities: Array<string>,
            dataInizio: string = null,
            dataFine: string = null
        ) =>
            dispatch(actions.exportLaboratoriData(keyword, province, cities, dataInizio, dataFine)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Laboratori));
