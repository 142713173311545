import * as actionTypes from '../actions/actionTypes';
import { IControllo } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    list: Array<IControllo>;
    error: string;
    page: number;
    count: number;
    totalPages: number;
    item: IControllo;
    stream: Blob;
}

const INITIAL_STATE = {
    list: [],
    selected: null,
    isFetchingList: false,
    isFetchingItem: false,
    didInvalidate: false,
    error: null,
    currentPage: 1,
    isLastPage: false,
    totalRecords: 0,
    totalPages: 0,
    onlyCounter: false,
    stream: null,
    isCreatingFile: false,
};

const fetchControlliStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.page === 1 ? [] : state.list,
        currentPage: action.page,
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: action.page === 1 ? 0 : state.totalRecords,
        totalPages: action.page === 1 ? 0 : state.totalPages,
        onlyCounter: false,
    });
};
const fetchControlliSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldPaginatedList: Array<IControllo> = [...state.list];
    const updatedPaginatedList = oldPaginatedList.concat(action.list);

    return updateObject(state, {
        list: updatedPaginatedList,
        isFetchingList: false,
        isLastPage: state.currentPage === action.totalPages,
        totalRecords: action.count,
        totalPages: action.totalPages,
    });
};

const fetchControlliFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const fetchControlloStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingItem: true,
        selected: null,
        onlyCounter: false,
    });
};

const fetchControlloSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingItem: false,
        selected: action.item,
    });
};

const fetchControlloFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingItem: false,
        didInvalidate: true,
    });
};

const fetchCounterStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: 0,
        list: [],
        onlyCounter: true,
    });
};

const fetchCounterSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingList: false,
        totalRecords: action.count,
    });
};

const fetchCounterFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const exportStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFile: true,
    });
};

const exportSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFile: false,
        stream: action.stream,
    });
};

const exportFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingFile: false,
        didInvalidate: true,
    });
};

const resetControlli = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        isFetching: false,
        didInvalidate: false,
        error: null,
        currentPage: 1,
        isLastPage: false,
        totalRecords: 0,
        onlyCounter: false,
    });
};

const controlloReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_CONTROLLI_START:
            return fetchControlliStart(state, action);
        case actionTypes.FETCH_CONTROLLI_SUCCESS:
            return fetchControlliSuccess(state, action);
        case actionTypes.FETCH_CONTROLLI_FAIL:
            return fetchControlliFail(state, action);

        case actionTypes.FETCH_CONTROLLO_START:
            return fetchControlloStart(state, action);
        case actionTypes.FETCH_CONTROLLO_SUCCESS:
            return fetchControlloSuccess(state, action);
        case actionTypes.FETCH_CONTROLLO_FAIL:
            return fetchControlloFail(state, action);

        case actionTypes.FETCH_CONTROLLI_COUNTER_START:
            return fetchCounterStart(state);
        case actionTypes.FETCH_CONTROLLI_COUNTER_SUCCESS:
            return fetchCounterSuccess(state, action);
        case actionTypes.FETCH_CONTROLLI_COUNTER_FAIL:
            return fetchCounterFail(state, action);

        case actionTypes.FETCH_CONTROLLI_EXPORT_START:
            return exportStart(state);
        case actionTypes.FETCH_CONTROLLI_EXPORT_SUCCESS:
            return exportSuccess(state, action);
        case actionTypes.FETCH_CONTROLLI_EXPORT_FAIL:
            return exportFail(state, action);

        case actionTypes.RESET_CONTROLLI:
            return resetControlli(state);

        default:
            return state;
    }
};

export default controlloReducer;
