import React, { Component } from 'react';
import classes from './styles.module.scss';
import { IField } from '../../shared/interfaces';
import Label from '../UI/Label';

class Field extends Component<IField> {
    state = {
        focused: false,
    };

    internalOnFocus = () => {
        this.setState({
            focused: true,
        });
    };

    render() {
        const {
            type,
            name,
            changed,
            value,
            placeholder,
            label,
            error,
            blurred,
            textAlign,
            size,
            hasMaxWidth,
            styles,
            transform,
            disabled,
            min,
            max,
        } = this.props;

        return (
            <div
                style={styles}
                className={[
                    classes.Field,
                    error ? classes['Field--error'] : '',
                    textAlign ? classes[`Field--${textAlign}`] : '',
                    size ? classes[`Field--${size}`] : '',
                    hasMaxWidth ? classes['Field--hasMaxWidth'] : '',
                    transform ? classes[`Field--${transform}`] : '',
                    type === 'number' ? classes['Field--number'] : '',
                ].join(' ')}
            >
                {label && (
                    <Label color={'black'} for={name} error={error}>
                        {label}
                    </Label>
                )}
                <input
                    disabled={disabled || false}
                    aria-label={name}
                    id={name}
                    className={classes['Field-input']}
                    autoComplete={'off'}
                    aria-autocomplete={'none'}
                    type={type || 'text'}
                    name={name}
                    onChange={changed}
                    onBlur={blurred}
                    value={value}
                    placeholder={placeholder}
                    onFocus={this.internalOnFocus}
                    min={min}
                    max={max}
                    step={1}
                />
                {error && <small>{error}</small>}
            </div>
        );
    }
}

export default Field;
