import React, { Component } from 'react';
import { IDecreto, IFormatore, IFormatoreSede } from '../../shared/interfaces';
import columns from '../../shared/data/columns';
import { connect } from 'react-redux';
import DetailsWrapper from '../DetailsWrapper';
import Text from '../UI/Text';
import Label from '../UI/Label';
import FlexWrapper from '../UI/FlexWrapper';
import NoDataText from '../NoDataText';
import List from '../UI/List';
import { FormatoreTipoDecretoEnum } from '../../shared/enums';
import * as actions from '../../store/actions';
import IconButton from '../UI/IconButton';
import ModalWrapper from '../Modal';
import FormatoreSedeForm from '../FormatoreSedeForm';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../ConfirmDialog';
import Toast from '../UI/Toast';
import FormatoreDecretoForm from '../FormatoreDecretoForm';
import dayjs from 'dayjs';

interface IProps {
    item: IFormatore;
    onRemoveSede(idFormatore: string, id: string): void;
    lastRemovedSede: string;
    onRemoveDecreto(idFormatore: string, id: string): void;
    lastRemovedDecreto: string;
}

interface IState {
    showSedeForm: boolean;
    selectedSede: IFormatoreSede;
    showDecretoForm: boolean;
    selectedDecreto: IDecreto;
}

class FormatoreDetails extends Component<IProps, IState> {
    state = {
        showSedeForm: false,
        selectedSede: null,
        showDecretoForm: false,
        selectedDecreto: null,
    };

    onToggleDecretoFormVisibility = () => {
        const showDecretoForm = !this.state.showDecretoForm;

        if (!showDecretoForm) {
            this.setState({
                selectedDecreto: null,
            });
        }

        this.setState({
            showDecretoForm,
        });
    };

    onToggleSedeFormVisibility = () => {
        const showSedeForm = !this.state.showSedeForm;

        if (!showSedeForm) {
            this.setState({
                selectedSede: null,
            });
        }

        this.setState({
            showSedeForm,
        });
    };

    onRemoveDecretoClicked = (id: string) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmDialog
                        onClose={onClose}
                        onConfirm={() => this.props.onRemoveDecreto(this.props.item.Id, id)}
                        title={'Sei sicuro di voler rimuovere questo decreto?'}
                        subtitle={"L'operazione NON potrà essere annullata."}
                    />
                );
            },
        });
    };

    onRemoveSedeClicked = (id: string) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmDialog
                        onClose={onClose}
                        onConfirm={() => this.props.onRemoveSede(this.props.item.Id, id)}
                        title={'Sei sicuro di voler rimuovere questa sede?'}
                        subtitle={"L'operazione NON potrà essere annullata."}
                    />
                );
            },
        });
    };

    onSedeSelected = (sede: IFormatoreSede) => {
        this.setState({
            selectedSede: sede,
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.lastRemovedSede !== prevProps.lastRemovedSede &&
            this.props.lastRemovedSede
        ) {
            this.setState(
                {
                    selectedSede: null,
                },
                () => {
                    Toast({ message: 'Record rimosso con successo.', type: 'success' });
                }
            );
        }
    }

    render() {
        const { item } = this.props;
        const { showSedeForm, showDecretoForm } = this.state;

        return (
            <>
                <DetailsWrapper
                    title={
                        <Text size={'md'} weight={'medium'}>
                            {item.RagioneSociale}
                        </Text>
                    }
                    subtitle={
                        <>
                            <Text inline={true} label={'ID Fiscale'}>
                                {item.IdentificativoFiscale}
                            </Text>
                            <Text inline={true} label={'N° Iscrizione'}>
                                {item.NumeroIscrizione}
                            </Text>
                        </>
                    }
                    tabs={{
                        titles: [
                            'Ditta',
                            'Titolarità',
                            `Sedi (${item.Sedi ? item.Sedi.length : 0})`,
                            `Decreti (${item.Decreti ? item.Decreti.length : 0})`,
                        ],
                        items: [
                            <div>
                                <Label size={'md'} for={'sede legale'}>
                                    Sede legale
                                </Label>
                                <FlexWrapper withSeparator={true}>
                                    <Text weight={'medium'}>{item.Citta}</Text>
                                    <Text>{item.Cap}</Text>
                                </FlexWrapper>
                                <Text marginBottom={20}>{item.Indirizzo}</Text>
                                {(item.FormazioneResidenziale || item.FormazioneADistanzaAsync) && (
                                    <>
                                        <Label size={'md'} for={'Tipo formazione'}>
                                            Tipo formazione
                                        </Label>
                                        {item.FormazioneADistanzaAsync && (
                                            <Text
                                                marginBottom={item.FormazioneResidenziale ? 0 : 20}
                                                wrap={true}
                                            >
                                                {'A distanza Asincrona'}
                                            </Text>
                                        )}
                                        {item.FormazioneADistanzaSync && (
                                            <Text
                                                marginBottom={item.FormazioneResidenziale ? 0 : 20}
                                                wrap={true}
                                            >
                                                {'A distanza Sincrona'}
                                            </Text>
                                        )}
                                        {item.FormazioneResidenziale && (
                                            <Text marginBottom={20} wrap={true}>
                                                {'Residenziale'}
                                            </Text>
                                        )}
                                    </>
                                )}
                                <Label size={'md'} for={'altri dati'}>
                                    Altri dati
                                </Label>
                                {item.Pec ? (
                                    <FlexWrapper withSeparator={true}>
                                        <Text weight={'medium'}>{'PEC:'}</Text>
                                        <Text>{item.Pec}</Text>
                                    </FlexWrapper>
                                ) : (
                                    <NoDataText />
                                )}
                            </div>,
                            <div>
                                <Label size={'md'} for={'legaleRappresentante'}>
                                    Legale rappresentante
                                </Label>
                                {item.LegaleRappresentante ? (
                                    <FlexWrapper withSeparator={true} withMarginBottom={true}>
                                        <Text>{item.LegaleRappresentante.Cognome}</Text>
                                        <Text>{item.LegaleRappresentante.Nome}</Text>
                                        <Text weight={'medium'}>
                                            {item.LegaleRappresentante.CodiceFiscale}
                                        </Text>
                                        <Text wrap={true}>
                                            {dayjs(item.LegaleRappresentante.DataNascita).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </Text>
                                    </FlexWrapper>
                                ) : (
                                    <NoDataText />
                                )}
                            </div>,
                            <div>
                                <List
                                    withMarginBottom={true}
                                    columns={columns.FORMATORI_COLUMNS}
                                    items={item.Sedi.map((sede: IFormatoreSede) => {
                                        return (
                                            <>
                                                <div style={columns.FORMATORI_COLUMNS[0].styles}>
                                                    <FlexWrapper>
                                                        <Text size={'sm'} weight={'medium'}>
                                                            {`${item.NumeroIscrizione}/${sede.ProgressivoSede}`}
                                                        </Text>
                                                    </FlexWrapper>
                                                    <Text size={'sm'}>
                                                        {dayjs(sede.DataInserimento).format(
                                                            'DD/MM/YYYY'
                                                        )}
                                                    </Text>
                                                </div>
                                                <div style={columns.FORMATORI_COLUMNS[1].styles}>
                                                    <FlexWrapper withSeparator={true}>
                                                        <Text size={'sm'} weight={'medium'}>
                                                            {sede.Citta}
                                                        </Text>
                                                        <Text size={'sm'}>{sede.Cap}</Text>
                                                    </FlexWrapper>
                                                    <Text size={'sm'}>{sede.Indirizzo}</Text>
                                                </div>
                                                <div style={columns.FORMATORI_COLUMNS[2].styles}>
                                                    {sede.ResponsabileSede ? (
                                                        <>
                                                            <Text size={'sm'} weight={'medium'}>
                                                                {'Responsabile sede'}
                                                            </Text>
                                                            <Text size={'sm'}>
                                                                {sede.ResponsabileSede}
                                                            </Text>
                                                        </>
                                                    ) : (
                                                        <NoDataText />
                                                    )}
                                                </div>
                                                <div style={columns.FORMATORI_COLUMNS[3].styles}>
                                                    <FlexWrapper justifyFlexEnd={true}>
                                                        <IconButton
                                                            size={'sm'}
                                                            withMargin={true}
                                                            theme={'danger'}
                                                            clicked={() =>
                                                                this.onRemoveSedeClicked(sede.Id)
                                                            }
                                                            icon={'trash'}
                                                        />
                                                        <IconButton
                                                            size={'sm'}
                                                            clicked={() =>
                                                                this.setState(
                                                                    {
                                                                        selectedSede: sede,
                                                                    },
                                                                    () => {
                                                                        this.onToggleSedeFormVisibility();
                                                                    }
                                                                )
                                                            }
                                                            icon={'edit'}
                                                        />
                                                    </FlexWrapper>
                                                </div>
                                            </>
                                        );
                                    })}
                                />
                                <FlexWrapper justifyFlexEnd={true}>
                                    <IconButton
                                        theme={'info'}
                                        withMargin={true}
                                        size={'sm'}
                                        clicked={this.onToggleSedeFormVisibility}
                                        icon={'plus'}
                                    />
                                </FlexWrapper>
                            </div>,
                            <div>
                                <List
                                    withMarginBottom={true}
                                    columns={columns.DECRETI_COLUMNS}
                                    items={item.Decreti.map((decreto: IDecreto) => {
                                        return (
                                            <>
                                                <div style={columns.DECRETI_COLUMNS[0].styles}>
                                                    <FlexWrapper>
                                                        <Text size={'sm'} weight={'medium'}>
                                                            {`${decreto.Numero}`}
                                                        </Text>
                                                    </FlexWrapper>
                                                    <Text size={'sm'}>
                                                        {dayjs(decreto.Data).format('DD/MM/YYYY')}
                                                    </Text>
                                                </div>
                                                <div style={columns.DECRETI_COLUMNS[1].styles}>
                                                    <Text size={'sm'}>
                                                        {
                                                            FormatoreTipoDecretoEnum[
                                                                decreto.TipoDecreto
                                                            ]
                                                        }
                                                    </Text>
                                                </div>
                                                <div style={columns.DECRETI_COLUMNS[2].styles}>
                                                    {decreto.Url ? (
                                                        <>
                                                            <a href={decreto.Url} download>
                                                                {'Scarica'}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <NoDataText />
                                                    )}
                                                </div>
                                                ,
                                                <div style={columns.FORMATORI_COLUMNS[3].styles}>
                                                    <FlexWrapper justifyFlexEnd={true}>
                                                        <IconButton
                                                            size={'sm'}
                                                            withMargin={true}
                                                            theme={'danger'}
                                                            clicked={() =>
                                                                this.onRemoveDecretoClicked(
                                                                    decreto.Id
                                                                )
                                                            }
                                                            icon={'trash'}
                                                        />
                                                        <IconButton
                                                            size={'sm'}
                                                            clicked={() =>
                                                                this.setState(
                                                                    {
                                                                        selectedDecreto: decreto,
                                                                    },
                                                                    () => {
                                                                        this.onToggleDecretoFormVisibility();
                                                                    }
                                                                )
                                                            }
                                                            icon={'edit'}
                                                        />
                                                    </FlexWrapper>
                                                </div>
                                            </>
                                        );
                                    })}
                                />
                                <FlexWrapper justifyFlexEnd={true}>
                                    <IconButton
                                        theme={'info'}
                                        withMargin={true}
                                        size={'sm'}
                                        clicked={this.onToggleDecretoFormVisibility}
                                        icon={'plus'}
                                    />
                                </FlexWrapper>
                            </div>,
                        ],
                    }}
                />
                <ModalWrapper
                    title={
                        this.state.selectedSede ? 'Modifica sede formatore' : 'Crea sede formatore'
                    }
                    visible={showSedeForm}
                    onCloseClicked={this.onToggleSedeFormVisibility}
                >
                    <FormatoreSedeForm
                        onCloseModal={this.onToggleSedeFormVisibility}
                        currentData={this.state.selectedSede}
                        idFormatore={item.Id}
                    />
                </ModalWrapper>
                <ModalWrapper
                    title={this.state.selectedDecreto ? 'Modifica decreto' : 'Crea decreto'}
                    visible={showDecretoForm}
                    onCloseClicked={this.onToggleDecretoFormVisibility}
                >
                    <FormatoreDecretoForm
                        onCloseModal={this.onToggleDecretoFormVisibility}
                        currentData={this.state.selectedDecreto}
                        idFormatore={item.Id}
                    />
                </ModalWrapper>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        item: state.formatoreState.selected,
        waitingForItem: state.formatoreState.isFetchingItem || state.formatoreState.isRemovingSede,
        error: state.formatoreState.error,
        sedi: state.formatoreState.sedi,
        lastRemovedItem: state.formatoreState.lastRemovedFormatore,
        waitingForRemoving: state.formatoreState.isRemovingFormatore,
        lastRemovedSede: state.formatoreState.lastRemovedSede,
        lastRemovedDecreto: state.formatoreState.lastRemovedDecreto,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onRemoveSede: (idFormatore: string, id: string) =>
            dispatch(actions.removeSede(idFormatore, id)),
        onRemoveDecreto: (idFormatore: string, id: string) =>
            dispatch(actions.removeDecreto(idFormatore, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatoreDetails);
