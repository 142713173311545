import React, { Component } from 'react';
import Select from 'react-select';
import classes from './styles.module.scss';
import { getItalianPeriod } from '../../shared/helpers/utilities';
import dayjs from 'dayjs';

let options = [
    {
        value: dayjs().subtract(1, 'month').format('MMM').toLowerCase(),
        label: getItalianPeriod(dayjs().subtract(1, 'month').format('MMMM YYYY')),
    },
];

for (let i = -2; i > -13; i--) {
    options.push({
        value: dayjs().add(i, 'month').format('MMM').toLowerCase(),
        label: getItalianPeriod(dayjs().add(i, 'month').format('MMMM YYYY')),
    });
}

interface IProps {
    changed(selected: { value: string; label: string }): void;
}

export default class PeriodSelector extends Component<IProps> {
    render() {
        return (
            <section className={classes.PeriodSelector}>
                <Select
                    defaultValue={options[0]}
                    isSearchable={true}
                    name="months"
                    options={options}
                    onChange={this.props.changed}
                />
            </section>
        );
    }
}
