import * as actionTypes from './actionTypes';
import { IControllo, IResponse } from '../../shared/interfaces';
import ControlloService from '../../services/api/controllo';

// FETCH
export const fetchControlliStart = (page: number) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_START,
        page,
    };
};

export const fetchControlliSuccess = (
    list: Array<IControllo>,
    count: number,
    totalPages: number
) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_SUCCESS,
        list,
        count,
        totalPages,
    };
};

export const fetchControlliFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_FAIL,
        error,
    };
};

export const fetchControlli = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number | null,
    motivo: number | null,
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    return (dispatch: any) => {
        dispatch(fetchControlliStart(page));

        ControlloService.get(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            tipo,
            motivo,
            dataInizio,
            dataFine,
            page
        ).then(
            (response: IResponse) => {
                dispatch(
                    fetchControlliSuccess(
                        response.Data,
                        response.DataCount || 0,
                        response.TotalPages || 1
                    )
                );
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchControlliFail(response.data.error));
            }
        );
    };
};

// DETAIL
export const fetchControlloStart = () => {
    return {
        type: actionTypes.FETCH_CONTROLLO_START,
    };
};

export const fetchControlloSuccess = (item: IControllo) => {
    return {
        type: actionTypes.FETCH_CONTROLLO_SUCCESS,
        item,
    };
};

export const fetchControlloFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CONTROLLO_FAIL,
        error,
    };
};

export const fetchControllo = (id: string) => {
    return (dispatch: any) => {
        dispatch(fetchControlloStart());

        ControlloService.getById(id).then(
            (data: IControllo) => {
                dispatch(fetchControlloSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchControlloFail(response.data.error));
            }
        );
    };
};

// COUNTER
export const fetchControlliCounterStart = () => {
    return {
        type: actionTypes.FETCH_CONTROLLI_COUNTER_START,
    };
};

export const fetchControlliCounterSuccess = (count: number) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_COUNTER_SUCCESS,
        count,
    };
};

export const fetchControlliCounterFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_COUNTER_FAIL,
        error,
    };
};

export const fetchControlliCounter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number | null,
    motivo: number | null,
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(fetchControlliCounterStart());

        ControlloService.counter(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            tipo,
            motivo,
            dataInizio,
            dataFine
        ).then(
            (response: IResponse) => {
                dispatch(fetchControlliCounterSuccess(response.DataCount || 0));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchControlliCounterFail(response.data.error));
            }
        );
    };
};

// EXPORT
export const exportControlliDataStart = () => {
    return {
        type: actionTypes.FETCH_CONTROLLI_EXPORT_START,
    };
};

export const exportControlliDataSuccess = (stream: Blob) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_EXPORT_SUCCESS,
        stream,
    };
};

export const exportControlliDataFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CONTROLLI_EXPORT_FAIL,
        error,
    };
};

export const exportControlliData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    tipo: number | null,
    motivo: number | null,
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(exportControlliDataStart());

        ControlloService.exportData(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            tipo,
            motivo,
            dataInizio,
            dataFine
        ).then(
            (stream: Blob) => {
                dispatch(exportControlliDataSuccess(stream));
            },
            (response: { data: { error: string } }) => {
                dispatch(exportControlliDataFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetControlli = () => {
    return {
        type: actionTypes.RESET_CONTROLLI,
    };
};
