import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthWrapper from '../../containers/AuthWrapper';
import * as actions from '../../store/actions';
import { IStatData, IStatRow, IStats, IStatTotals } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';
import {
    getChartData,
    getStatDetailsData,
    getStatsRows,
    getStatsTotals,
} from '../../shared/helpers/stats';
import dayjs from 'dayjs';
import PeriodSelector from '../../components/PeriodSelector';
import StatsTable from '../../components/StatsTable';
import StatsChart from '../../components/StatsChart';
import ProvinceSelector from '../../components/ProvinceSelector';
import ModalWrapper from '../../components/Modal';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
    stats: IStats;
    waitingForStats: boolean;
    error: any;
    onGetData(months: number): IStats;
    onResetData(): void;
}

interface IState {
    tableRows: Array<IStatRow>;
    totals: IStatTotals;
    showTableEffect: boolean;
    showChartEffect: boolean;
    chartData: Array<Array<string | number>>;
    showDetails: boolean;
    currentMonth: string;
    currentDetails: {
        provincia: string | null;
        data: Array<IStatRow>;
    };
    totalsForDetail: IStatTotals;
}

class Statistiche extends Component<IProps & RouteComponentProps, IState> {
    state = {
        tableRows: [],
        totals: null,
        chartData: [],
        showChartEffect: false,
        showTableEffect: false,
        showDetails: false,
        currentMonth: dayjs().subtract(1, 'month').format('MMM'),
        currentDetails: {
            provincia: null,
            data: [],
        },
        totalsForDetail: null,
    };

    componentDidMount() {
        this.props.onGetData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.stats !== prevProps.stats) {
            this.createTableRows(this.props.stats, dayjs().subtract(1, 'month').format('MMM'));
            this.createChartData(this.props.stats);
        }
    }

    createTableRows = (items: Array<IStats>, month: string) => {
        if (!month || !items) return [];

        this.setState(
            {
                showTableEffect: true,
                currentMonth: month,
            },
            () => {
                const tableRows: Array<IStatRow> = getStatsRows(items, month);
                const totals: IStatTotals = getStatsTotals(items, month);

                this.setState(
                    {
                        tableRows,
                        totals,
                    },
                    () => {
                        this.resetShowEffect();
                    }
                );
            }
        );
    };

    createChartData = (items: Array<IStats>, provincia?: string) => {
        if (!items) return [];

        this.setState(
            {
                showTableEffect: true,
            },
            () => {
                const chartData: Array<Array<string | number>> = getChartData(items, provincia);

                this.setState(
                    {
                        chartData,
                    },
                    () => {
                        this.resetShowEffect();
                    }
                );
            }
        );
    };

    resetShowEffect = () => {
        setTimeout(() => {
            this.setState({
                showTableEffect: false,
                showChartEffect: false,
            });
        }, 300);
    };

    handleToggleDetailsVisibility = () => {
        const showDetails = !this.state.showDetails;

        this.setState(
            {
                showDetails,
            },
            () => {
                setTimeout(() => {
                    if (!showDetails) {
                        this.setState({
                            currentDetails: {
                                provincia: null,
                                data: [],
                            },
                        });
                    }
                }, 500);
            }
        );
    };

    onGoToAnagrafica = (citta: string, idCitta: string) => {
        this.setState(
            {
                showDetails: false,
            },
            () => {
                setTimeout(() => {
                    this.props.history.push(`/anagrafica?citta=${citta}&idCitta=${idCitta}`);
                }, 800);
            }
        );
    };

    onShowDetailsClicked = (prov: string) => {
        const currentStatsRows: Array<IStatData> = [...this.props.stats]
            .filter(
                (main: IStats) => main.Month.toLowerCase() === this.state.currentMonth.toLowerCase()
            )
            .map((item: IStats) => item[`Dati${prov}`]);

        const currentDetails = {
            provincia: prov,
            data:
                currentStatsRows && currentStatsRows.length > 0
                    ? getStatDetailsData(
                          currentStatsRows[0].DatiCitta,
                          prov,
                          this.state.currentMonth
                      )
                    : [],
        };

        const currentTotals =
            currentStatsRows && currentStatsRows.length > 0
                ? {
                      label: 'Totale',
                      osa: currentStatsRows[0].Osa,
                      attivita: currentStatsRows[0].MasterList,
                      stabilimenti: currentStatsRows[0].Sedi,
                      month: this.state.currentMonth,
                  }
                : null;

        this.setState(
            {
                currentDetails,
                totalsForDetail: currentTotals,
            },
            () => {
                this.handleToggleDetailsVisibility();
            }
        );
    };

    render() {
        const { waitingForStats, stats } = this.props;
        const {
            tableRows,
            totals,
            showTableEffect,
            chartData,
            showDetails,
            currentDetails,
            totalsForDetail,
        } = this.state;

        if (waitingForStats) {
            return <Loader label={'Caricamento statistiche in corso...'} fullPage={true} />;
        }

        return (
            <AuthWrapper>
                <main className={classes.Statistiche}>
                    <Title>{'Statistiche'}</Title>
                    <section className={classes['Statistiche-table']}>
                        <PeriodSelector
                            changed={(selected) => this.createTableRows(stats, selected.value)}
                        />
                        <StatsTable
                            type={'master'}
                            cellClicked={this.onShowDetailsClicked}
                            rows={tableRows}
                            showEffect={showTableEffect}
                            totals={totals}
                            columns={['AA.SS.PP', 'OSA', 'Stabilimenti', 'Attività']}
                        />
                    </section>
                    {chartData.length > 0 && (
                        <section className={classes['Statistiche-charts']}>
                            <ProvinceSelector
                                changed={(selected) => this.createChartData(stats, selected.value)}
                            />
                            <StatsChart data={chartData} />
                        </section>
                    )}
                    <ModalWrapper
                        title={`Dati provincia ${currentDetails.provincia}`}
                        visible={showDetails}
                        onCloseClicked={() =>
                            this.setState({
                                showDetails: false,
                            })
                        }
                    >
                        <StatsTable
                            goToAnagrafica={this.onGoToAnagrafica}
                            type={'details'}
                            rows={currentDetails.data}
                            totals={totalsForDetail}
                            columns={['Città', 'OSA', 'Stabilimenti', 'Attività']}
                        />
                    </ModalWrapper>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        stats: state.statsState.stats,
        waitingForStats: state.statsState.isFetching,
        error: state.statsState.error,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetData: (months: number = 12) => dispatch(actions.fetchStats(months)),
        onResetData: () => dispatch(actions.resetStats()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistiche));
