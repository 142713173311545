import React, { Component } from 'react';
import { IMasterList, ISelectOption } from '../../shared/interfaces';
import * as actions from '../../store/actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import SelectField from '../UI/Select';
import { getSelectOptionsForMasterList } from '../../shared/helpers/selects';

const PLACEHOLDER = 'Ricerca Master List';

interface IProps {
    onGetList(keyword: string): Array<IMasterList>;
    waitingForList: boolean;
    items: Array<IMasterList>;
    onSelectedChanged(selected: Array<ISelectOption> | ISelectOption): void;
    label: any;
    name?: string;
    selected: Array<ISelectOption> | ISelectOption;
}

class MasterListSelect extends Component<IProps & RouteComponentProps> {
    onKeywordChanged = (value: string) => {
        if (value && value.length > 2) {
            this.props.onGetList(value);
        }
    };

    render() {
        const { waitingForList, items, onSelectedChanged, label, name, selected } = this.props;

        const cleanOptions = items
            ? items.map((item: IMasterList) => {
                  return {
                      ...item,
                      Code: item.Code.replace('.', '').replace('.', ''),
                  };
              })
            : [];

        return (
            <SelectField
                placeholder={PLACEHOLDER}
                onInputChanged={this.onKeywordChanged}
                selectedChanged={onSelectedChanged}
                isMulti={true}
                options={getSelectOptionsForMasterList(cleanOptions)}
                isLoading={waitingForList}
                label={label}
                name={name}
                selected={selected}
            />
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        items: state.masterListState.list,
        waitingForList: state.masterListState.isFetching,
        itemsError: state.masterListState.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetList: (keyword: string) => dispatch(actions.fetchMasterListByKeyword(keyword)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterListSelect));
