import * as actionTypes from '../actions/actionTypes';
import { IDecreto, ILabAnalisi, ILaboratorioSede } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    list: Array<ILabAnalisi>;
    error: string;
    page: number;
    count: number;
    totalPages: number;
    item: ILaboratorioSede;
    stream: Blob;
    decreto: IDecreto;
    lastRemovedId: string;
    lastRemovedDecretoId: string;
    office: ILaboratorioSede;
}

const INITIAL_STATE = {
    list: [],
    selected: null,
    isFetchingList: false,
    isFetchingItem: false,
    didInvalidate: false,
    error: null,
    currentPage: 1,
    isLastPage: false,
    totalRecords: 0,
    totalPages: 0,
    onlyCounter: false,
    stream: null,
    isCreatingFile: false,
    // Laboratorio
    lastUpdatedLaboratorio: null,
    isUpdatingLaboratorio: false,
    lastCreatedLaboratorio: null,
    isCreatingLaboratorio: false,
    lastRemovedLaboratorio: null,
    isRemovingLaboratorio: false,
    // laboratorio
    decreti: [],
    isCreatingDecreto: false,
    lastCreatedDecreto: null,
    isUpdatingDecreto: false,
    lastUpdatedDecreto: null,
    lastRemovedDecreto: null,
    isRemovingDecreto: false,
    // sede
    lastCreatedSede: false,
    lastUpdatedSede: false,
};

const fetchLaboratoriStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.page === 1 ? [] : state.list,
        currentPage: action.page,
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: action.page === 1 ? 0 : state.totalRecords,
        totalPages: action.page === 1 ? 0 : state.totalPages,
        onlyCounter: false,
        lastUpdatedLaboratorio: null,
        lastCreatedLaboratorio: null,
    });
};
const fetchLaboratoriSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldPaginatedList: Array<ILabAnalisi> = [...state.list];
    const updatedPaginatedList = oldPaginatedList.concat(action.list);

    return updateObject(state, {
        list: updatedPaginatedList,
        isFetchingList: false,
        isLastPage: state.currentPage === action.totalPages,
        totalRecords: action.count,
        totalPages: action.totalPages,
    });
};
const fetchLaboratoriFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const fetchLaboratorioStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingItem: true,
        selected: null,
        onlyCounter: false,
        lastUpdatedLaboratorio: null,
        lastCreatedLaboratorio: null,
    });
};
const fetchLaboratorioSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingItem: false,
        selected: action.item,
        decreti: action.item.Decreti || [],
    });
};
const fetchLaboratorioFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingItem: false,
        didInvalidate: true,
    });
};

const createLaboratorioStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingLaboratorio: true,
        selected: null,
        onlyCounter: false,
        lastCreatedLaboratorio: null,
    });
};
const createLaboratorioSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingLaboratorio: false,
        selected: { ...state.selected, ...action.item },
        lastCreatedLaboratorio: action.item.Id,
    });
};
const createLaboratorioFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingLaboratorio: false,
        didInvalidate: true,
    });
};

const updateLaboratorioStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isUpdatingLaboratorio: true,
        onlyCounter: false,
        lastUpdatedLaboratorio: null,
    });
};
const updateLaboratorioSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isUpdatingLaboratorio: false,
        selected: { ...state.selected, Ditta: action.item },
        lastUpdatedLaboratorio: action.item.Id,
    });
};
const updateLaboratorioFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingLaboratorio: false,
        didInvalidate: true,
    });
};

const removeLaboratorioStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isRemovingLaboratorio: true,
        lastRemovedLaboratorio: null,
    });
};
const removeLaboratorioSuccess = (state = INITIAL_STATE, action: IAction) => {
    const list: Array<ILabAnalisi> = state.list.filter(
        (item: ILabAnalisi) => item.Laboratorio.IdLabAnalisiDitta !== action.lastRemovedId
    );

    return updateObject(state, {
        isRemovingLaboratorio: false,
        lastRemovedLaboratorio: action.lastRemovedId,
        selected: null,
        list,
        totalRecords: state.totalRecords > 0 ? state.totalRecords - 1 : 0,
    });
};
const removeLaboratorioFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isRemovingLaboratorio: false,
        didInvalidate: true,
    });
};

const fetchCounterStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: 0,
        list: [],
        onlyCounter: true,
    });
};
const fetchCounterSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingList: false,
        totalRecords: action.count,
    });
};
const fetchCounterFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const exportStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFile: true,
    });
};
const exportSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFile: false,
        stream: action.stream,
    });
};
const exportFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingFile: false,
        didInvalidate: true,
    });
};

// Sedi
const createSedeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastCreatedSede: null,
        error: null,
        didInvalidate: false,
        isCreatingSede: true,
    });
};
const createSedeSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingSede: false,
        lastCreatedSede: action.office,
    });
};
const createSedeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingSede: false,
        didInvalidate: true,
    });
};

const updateSedeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastUpdatedSede: null,
        error: null,
        didInvalidate: false,
        isUpdatingSede: true,
    });
};
const updateSedeSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isUpdatingSede: false,
        lastUpdatedSede: action.office,
        selected: action.office,
    });
};
const updateSedeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingSede: false,
        didInvalidate: true,
    });
};

// Decreti
const createDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastCreatedDecreto: null,
        error: null,
        didInvalidate: false,
        isCreatingDecreto: true,
    });
};

const createDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = [...state.decreti].concat(action.decreto);

    const selected: ILaboratorioSede = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isCreatingDecreto: false,
        lastCreatedDecreto: action.decreto,
        decreti,
        selected,
    });
};

const createDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingDecreto: false,
        didInvalidate: true,
    });
};

const updateDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastUpdatedDecreto: null,
        error: null,
        didInvalidate: false,
        isUpdatingDecreto: true,
    });
};

const updateDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = [...state.decreti].map((decreto: IDecreto) =>
        decreto.Id === action.decreto.Id ? action.decreto : decreto
    );

    const selected: ILaboratorioSede = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isUpdatingDecreto: false,
        lastUpdatedDecreto: action.decreto,
        decreti,
        selected,
    });
};

const updateDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingDecreto: false,
        didInvalidate: true,
    });
};

const removeDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isRemovingDecreto: true,
        lastRemovedDecreto: null,
    });
};

const removeDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = state.decreti.filter(
        (item: IDecreto) => item.Id !== action.lastRemovedDecretoId
    );

    const selected: ILaboratorioSede = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isRemovingDecreto: true,
        lastRemovedDecreto: action.lastRemovedDecretoId,
        selected,
        decreti,
    });
};

const removeDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isRemovingDecreto: true,
        didInvalidate: true,
    });
};

const resetLaboratori = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        isFetching: false,
        didInvalidate: false,
        error: null,
        currentPage: 1,
        isLastPage: false,
        totalRecords: 0,
        onlyCounter: false,
    });
};

const laboratorioReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_LABORATORI_START:
            return fetchLaboratoriStart(state, action);
        case actionTypes.FETCH_LABORATORI_SUCCESS:
            return fetchLaboratoriSuccess(state, action);
        case actionTypes.FETCH_LABORATORI_FAIL:
            return fetchLaboratoriFail(state, action);

        case actionTypes.FETCH_LABORATORIO_START:
            return fetchLaboratorioStart(state, action);
        case actionTypes.FETCH_LABORATORIO_SUCCESS:
            return fetchLaboratorioSuccess(state, action);
        case actionTypes.FETCH_LABORATORIO_FAIL:
            return fetchLaboratorioFail(state, action);

        case actionTypes.CREATE_LABORATORIO_START:
            return createLaboratorioStart(state, action);
        case actionTypes.CREATE_LABORATORIO_SUCCESS:
            return createLaboratorioSuccess(state, action);
        case actionTypes.CREATE_LABORATORIO_FAIL:
            return createLaboratorioFail(state, action);

        case actionTypes.UPDATE_LABORATORIO_START:
            return updateLaboratorioStart(state, action);
        case actionTypes.UPDATE_LABORATORIO_SUCCESS:
            return updateLaboratorioSuccess(state, action);
        case actionTypes.UPDATE_LABORATORIO_FAIL:
            return updateLaboratorioFail(state, action);

        case actionTypes.REMOVE_LABORATORIO_START:
            return removeLaboratorioStart(state, action);
        case actionTypes.REMOVE_LABORATORIO_SUCCESS:
            return removeLaboratorioSuccess(state, action);
        case actionTypes.REMOVE_LABORATORIO_FAIL:
            return removeLaboratorioFail(state, action);

        case actionTypes.FETCH_LABORATORI_COUNTER_START:
            return fetchCounterStart(state);
        case actionTypes.FETCH_LABORATORI_COUNTER_SUCCESS:
            return fetchCounterSuccess(state, action);
        case actionTypes.FETCH_LABORATORI_COUNTER_FAIL:
            return fetchCounterFail(state, action);

        case actionTypes.FETCH_LABORATORI_EXPORT_START:
            return exportStart(state);
        case actionTypes.FETCH_LABORATORI_EXPORT_SUCCESS:
            return exportSuccess(state, action);
        case actionTypes.FETCH_LABORATORI_EXPORT_FAIL:
            return exportFail(state, action);

        case actionTypes.CREATE_LABORATORIO_SEDE_START:
            return createSedeStart(state);
        case actionTypes.CREATE_LABORATORIO_SEDE_SUCCESS:
            return createSedeSuccess(state, action);
        case actionTypes.CREATE_LABORATORIO_SEDE_FAIL:
            return createSedeFail(state, action);

        case actionTypes.UPDATE_LABORATORIO_SEDE_START:
            return updateSedeStart(state);
        case actionTypes.UPDATE_LABORATORIO_SEDE_SUCCESS:
            return updateSedeSuccess(state, action);
        case actionTypes.UPDATE_LABORATORIO_SEDE_FAIL:
            return updateSedeFail(state, action);

        case actionTypes.CREATE_LABORATORIO_DECRETO_START:
            return createDecretoStart(state);
        case actionTypes.CREATE_LABORATORIO_DECRETO_SUCCESS:
            return createDecretoSuccess(state, action);
        case actionTypes.CREATE_LABORATORIO_DECRETO_FAIL:
            return createDecretoFail(state, action);

        case actionTypes.UPDATE_LABORATORIO_DECRETO_START:
            return updateDecretoStart(state);
        case actionTypes.UPDATE_LABORATORIO_DECRETO_SUCCESS:
            return updateDecretoSuccess(state, action);
        case actionTypes.UPDATE_LABORATORIO_DECRETO_FAIL:
            return updateDecretoFail(state, action);

        case actionTypes.DELETE_LABORATORIO_DECRETO_START:
            return removeDecretoStart(state);
        case actionTypes.DELETE_LABORATORIO_DECRETO_SUCCESS:
            return removeDecretoSuccess(state, action);
        case actionTypes.DELETE_LABORATORIO_DECRETO_FAIL:
            return removeDecretoFail(state, action);

        case actionTypes.RESET_LABORATORI:
            return resetLaboratori(state);

        default:
            return state;
    }
};

export default laboratorioReducer;
