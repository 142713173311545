import React, { ReactNode } from 'react';
import classes from './styles.module.scss';
import { ITab, IAction } from '../../shared/interfaces';
import Tabs from '../Tabs';
import Button from '../UI/Button';

interface IProps {
    title: ReactNode | string;
    subtitle: ReactNode | string;
    tabs: ITab;
    actions?: Array<IAction>;
}

const DetailsWrapper = (props: IProps) => {
    const { title, subtitle, tabs, actions } = props;
    return (
        <section className={classes.DetailsWrapper}>
            <header className={classes['DetailsWrapper-header']}>
                {title}
                {subtitle}
            </header>
            <Tabs tabs={tabs} />
            {actions && actions.length > 0 && (
                <footer className={classes['DetailsWrapper-footer']}>
                    {actions.map((action: IAction, ix: number) => (
                        <Button key={ix} outline={true} clicked={action.onClick}>
                            {action.label}
                        </Button>
                    ))}
                </footer>
            )}
        </section>
    );
};

export default DetailsWrapper;
