export const HOMEPAGE = '/';
export const SIGNIN = '/signin';
export const ANAGRAFICA = '/anagrafica';
export const CONTROLLI = '/controlli';
export const CAMPIONI = '/campioni';
export const FORMATORI = '/formatori';
export const FORMATORE = '/formatori/:id';
export const STATISTICHE = '/statistiche';
export const LABORATORI = '/laboratori';
export const LABORATORIO = '/laboratori/:idDitta/:idSede';
export const ERROR_PAGE = '/error';
