import React, { Component } from 'react';
import './styles.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ITab } from '../../shared/interfaces';

interface IProps {
    tabs: ITab;
}

class TabsWrapper extends Component<IProps> {
    render() {
        const { tabs } = this.props;

        if (!tabs.titles || !tabs.titles.length) return null;

        const tabsListTitles = tabs.titles.map((title: string, ix: number) => (
            <Tab key={ix}>{title}</Tab>
        ));

        const tabsListPanels = tabs.items.map((panel: any, ix: number) => (
            <TabPanel key={ix}>{panel}</TabPanel>
        ));

        return (
            <section className={'TabsWrapper'}>
                <Tabs forceRenderTabPanel={true}>
                    <TabList>{tabsListTitles}</TabList>
                    {tabsListPanels}
                </Tabs>
            </section>
        );
    }
}

export default TabsWrapper;
