import * as actionTypes from '../actions/actionTypes';
import { ICampione } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    list: Array<ICampione>;
    error: string;
    page: number;
    count: number;
    totalPages: number;
    item: ICampione;
    stream: Blob;
}

const INITIAL_STATE = {
    list: [],
    selected: null,
    isFetchingList: false,
    isFetchingItem: false,
    didInvalidate: false,
    error: null,
    currentPage: 1,
    isLastPage: false,
    totalRecords: 0,
    totalPages: 0,
    onlyCounter: false,
    stream: null,
    isCreatingFile: false,
};

const fetchCampioniStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.page === 1 ? [] : state.list,
        currentPage: action.page,
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: action.page === 1 ? 0 : state.totalRecords,
        totalPages: action.page === 1 ? 0 : state.totalPages,
        onlyCounter: false,
    });
};

const fetchCampioniSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldPaginatedList: Array<ICampione> = [...state.list];
    const updatedPaginatedList = oldPaginatedList.concat(action.list);

    return updateObject(state, {
        list: updatedPaginatedList,
        isFetchingList: false,
        isLastPage: state.currentPage === action.totalPages,
        totalRecords: action.count,
        totalPages: action.totalPages,
    });
};

const resetCampioni = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        isFetching: false,
        didInvalidate: false,
        error: null,
        currentPage: 1,
        isLastPage: false,
        totalRecords: 0,
        onlyCounter: false,
    });
};

const fetchCampioneStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingItem: true,
        selected: null,
        onlyCounter: false,
    });
};

const fetchCampioneSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingItem: false,
        selected: action.item,
    });
};

const fetchCampioneFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingItem: false,
        didInvalidate: true,
    });
};

const fetchCounterStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: 0,
        list: [],
        onlyCounter: true,
    });
};

const fetchCounterSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingList: false,
        totalRecords: action.count,
    });
};

const fetchCounterFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const exportStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFile: true,
    });
};

const exportSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFile: false,
        stream: action.stream,
    });
};

const exportFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingFile: false,
        didInvalidate: true,
    });
};

const fetchCampioniFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetching: false,
        didInvalidate: true,
    });
};

const campioneReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_CAMPIONI_START:
            return fetchCampioniStart(state, action);
        case actionTypes.FETCH_CAMPIONI_SUCCESS:
            return fetchCampioniSuccess(state, action);
        case actionTypes.FETCH_CAMPIONI_FAIL:
            return fetchCampioniFail(state, action);

        case actionTypes.FETCH_CAMPIONE_START:
            return fetchCampioneStart(state, action);
        case actionTypes.FETCH_CAMPIONE_SUCCESS:
            return fetchCampioneSuccess(state, action);
        case actionTypes.FETCH_CAMPIONE_FAIL:
            return fetchCampioneFail(state, action);

        case actionTypes.FETCH_CAMPIONI_COUNTER_START:
            return fetchCounterStart(state);
        case actionTypes.FETCH_CAMPIONI_COUNTER_SUCCESS:
            return fetchCounterSuccess(state, action);
        case actionTypes.FETCH_CAMPIONI_COUNTER_FAIL:
            return fetchCounterFail(state, action);

        case actionTypes.FETCH_CAMPIONI_EXPORT_START:
            return exportStart(state);
        case actionTypes.FETCH_CAMPIONI_EXPORT_SUCCESS:
            return exportSuccess(state, action);
        case actionTypes.FETCH_CAMPIONI_EXPORT_FAIL:
            return exportFail(state, action);

        case actionTypes.RESET_CAMPIONI:
            return resetCampioni(state);

        default:
            return state;
    }
};

export default campioneReducer;
