import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    text?: string;
    lng: number;
    lat: number;
}

const MapButton = (props: IProps) => {
    const href = `https://www.google.it/maps?q=${props.lat}+${props.lng}&hl=it`;

    return props.lat && props.lng ? (
        <a target={'_blank'} href={href} className={classes.MapButton} rel="noopener noreferrer">
            {props.text || 'Vai alla mappa'}
        </a>
    ) : null;
};

export default MapButton;
