import React, { Component } from 'react';
import Select from 'react-select';
import classes from './styles.module.scss';
import constants from '../../shared/constants';

const defaultValue = [{ value: '', label: 'Tutte le province' }];

const options = Object.keys(constants.PROVINCE).map((key: string) => {
    return {
        value: constants.PROVINCE[key].value,
        label: constants.PROVINCE[key].label,
    };
});

interface IProps {
    changed(selected: { value: string; label: string }): void;
}

export default class ProvinceSelector extends Component<IProps> {
    render() {
        return (
            <section className={classes.ProvinceSelector}>
                <Select
                    onChange={this.props.changed}
                    defaultValue={defaultValue[0]}
                    isSearchable={true}
                    name="province"
                    options={defaultValue.concat(options)}
                />
            </section>
        );
    }
}
