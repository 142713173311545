import React from 'react';
import classes from './styles.module.scss';
import Label from '../Label';

interface IProps {
    children: any;
    label?: any;
    name?: string;
    noMargin?: boolean;
    withSeparator?: boolean;
    withMarginBottom?: boolean;
    withSections?: boolean;
    alignItems?: 'center' | 'flex-end' | 'flex-start';
    withFields?: boolean;
    justifyFlexEnd?: boolean;
}

const FlexWrapper = (props: IProps) => {
    return (
        <section
            className={[
                classes.FlexWrapper,
                props.noMargin ? classes['FlexWrapper--noMargin'] : '',
                props.withFields ? classes['FlexWrapper--withFields'] : '',
                props.withSeparator ? classes['FlexWrapper--withSeparator'] : '',
                props.withMarginBottom ? classes['FlexWrapper--withMarginBottom'] : '',
                props.withSections ? classes['FlexWrapper--withSections'] : '',
                props.alignItems ? classes[`FlexWrapper--${props.alignItems}`] : '',
                props.justifyFlexEnd ? classes[`FlexWrapper--justifyFlexEnd`] : '',
            ].join(' ')}
        >
            {props.label && <Label for={props.name || ''}>{props.label}</Label>}
            <div>{props.children}</div>
        </section>
    );
};

export default FlexWrapper;
