import * as actionTypes from '../actions/actionTypes';
import { IFormatore, IDecreto, IFormatoreSede } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    list: Array<IFormatore>;
    error: string;
    page: number;
    count: number;
    totalPages: number;
    item: IFormatore;
    stream: Blob;
    office: IFormatoreSede;
    decreto: IDecreto;
    lastRemovedId: string;
    lastRemovedSedeId: string;
    lastRemovedDecretoId: string;
}

const INITIAL_STATE = {
    list: [],
    selected: null,
    isFetchingList: false,
    isFetchingItem: false,
    didInvalidate: false,
    error: null,
    currentPage: 1,
    isLastPage: false,
    totalRecords: 0,
    totalPages: 0,
    onlyCounter: false,
    stream: null,
    isCreatingFile: false,
    // Formatore
    lastUpdatedFormatore: null,
    isUpdatingFormatore: false,
    lastCreatedFormatore: null,
    isCreatingFormatore: false,
    lastRemovedFormatore: null,
    isRemovingFormatore: false,
    // Sede formatore
    sedi: [],
    isCreatingSede: false,
    lastCreatedSede: null,
    isUpdatingSede: false,
    lastUpdatedSede: null,
    lastRemovedSede: null,
    isRemovingSede: false,
    decreti: [],
    isCreatingDecreto: false,
    lastCreatedDecreto: null,
    isUpdatingDecreto: false,
    lastUpdatedDecreto: null,
    lastRemovedDecreto: null,
    isRemovingDecreto: false,
};

const fetchFormatoriStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        list: action.page === 1 ? [] : state.list,
        currentPage: action.page,
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: action.page === 1 ? 0 : state.totalRecords,
        totalPages: action.page === 1 ? 0 : state.totalPages,
        onlyCounter: false,
        lastUpdatedFormatore: null,
        lastCreatedFormatore: null,
    });
};
const fetchFormatoriSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldPaginatedList: Array<IFormatore> = [...state.list];
    const updatedPaginatedList = oldPaginatedList.concat(action.list);

    return updateObject(state, {
        list: updatedPaginatedList,
        isFetchingList: false,
        isLastPage: state.currentPage === action.totalPages,
        totalRecords: action.count,
        totalPages: action.totalPages,
    });
};

const fetchFormatoriFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const fetchFormatoreStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingItem: true,
        selected: null,
        onlyCounter: false,
        sedi: [],
        lastUpdatedFormatore: null,
        lastCreatedFormatore: null,
    });
};

const fetchFormatoreSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingItem: false,
        selected: action.item,
        sedi: action.item.Sedi || [],
        decreti: action.item.Decreti || [],
    });
};

const fetchFormatoreFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingItem: false,
        didInvalidate: true,
    });
};

const createFormatoreStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFormatore: true,
        selected: null,
        onlyCounter: false,
        lastCreatedFormatore: null,
    });
};

const createFormatoreSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFormatore: false,
        selected: { ...state.selected, ...action.item },
        lastCreatedFormatore: action.item.Id,
    });
};

const createFormatoreFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingFormatore: false,
        didInvalidate: true,
    });
};

const updateFormatoreStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isUpdatingFormatore: true,
        selected: null,
        onlyCounter: false,
        lastUpdatedFormatore: null,
    });
};

const updateFormatoreSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isUpdatingFormatore: false,
        selected: { ...state.selected, ...action.item },
        lastUpdatedFormatore: action.item.Id,
    });
};

const updateFormatoreFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingFormatore: false,
        didInvalidate: true,
    });
};

const removeFormatoreStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isRemovingFormatore: true,
        lastRemovedFormatore: null,
    });
};

const removeFormatoreSuccess = (state = INITIAL_STATE, action: IAction) => {
    const list: Array<IFormatore> = state.list.filter(
        (item: IFormatore) => item.Id !== action.lastRemovedId
    );

    return updateObject(state, {
        isRemovingFormatore: false,
        lastRemovedFormatore: action.lastRemovedId,
        selected: null,
        list,
        totalRecords: state.totalRecords > 0 ? state.totalRecords - 1 : 0,
    });
};

const removeFormatoreFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isRemovingFormatore: false,
        didInvalidate: true,
    });
};

const fetchCounterStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isFetchingList: true,
        isLastPage: false,
        totalRecords: 0,
        list: [],
        onlyCounter: true,
    });
};

const fetchCounterSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isFetchingList: false,
        totalRecords: action.count,
    });
};

const fetchCounterFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingList: false,
        didInvalidate: true,
    });
};

const exportStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isCreatingFile: true,
    });
};

const exportSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isCreatingFile: false,
        stream: action.stream,
    });
};

const exportFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingFile: false,
        didInvalidate: true,
    });
};

// Sedi
const createSedeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastCreatedSede: null,
        error: null,
        didInvalidate: false,
        isCreatingSede: true,
    });
};

const createSedeSuccess = (state = INITIAL_STATE, action: IAction) => {
    const sedi: Array<IFormatoreSede> = [...state.sedi].concat(action.office);

    const selected: IFormatoreSede = state.selected
        ? {
              ...state.selected,
              Sedi: sedi,
          }
        : null;

    return updateObject(state, {
        isCreatingSede: false,
        lastCreatedSede: action.office,
        sedi,
        selected,
    });
};

const createSedeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingSede: false,
        didInvalidate: true,
    });
};

const updateSedeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastUpdatedSede: null,
        error: null,
        didInvalidate: false,
        isUpdatingSede: true,
    });
};

const updateSedeSuccess = (state = INITIAL_STATE, action: IAction) => {
    const sedi: Array<IFormatoreSede> = [...state.sedi].map((sede: IFormatoreSede) =>
        sede.Id === action.office.Id ? action.office : sede
    );

    const selected: IFormatoreSede = state.selected
        ? {
              ...state.selected,
              Sedi: sedi,
          }
        : null;

    return updateObject(state, {
        isUpdatingSede: false,
        lastUpdatedSede: action.office,
        sedi,
        selected,
    });
};

const updateSedeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingSede: false,
        didInvalidate: true,
    });
};

const removeSedeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isRemovingSede: true,
        lastRemovedSede: null,
    });
};

const removeSedeSuccess = (state = INITIAL_STATE, action: IAction) => {
    const sedi: Array<IFormatoreSede> = state.sedi.filter(
        (item: IFormatoreSede) => item.Id !== action.lastRemovedSedeId
    );

    const selected: IFormatoreSede = state.selected
        ? {
              ...state.selected,
              Sedi: sedi,
          }
        : null;

    return updateObject(state, {
        isRemovingSede: true,
        lastRemovedSede: action.lastRemovedId,
        selected,
        sedi,
    });
};

const removeSedeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isRemovingSede: true,
        didInvalidate: true,
    });
};

// Decreti
const createDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastCreatedDecreto: null,
        error: null,
        didInvalidate: false,
        isCreatingDecreto: true,
    });
};

const createDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = [...state.decreti].concat(action.decreto);

    const selected: IFormatore = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isCreatingDecreto: false,
        lastCreatedDecreto: action.decreto,
        decreti,
        selected,
    });
};

const createDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isCreatingDecreto: false,
        didInvalidate: true,
    });
};

const updateDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastUpdatedDecreto: null,
        error: null,
        didInvalidate: false,
        isUpdatingDecreto: true,
    });
};

const updateDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = [...state.decreti].map((decreto: IDecreto) =>
        decreto.Id === action.decreto.Id ? action.decreto : decreto
    );

    const selected: IDecreto = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isUpdatingDecreto: false,
        lastUpdatedDecreto: action.decreto,
        decreti,
        selected,
    });
};

const updateDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingDecreto: false,
        didInvalidate: true,
    });
};

const removeDecretoStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isRemovingDecreto: true,
        lastRemovedDecreto: null,
    });
};

const removeDecretoSuccess = (state = INITIAL_STATE, action: IAction) => {
    const decreti: Array<IDecreto> = state.decreti.filter(
        (item: IDecreto) => item.Id !== action.lastRemovedDecretoId
    );

    const selected: IDecreto = state.selected
        ? {
              ...state.selected,
              Decreti: decreti,
          }
        : null;

    return updateObject(state, {
        isRemovingDecreto: true,
        lastRemovedDecreto: action.lastRemovedDecretoId,
        selected,
        decreti,
    });
};

const removeDecretoFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isRemovingDecreto: true,
        didInvalidate: true,
    });
};

const resetFormatori = (state = INITIAL_STATE) => {
    return updateObject(state, {
        list: [],
        isFetching: false,
        didInvalidate: false,
        error: null,
        currentPage: 1,
        isLastPage: false,
        totalRecords: 0,
        onlyCounter: false,
    });
};

const formatoreReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        case actionTypes.FETCH_FORMATORI_START:
            return fetchFormatoriStart(state, action);
        case actionTypes.FETCH_FORMATORI_SUCCESS:
            return fetchFormatoriSuccess(state, action);
        case actionTypes.FETCH_FORMATORI_FAIL:
            return fetchFormatoriFail(state, action);

        case actionTypes.FETCH_FORMATORE_START:
            return fetchFormatoreStart(state, action);
        case actionTypes.FETCH_FORMATORE_SUCCESS:
            return fetchFormatoreSuccess(state, action);
        case actionTypes.FETCH_FORMATORE_FAIL:
            return fetchFormatoreFail(state, action);

        case actionTypes.CREATE_FORMATORE_START:
            return createFormatoreStart(state, action);
        case actionTypes.CREATE_FORMATORE_SUCCESS:
            return createFormatoreSuccess(state, action);
        case actionTypes.CREATE_FORMATORE_FAIL:
            return createFormatoreFail(state, action);

        case actionTypes.UPDATE_FORMATORE_START:
            return updateFormatoreStart(state, action);
        case actionTypes.UPDATE_FORMATORE_SUCCESS:
            return updateFormatoreSuccess(state, action);
        case actionTypes.UPDATE_FORMATORE_FAIL:
            return updateFormatoreFail(state, action);

        case actionTypes.REMOVE_FORMATORE_START:
            return removeFormatoreStart(state, action);
        case actionTypes.REMOVE_FORMATORE_SUCCESS:
            return removeFormatoreSuccess(state, action);
        case actionTypes.REMOVE_FORMATORE_FAIL:
            return removeFormatoreFail(state, action);

        case actionTypes.FETCH_FORMATORI_COUNTER_START:
            return fetchCounterStart(state);
        case actionTypes.FETCH_FORMATORI_COUNTER_SUCCESS:
            return fetchCounterSuccess(state, action);
        case actionTypes.FETCH_FORMATORI_COUNTER_FAIL:
            return fetchCounterFail(state, action);

        case actionTypes.FETCH_FORMATORI_EXPORT_START:
            return exportStart(state);
        case actionTypes.FETCH_FORMATORI_EXPORT_SUCCESS:
            return exportSuccess(state, action);
        case actionTypes.FETCH_FORMATORI_EXPORT_FAIL:
            return exportFail(state, action);

        case actionTypes.CREATE_FORMATORE_SEDE_START:
            return createSedeStart(state);
        case actionTypes.CREATE_FORMATORE_SEDE_SUCCESS:
            return createSedeSuccess(state, action);
        case actionTypes.CREATE_FORMATORE_SEDE_FAIL:
            return createSedeFail(state, action);

        case actionTypes.UPDATE_FORMATORE_SEDE_START:
            return updateSedeStart(state);
        case actionTypes.UPDATE_FORMATORE_SEDE_SUCCESS:
            return updateSedeSuccess(state, action);
        case actionTypes.UPDATE_FORMATORE_SEDE_FAIL:
            return updateSedeFail(state, action);

        case actionTypes.DELETE_FORMATORE_SEDE_START:
            return removeSedeStart(state);
        case actionTypes.DELETE_FORMATORE_SEDE_SUCCESS:
            return removeSedeSuccess(state, action);
        case actionTypes.DELETE_FORMATORE_SEDE_FAIL:
            return removeSedeFail(state, action);

        case actionTypes.CREATE_FORMATORE_DECRETO_START:
            return createDecretoStart(state);
        case actionTypes.CREATE_FORMATORE_DECRETO_SUCCESS:
            return createDecretoSuccess(state, action);
        case actionTypes.CREATE_FORMATORE_DECRETO_FAIL:
            return createDecretoFail(state, action);

        case actionTypes.UPDATE_FORMATORE_DECRETO_START:
            return updateDecretoStart(state);
        case actionTypes.UPDATE_FORMATORE_DECRETO_SUCCESS:
            return updateDecretoSuccess(state, action);
        case actionTypes.UPDATE_FORMATORE_DECRETO_FAIL:
            return updateDecretoFail(state, action);

        case actionTypes.DELETE_FORMATORE_DECRETO_START:
            return removeDecretoStart(state);
        case actionTypes.DELETE_FORMATORE_DECRETO_SUCCESS:
            return removeDecretoSuccess(state, action);
        case actionTypes.DELETE_FORMATORE_DECRETO_FAIL:
            return removeDecretoFail(state, action);

        case actionTypes.RESET_FORMATORI:
            return resetFormatori(state);

        default:
            return state;
    }
};

export default formatoreReducer;
