import * as actionTypes from './actionTypes';
import { ICampione, IControllo, IResponse } from '../../shared/interfaces';
import CampioneService from '../../services/api/campione';

// FETCH
export const fetchCampioniStart = (page: number) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_START,
        page,
    };
};

export const fetchCampioniSuccess = (list: Array<ICampione>, count: number, totalPages: number) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_SUCCESS,
        list,
        count,
        totalPages,
    };
};

export const fetchCampioniFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_FAIL,
        error,
    };
};

export const fetchCampioni = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    classe: Array<number> = [],
    provenienza: Array<number> = [],
    circuito: Array<number> = [],
    tipologiaOsa: Array<number> = [],
    dataInizio: string = null,
    dataFine: string = null,
    page: number = 1
) => {
    return (dispatch: any) => {
        dispatch(fetchCampioniStart(page));

        CampioneService.get(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            classe,
            provenienza,
            circuito,
            tipologiaOsa,
            dataInizio,
            dataFine,
            page
        ).then(
            (response: IResponse) => {
                dispatch(
                    fetchCampioniSuccess(
                        response.Data,
                        response.DataCount || 0,
                        response.TotalPages || 1
                    )
                );
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchCampioniFail(response.data.error));
            }
        );
    };
};

// DETAIL
export const fetchCampioneStart = () => {
    return {
        type: actionTypes.FETCH_CAMPIONE_START,
    };
};

export const fetchCampioneSuccess = (item: IControllo) => {
    return {
        type: actionTypes.FETCH_CAMPIONE_SUCCESS,
        item,
    };
};

export const fetchCampioneFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CAMPIONE_FAIL,
        error,
    };
};

export const fetchCampione = (id: string) => {
    return (dispatch: any) => {
        dispatch(fetchCampioneStart());

        CampioneService.getById(id).then(
            (data: IControllo) => {
                dispatch(fetchCampioneSuccess(data));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchCampioneFail(response.data.error));
            }
        );
    };
};

// COUNTER
export const fetchCampioniCounterStart = () => {
    return {
        type: actionTypes.FETCH_CAMPIONI_COUNTER_START,
    };
};

export const fetchCampioniCounterSuccess = (count: number) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_COUNTER_SUCCESS,
        count,
    };
};

export const fetchCampioniCounterFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_COUNTER_FAIL,
        error,
    };
};

export const fetchCampioniCounter = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    classe: Array<number> = [],
    provenienza: Array<number> = [],
    circuito: Array<number> = [],
    tipologiaOsa: Array<number> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(fetchCampioniCounterStart());

        CampioneService.counter(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            classe,
            provenienza,
            circuito,
            tipologiaOsa,
            dataInizio,
            dataFine
        ).then(
            (response: IResponse) => {
                dispatch(fetchCampioniCounterSuccess(response.DataCount || 0));
            },
            (response: { data: { error: string } }) => {
                dispatch(fetchCampioniCounterFail(response.data.error));
            }
        );
    };
};

// EXPORT
export const exportCampioniDataStart = () => {
    return {
        type: actionTypes.FETCH_CAMPIONI_EXPORT_START,
    };
};

export const exportCampioniDataSuccess = (stream: Blob) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_EXPORT_SUCCESS,
        stream,
    };
};

export const exportCampioniDataFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CAMPIONI_EXPORT_FAIL,
        error,
    };
};

export const exportCampioniData = (
    keyword: string = '',
    province: Array<string> = [],
    cities: Array<string> = [],
    masterList: Array<string> = [],
    ateco: Array<string> = [],
    classe: Array<number> = [],
    provenienza: Array<number> = [],
    circuito: Array<number> = [],
    tipologiaOsa: Array<number> = [],
    dataInizio: string = null,
    dataFine: string = null
) => {
    return (dispatch: any) => {
        dispatch(exportCampioniDataStart());

        CampioneService.exportData(
            keyword,
            province,
            cities,
            masterList,
            ateco,
            classe,
            provenienza,
            circuito,
            tipologiaOsa,
            dataInizio,
            dataFine
        ).then(
            (stream: Blob) => {
                dispatch(exportCampioniDataSuccess(stream));
            },
            (response: { data: { error: string } }) => {
                dispatch(exportCampioniDataFail(response.data.error));
            }
        );
    };
};

// RESET
export const resetCampioni = () => {
    return {
        type: actionTypes.RESET_CAMPIONI,
    };
};
