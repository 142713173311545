import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Title from '../../components/UI/Title';

interface IProps {
    history: History;
}

interface IState {}

class Homepage extends Component<IProps & RouteComponentProps, IState> {
    render() {
        return (
            <main className={classes.Homepage}>
                <Title>{'Homepage'}</Title>
            </main>
        );
    }
}

export default withRouter(Homepage);
