import { IChartData, IStatCityData, IStatRow, IStats, IStatTotals } from '../interfaces';
import constants from '../constants';
import { getItalianPeriod } from './utilities';
import dayjs from 'dayjs';

export const getStatsTotals = (items: Array<IStats>, month: string): IStatTotals => {
    if (!items || !items.length || !month) return null;

    const current: IStats = items.find(
        (item: IStats) => item.Month.toLowerCase() === month.toLowerCase()
    );

    return current
        ? {
              label: 'Totale',
              osa: current.NumeroOsa,
              attivita: current.NumeroMasterList,
              stabilimenti: current.NumeroSedi,
              month: current.Month,
          }
        : null;
};

export const getStatsRows = (items: Array<IStats>, month: string): Array<IStatRow> => {
    if (!items || !items.length || !month) return [];

    const current: IStats = items.find(
        (item: IStats) => item.Month.toLowerCase() === month.toLowerCase()
    );

    return current
        ? [
              {
                  provincia: constants.PROVINCE.AG.label,
                  sigla: constants.PROVINCE.AG.value,
                  osa: current.DatiAG.Osa,
                  attivita: current.DatiAG.MasterList,
                  stabilimenti: current.DatiAG.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.CL.label,
                  sigla: constants.PROVINCE.CL.value,
                  osa: current.DatiCL.Osa,
                  attivita: current.DatiCL.MasterList,
                  stabilimenti: current.DatiCL.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.CT.label,
                  sigla: constants.PROVINCE.CT.value,
                  osa: current.DatiCT.Osa,
                  attivita: current.DatiCT.MasterList,
                  stabilimenti: current.DatiCT.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.EN.label,
                  sigla: constants.PROVINCE.EN.value,
                  osa: current.DatiEN.Osa,
                  attivita: current.DatiEN.MasterList,
                  stabilimenti: current.DatiEN.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.ME.label,
                  sigla: constants.PROVINCE.ME.value,
                  osa: current.DatiME.Osa,
                  attivita: current.DatiME.MasterList,
                  stabilimenti: current.DatiME.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.PA.label,
                  sigla: constants.PROVINCE.PA.value,
                  osa: current.DatiPA.Osa,
                  attivita: current.DatiPA.MasterList,
                  stabilimenti: current.DatiPA.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.RG.label,
                  sigla: constants.PROVINCE.RG.value,
                  osa: current.DatiRG.Osa,
                  attivita: current.DatiRG.MasterList,
                  stabilimenti: current.DatiRG.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.SR.label,
                  sigla: constants.PROVINCE.SR.value,
                  osa: current.DatiSR.Osa,
                  attivita: current.DatiSR.MasterList,
                  stabilimenti: current.DatiSR.Sedi,
                  month: current.Month,
              },
              {
                  provincia: constants.PROVINCE.TP.label,
                  sigla: constants.PROVINCE.TP.value,
                  osa: current.DatiTP.Osa,
                  attivita: current.DatiTP.MasterList,
                  stabilimenti: current.DatiTP.Sedi,
                  month: current.Month,
              },
              {
                  provincia: 'Altre',
                  sigla: 'Altre',
                  osa: current.DatiAltre.Osa,
                  attivita: current.DatiAltre.MasterList,
                  stabilimenti: current.DatiAltre.Sedi,
                  month: current.Month,
              },
          ]
        : [];
};

export const getChartData = (
    items: Array<IStats>,
    provincia: string
): Array<Array<string | number>> => {
    if (!items || !items.length) return [];

    items.reverse();

    let found: Array<IChartData> = [];

    if (provincia) {
        found = items.map((item: IStats) => {
            return {
                numeroOSA: item[`Dati${provincia.toUpperCase()}`].Osa,
                numeroMasterList: item[`Dati${provincia.toUpperCase()}`].MasterList,
                numeroSedi: item[`Dati${provincia.toUpperCase()}`].Sedi,
            };
        });
    } else {
        found = items.map((item: IStats) => {
            return {
                numeroOSA: item.NumeroOsa,
                numeroMasterList: item.NumeroMasterList,
                numeroSedi: item.NumeroSedi,
            };
        });
    }

    const periods = ['Tipologia'];

    for (let i = -12; i < 0; i++) {
        periods.push(getItalianPeriod(dayjs().add(i, 'month').format('MMMM YYYY')));
    }

    const osa = ['OSA'];
    const osaList: Array<any> = found.map((item: IChartData) => item.numeroOSA);

    const stabilimenti = ['Stabilimenti'];
    const stabList: Array<any> = found.map((item: IChartData) => item.numeroSedi);

    const attivita = ['Attività'];
    const attList: Array<any> = found.map((item: IChartData) => item.numeroMasterList);

    return [periods, osa.concat(osaList), stabilimenti.concat(stabList), attivita.concat(attList)];
};

export const getStatDetailsData = (
    items: Array<IStatCityData>,
    provincia: string,
    month: string
): Array<IStatRow> => {
    if (!items || !items.length) return [];

    const data: Array<IStatRow> = items.map((item: IStatCityData) => {
        return {
            citta: item.Citta,
            idCitta: item.IdCitta,
            provincia: provincia.substring(0, 2).toUpperCase(),
            osa: item.Osa,
            deltaOsa: item.DeltaOsa,
            stabilimenti: item.Sedi,
            deltaStabilimenti: item.DeltaSedi,
            attivita: item.MasterList,
            deltaMasterList: item.DeltaMasterList,
            month,
        };
    });

    return data || [];
};
